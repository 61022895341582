/* eslint-disable */
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Date: { input: string; output: string }
  DateTime: { input: string; output: string }
  JSON: { input: any; output: any }
  Upload: { input: any; output: any }
}

export enum AccountScopeCode {
  UNIQUE = 'UNIQUE',
}

export type AppCreateInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  permissions?: InputMaybe<Array<Permission>>
}

export type AppTokenCreateInput = {
  name: Scalars['String']['input']
}

export type AppUpdateInput = {
  isActive: Scalars['Boolean']['input']
  name: Scalars['String']['input']
  permissions?: InputMaybe<Array<Permission>>
}

export type AppsFilterInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  search?: InputMaybe<Scalars['String']['input']>
}

export enum AppsSortField {
  ACTIVE = 'ACTIVE',
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME',
}

export type AppsSortInput = {
  direction: SortDirection
  field: AppsSortField
}

export enum AuthScopeCode {
  APP_TOKEN_INVALID = 'APP_TOKEN_INVALID',
  INACTIVE = 'INACTIVE',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  INVALID_PAYLOAD = 'INVALID_PAYLOAD',
  INVALID_TOKEN_TYPE = 'INVALID_TOKEN_TYPE',
  JWT_EXPIRED = 'JWT_EXPIRED',
  JWT_INVALID = 'JWT_INVALID',
  NO_USER_WITH_EMAIL = 'NO_USER_WITH_EMAIL',
  USER_ONLY = 'USER_ONLY',
}

export type CaseActionCreateInput = {
  comment: Scalars['String']['input']
  date: Scalars['DateTime']['input']
  resultId: Scalars['ID']['input']
  typeId: Scalars['ID']['input']
}

export type CaseActionResultCreateInput = {
  isActive: Scalars['Boolean']['input']
  title: Scalars['String']['input']
  value?: InputMaybe<Scalars['String']['input']>
}

export type CaseActionResultUpdateInput = {
  isActive: Scalars['Boolean']['input']
  title: Scalars['String']['input']
  value?: InputMaybe<Scalars['String']['input']>
}

export type CaseActionResultsFilterInput = {
  deactivated?: InputMaybe<Scalars['Boolean']['input']>
}

export enum CaseActionResultsSortField {
  ACTIVE = 'ACTIVE',
  TITLE = 'TITLE',
}

export type CaseActionResultsSortInput = {
  direction: SortDirection
  field: CaseActionResultsSortField
}

export type CaseActionTypeCreateInput = {
  isActive: Scalars['Boolean']['input']
  title: Scalars['String']['input']
  value?: InputMaybe<Scalars['String']['input']>
}

export type CaseActionTypeUpdateInput = {
  isActive: Scalars['Boolean']['input']
  title: Scalars['String']['input']
  value?: InputMaybe<Scalars['String']['input']>
}

export type CaseActionTypesFilterInput = {
  deactivated?: InputMaybe<Scalars['Boolean']['input']>
}

export enum CaseActionTypesSortField {
  ACTIVE = 'ACTIVE',
  TITLE = 'TITLE',
}

export type CaseActionTypesSortInput = {
  direction: SortDirection
  field: CaseActionTypesSortField
}

export type CaseActionUpdateInput = {
  comment: Scalars['String']['input']
  date: Scalars['DateTime']['input']
  resultId: Scalars['ID']['input']
  typeId: Scalars['ID']['input']
}

export type CaseAddressAssignInput = {
  customerAddressId: Scalars['ID']['input']
}

export type CaseAddressAssignNewInput = {
  city: Scalars['String']['input']
  cityArea?: InputMaybe<Scalars['String']['input']>
  countryAreaId?: InputMaybe<Scalars['ID']['input']>
  countryCode?: InputMaybe<Scalars['String']['input']>
  isDefault?: InputMaybe<Scalars['Boolean']['input']>
  isPermanentResidency: Scalars['Boolean']['input']
  note?: InputMaybe<Scalars['String']['input']>
  postalCode: Scalars['String']['input']
  streetAddress1: Scalars['String']['input']
  streetAddress2?: InputMaybe<Scalars['String']['input']>
}

export type CaseAssignInput = {
  userId: Scalars['ID']['input']
}

export type CaseBulkAssignInput = {
  caseIds: Array<Scalars['ID']['input']>
  userId: Scalars['ID']['input']
}

export type CaseBulkReopenInput = {
  caseIds: Array<Scalars['ID']['input']>
}

export type CaseCloseInput = {
  caseCloseReasonId?: InputMaybe<Scalars['ID']['input']>
}

export type CaseCloseReasonCreateInput = {
  isActive: Scalars['Boolean']['input']
  title: Scalars['String']['input']
  value?: InputMaybe<Scalars['String']['input']>
}

export type CaseCloseReasonUpdateInput = {
  isActive: Scalars['Boolean']['input']
  title: Scalars['String']['input']
  value?: InputMaybe<Scalars['String']['input']>
}

export type CaseCloseReasonsFilterInput = {
  deactivated?: InputMaybe<Scalars['Boolean']['input']>
}

export enum CaseCloseReasonsSortField {
  ACTIVE = 'ACTIVE',
  TITLE = 'TITLE',
}

export type CaseCloseReasonsSortInput = {
  direction: SortDirection
  field: CaseCloseReasonsSortField
}

export type CaseCreateInput = {
  existingCustomerId?: InputMaybe<Scalars['ID']['input']>
  externalIdentifier?: InputMaybe<Scalars['String']['input']>
  newCustomer?: InputMaybe<CustomerCreateInput>
  title?: InputMaybe<Scalars['String']['input']>
}

export enum CaseCustomStatus {
  CALL_AGAIN = 'CALL_AGAIN',
  CLOSING_MEDIATION_LETTER_SENT = 'CLOSING_MEDIATION_LETTER_SENT',
  NEGOTIATIONS = 'NEGOTIATIONS',
  STILL_THINKING = 'STILL_THINKING',
  WAITING_FOR_INITIAL_DOCS = 'WAITING_FOR_INITIAL_DOCS',
  WAITING_FOR_PAYMENT_DEBT = 'WAITING_FOR_PAYMENT_DEBT',
  WAITING_FOR_PAYMENT_FEE = 'WAITING_FOR_PAYMENT_FEE',
  WAITING_FOR_SIGNED_POA_PTP_LETTER = 'WAITING_FOR_SIGNED_POA_PTP_LETTER',
  WITHOUT_CONTACT = 'WITHOUT_CONTACT',
}

export type CaseCustomStatusUpdateInput = {
  customStatus: CaseCustomStatus
}

export type CaseDocumentCreateInput = {
  file: Scalars['Upload']['input']
  note?: InputMaybe<Scalars['String']['input']>
  otherType?: InputMaybe<Scalars['String']['input']>
  type: CaseDocumentType
}

export type CaseDocumentGeneratePowerOfAttorneyInput = {
  companyName: Scalars['String']['input']
  documentNumber: Scalars['Int']['input']
  expirationMonths: Scalars['Int']['input']
  package: CasePackage
}

export enum CaseDocumentType {
  CLOSING_MEDIATION_LETTER = 'CLOSING_MEDIATION_LETTER',
  INVOICE_FOR_FEE = 'INVOICE_FOR_FEE',
  OTHER = 'OTHER',
  PAYMENT_PROOF_FEE = 'PAYMENT_PROOF_FEE',
  POWER_OF_ATTORNEY_CASE = 'POWER_OF_ATTORNEY_CASE',
  POWER_OF_ATTORNEY_CASE_GENERATED = 'POWER_OF_ATTORNEY_CASE_GENERATED',
  PTP_LETTER = 'PTP_LETTER',
  SCREENSHOTS = 'SCREENSHOTS',
}

export type CaseDocumentUpdateInput = {
  note?: InputMaybe<Scalars['String']['input']>
  otherType?: InputMaybe<Scalars['String']['input']>
  type: CaseDocumentType
}

export type CaseEmailAssignInput = {
  contactEmailId: Scalars['ID']['input']
}

export type CaseEmailAssignNewInput = {
  emailAddress: Scalars['String']['input']
  isDefault?: InputMaybe<Scalars['Boolean']['input']>
  note?: InputMaybe<Scalars['String']['input']>
}

export enum CaseEventType {
  ACTION_ADDED = 'ACTION_ADDED',
  ACTION_DELETED = 'ACTION_DELETED',
  ACTION_EDITED = 'ACTION_EDITED',
  ADDRESS_ASSIGNED = 'ADDRESS_ASSIGNED',
  ASSIGNED = 'ASSIGNED',
  CLOSED = 'CLOSED',
  CREATED = 'CREATED',
  CUSTOM_STATUS_UPDATED = 'CUSTOM_STATUS_UPDATED',
  DOCUMENT_DELETED = 'DOCUMENT_DELETED',
  DOCUMENT_EDITED = 'DOCUMENT_EDITED',
  DOCUMENT_UPLOADED = 'DOCUMENT_UPLOADED',
  EMAIL_ASSIGNED = 'EMAIL_ASSIGNED',
  FEE_CREATED = 'FEE_CREATED',
  FEE_PAYMENT_CREATED = 'FEE_PAYMENT_CREATED',
  FEE_PAYMENT_DELETED = 'FEE_PAYMENT_DELETED',
  FEE_PAYMENT_UPDATED = 'FEE_PAYMENT_UPDATED',
  FEE_UPDATED = 'FEE_UPDATED',
  NOTE_ADDED = 'NOTE_ADDED',
  PHONE_ASSIGNED = 'PHONE_ASSIGNED',
  REOPENED = 'REOPENED',
  SERVICE_ADDED = 'SERVICE_ADDED',
  SERVICE_DELETED = 'SERVICE_DELETED',
  SERVICE_EDITED = 'SERVICE_EDITED',
}

export type CaseEventsFilterInput = {
  eventType?: InputMaybe<Array<CaseEventType>>
}

export enum CaseEventsSortField {
  DATE = 'DATE',
}

export type CaseEventsSortInput = {
  direction: SortDirection
  field: CaseEventsSortField
}

export type CaseFeeCreateInput = {
  feeAmount: MoneyInput
  feeRate: Scalars['Float']['input']
}

export type CaseFeePaymentCreateInput = {
  paidAmount: MoneyInput
  paidAt: Scalars['DateTime']['input']
}

export type CaseFeePaymentUpdateInput = {
  paidAmount: MoneyInput
  paidAt: Scalars['DateTime']['input']
}

export type CaseFeeUpdateInput = {
  feeAmount: MoneyInput
  feeRate: Scalars['Float']['input']
}

export type CaseImportInput = {
  customer: CustomerCreateInput
  externalIdentifier?: InputMaybe<Scalars['String']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  source?: InputMaybe<CaseImportSourceInput>
}

export type CaseImportSourceInput = {
  campaign: Scalars['String']['input']
  data: Scalars['JSON']['input']
  medium: Scalars['String']['input']
  type: Scalars['String']['input']
}

export type CaseNoteAddInput = {
  message: Scalars['String']['input']
}

export enum CasePackage {
  ADDON = 'ADDON',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM',
  SILVER = 'SILVER',
}

export type CasePhoneAssignInput = {
  contactPhoneId: Scalars['ID']['input']
}

export type CasePhoneAssignNewInput = {
  isDefault?: InputMaybe<Scalars['Boolean']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  phoneNumber: Scalars['String']['input']
}

export enum CaseScopeCode {
  CANNOT_ASSIGN_TO_APP = 'CANNOT_ASSIGN_TO_APP',
  CASE_CLOSED = 'CASE_CLOSED',
  INVALID_DOCUMENT_CONFIGURATION = 'INVALID_DOCUMENT_CONFIGURATION',
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_PHONE = 'INVALID_PHONE',
  MISSING_ADDRESS = 'MISSING_ADDRESS',
  MISSING_CONTACT_PHONE = 'MISSING_CONTACT_PHONE',
  MISSING_DOCUMENT_CONFIGURATION = 'MISSING_DOCUMENT_CONFIGURATION',
  MISSING_GOVERNMENT_ID = 'MISSING_GOVERNMENT_ID',
  MISSING_SERVICE_DEBT = 'MISSING_SERVICE_DEBT',
  MISSING_SERVICE_LOCALIZATION = 'MISSING_SERVICE_LOCALIZATION',
  NO_SERVICES = 'NO_SERVICES',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE',
}

export enum CaseStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  PENDING = 'PENDING',
}

export enum CaseType {
  MULTIPLE = 'MULTIPLE',
  NONE = 'NONE',
  SINGLE = 'SINGLE',
}

export type CasesAllFilterInput = {
  assignedTo?: InputMaybe<Array<Scalars['ID']['input']>>
  closeReasons?: InputMaybe<Array<Scalars['ID']['input']>>
  created?: InputMaybe<DateRange>
  customStatus?: InputMaybe<Array<CaseCustomStatus>>
  customer?: InputMaybe<Array<Scalars['ID']['input']>>
  emailAddress?: InputMaybe<Scalars['String']['input']>
  feePaymentStatus?: InputMaybe<Array<PaymentStatus>>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Array<CaseStatus>>
  title?: InputMaybe<Scalars['String']['input']>
}

export enum CasesAllSortField {
  ASSIGNEE = 'ASSIGNEE',
  CUSTOMER = 'CUSTOMER',
  DATE = 'DATE',
  TITLE = 'TITLE',
}

export type CasesAllSortInput = {
  direction: SortDirection
  field: CasesAllSortField
}

export type CasesAssignedFilterInput = {
  created?: InputMaybe<DateRange>
  customStatus?: InputMaybe<Array<CaseCustomStatus>>
  customer?: InputMaybe<Array<Scalars['ID']['input']>>
  emailAddress?: InputMaybe<Scalars['String']['input']>
  feePaymentStatus?: InputMaybe<Array<PaymentStatus>>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  search?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Array<CaseStatus>>
  title?: InputMaybe<Scalars['String']['input']>
}

export enum CasesAssignedSortField {
  CUSTOMER = 'CUSTOMER',
  DATE = 'DATE',
  TITLE = 'TITLE',
}

export type CasesAssignedSortInput = {
  direction: SortDirection
  field: CasesAssignedSortField
}

export enum CommonScopeCode {
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  NOT_FRONTEND_URL = 'NOT_FRONTEND_URL',
  PAGINATION_INVALID = 'PAGINATION_INVALID',
  PAYLOAD_TOO_LARGE = 'PAYLOAD_TOO_LARGE',
  RECORD_EXISTS = 'RECORD_EXISTS',
  REQUIRED = 'REQUIRED',
  SINGLE = 'SINGLE',
  STORE_ERROR = 'STORE_ERROR',
  UPLOAD_ERROR = 'UPLOAD_ERROR',
}

export type CustomerAddressCreateInput = {
  city: Scalars['String']['input']
  cityArea?: InputMaybe<Scalars['String']['input']>
  countryAreaId?: InputMaybe<Scalars['ID']['input']>
  countryCode?: InputMaybe<Scalars['String']['input']>
  isDefault?: InputMaybe<Scalars['Boolean']['input']>
  isPermanentResidency: Scalars['Boolean']['input']
  note?: InputMaybe<Scalars['String']['input']>
  postalCode: Scalars['String']['input']
  streetAddress1: Scalars['String']['input']
  streetAddress2?: InputMaybe<Scalars['String']['input']>
}

export type CustomerContactEmailCreateInput = {
  emailAddress: Scalars['String']['input']
  isDefault?: InputMaybe<Scalars['Boolean']['input']>
  note?: InputMaybe<Scalars['String']['input']>
}

export type CustomerContactPhoneCreateInput = {
  isDefault?: InputMaybe<Scalars['Boolean']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  phoneNumber: Scalars['String']['input']
}

export type CustomerCreateInput = {
  address?: InputMaybe<CustomerAddressCreateInput>
  birthDate?: InputMaybe<Scalars['Date']['input']>
  contactEmail?: InputMaybe<CustomerContactEmailCreateInput>
  contactPhone?: InputMaybe<CustomerContactPhoneCreateInput>
  educationLevel?: InputMaybe<CustomerEducationLevel>
  firstName: Scalars['String']['input']
  gender?: InputMaybe<Gender>
  governmentId?: InputMaybe<Scalars['String']['input']>
  lastName: Scalars['String']['input']
  marriageStatus?: InputMaybe<CustomerMaritalStatus>
  middleName?: InputMaybe<Scalars['String']['input']>
}

export type CustomerDocumentCreateInput = {
  file: Scalars['Upload']['input']
  note?: InputMaybe<Scalars['String']['input']>
  otherType?: InputMaybe<Scalars['String']['input']>
  type: CustomerDocumentType
}

export enum CustomerDocumentType {
  ID_CARD = 'ID_CARD',
  OTHER = 'OTHER',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
}

export type CustomerDocumentUpdateInput = {
  note?: InputMaybe<Scalars['String']['input']>
  otherType?: InputMaybe<Scalars['String']['input']>
  type: CustomerDocumentType
}

export enum CustomerEducationLevel {
  DIPLOMA = 'DIPLOMA',
  JUNIOR_HIGH_SCHOOL = 'JUNIOR_HIGH_SCHOOL',
  NONE = 'NONE',
  POSTGRADUATE = 'POSTGRADUATE',
  PRIMARY_SCHOOL = 'PRIMARY_SCHOOL',
  SENIOR_HIGH_SCHOOL = 'SENIOR_HIGH_SCHOOL',
  UNIVERSITY = 'UNIVERSITY',
}

export enum CustomerEventType {
  ADDRESS_ADDED = 'ADDRESS_ADDED',
  ADDRESS_DEFAULT = 'ADDRESS_DEFAULT',
  ADDRESS_REMOVED = 'ADDRESS_REMOVED',
  CONTACT_EMAIL_ADDED = 'CONTACT_EMAIL_ADDED',
  CONTACT_EMAIL_DEFAULT = 'CONTACT_EMAIL_DEFAULT',
  CONTACT_EMAIL_REMOVED = 'CONTACT_EMAIL_REMOVED',
  CONTACT_PHONE_ADDED = 'CONTACT_PHONE_ADDED',
  CONTACT_PHONE_DEFAULT = 'CONTACT_PHONE_DEFAULT',
  CONTACT_PHONE_REMOVED = 'CONTACT_PHONE_REMOVED',
  CREATED = 'CREATED',
  DOCUMENT_DELETED = 'DOCUMENT_DELETED',
  DOCUMENT_EDITED = 'DOCUMENT_EDITED',
  DOCUMENT_UPLOADED = 'DOCUMENT_UPLOADED',
  UPDATED = 'UPDATED',
}

export enum CustomerMaritalStatus {
  DIVORCED = 'DIVORCED',
  MARRIED = 'MARRIED',
  SINGLE = 'SINGLE',
  WIDOWED = 'WIDOWED',
}

export enum CustomerScopeCode {
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_PHONE = 'INVALID_PHONE',
  REQUIRED = 'REQUIRED',
  REQUIRED_MIN_LENGTH = 'REQUIRED_MIN_LENGTH',
  UNIQUE = 'UNIQUE',
}

export type CustomerUpdateInput = {
  birthDate?: InputMaybe<Scalars['Date']['input']>
  educationLevel?: InputMaybe<CustomerEducationLevel>
  firstName: Scalars['String']['input']
  gender?: InputMaybe<Gender>
  governmentId?: InputMaybe<Scalars['String']['input']>
  lastName: Scalars['String']['input']
  marriageStatus?: InputMaybe<CustomerMaritalStatus>
  middleName?: InputMaybe<Scalars['String']['input']>
}

export type CustomersFilterInput = {
  emailAddress?: InputMaybe<Scalars['String']['input']>
  ids?: InputMaybe<Array<Scalars['ID']['input']>>
  name?: InputMaybe<Scalars['String']['input']>
  phoneNumber?: InputMaybe<Scalars['String']['input']>
  /** Searches based on name, phone number and email */
  search?: InputMaybe<Scalars['String']['input']>
}

export enum CustomersSortField {
  CREATED = 'CREATED',
  NAME = 'NAME',
}

export type CustomersSortInput = {
  direction: SortDirection
  field: CustomersSortField
}

export type DateRange = {
  gte?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export type MoneyInput = {
  amount: Scalars['Float']['input']
  currency: Scalars['String']['input']
}

export enum PaymentStatus {
  FULLY_PAID = 'FULLY_PAID',
  OVERPAID = 'OVERPAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  UNPAID = 'UNPAID',
}

export enum Period {
  DAY = 'DAY',
  HOUR = 'HOUR',
}

export enum Permission {
  MANAGE_APPS = 'MANAGE_APPS',
  MANAGE_CASES = 'MANAGE_CASES',
  MANAGE_CUSTOMERS = 'MANAGE_CUSTOMERS',
  MANAGE_SERVICE_PROVIDERS = 'MANAGE_SERVICE_PROVIDERS',
  MANAGE_SETTINGS = 'MANAGE_SETTINGS',
  MANAGE_USERS = 'MANAGE_USERS',
}

export enum Scope {
  ACCOUNT = 'ACCOUNT',
  AUTH = 'AUTH',
  CASE = 'CASE',
  COMMON = 'COMMON',
  CUSTOMER = 'CUSTOMER',
  SERVICE = 'SERVICE',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
}

export type ServiceCreateInput = {
  dpd?: InputMaybe<Scalars['Float']['input']>
  outstandingDebt?: InputMaybe<MoneyInput>
  principalAmount?: InputMaybe<MoneyInput>
  status: ServiceStatus
  title?: InputMaybe<Scalars['String']['input']>
  typeId: Scalars['ID']['input']
}

export type ServiceDocumentCreateInput = {
  file: Scalars['Upload']['input']
  note?: InputMaybe<Scalars['String']['input']>
  otherType?: InputMaybe<Scalars['String']['input']>
  type: ServiceDocumentType
}

export enum ServiceDocumentType {
  OTHER = 'OTHER',
  PAYMENT_PROOF_DEBT = 'PAYMENT_PROOF_DEBT',
}

export type ServiceDocumentUpdateInput = {
  note?: InputMaybe<Scalars['String']['input']>
  otherType?: InputMaybe<Scalars['String']['input']>
  type: ServiceDocumentType
}

export type ServiceProviderContactCreateInput = {
  contactEmail?: InputMaybe<ServiceProviderContactEmailInput>
  contactPhone?: InputMaybe<ServiceProviderContactPhoneInput>
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  middleName?: InputMaybe<Scalars['String']['input']>
  role: Scalars['String']['input']
}

export type ServiceProviderContactEmailInput = {
  emailAddress: Scalars['String']['input']
  note?: InputMaybe<Scalars['String']['input']>
}

export type ServiceProviderContactPhoneInput = {
  note?: InputMaybe<Scalars['String']['input']>
  phoneNumber: Scalars['String']['input']
}

export enum ServiceProviderContactSortField {
  CREATED = 'CREATED',
  NAME = 'NAME',
  ROLE = 'ROLE',
}

export type ServiceProviderContactSortInput = {
  direction: SortDirection
  field: ServiceProviderContactSortField
}

export type ServiceProviderContactUpdateInput = {
  contactEmail?: InputMaybe<ServiceProviderContactEmailInput>
  contactPhone?: InputMaybe<ServiceProviderContactPhoneInput>
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  middleName?: InputMaybe<Scalars['String']['input']>
  role: Scalars['String']['input']
}

export type ServiceProviderContactsFilterInput = {
  email?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  role?: InputMaybe<Scalars['String']['input']>
  /** Searches based on name, email, phone */
  search?: InputMaybe<Scalars['String']['input']>
  serviceProvider?: InputMaybe<Scalars['ID']['input']>
}

export type ServiceProviderCreateInput = {
  name: Scalars['String']['input']
  serviceTypes?: InputMaybe<Array<Scalars['ID']['input']>>
}

export enum ServiceProviderScopeCode {
  REQUIRED = 'REQUIRED',
}

export type ServiceProviderUpdateInput = {
  name: Scalars['String']['input']
  serviceTypes?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type ServiceProvidersFilterInput = {
  contactEmail?: InputMaybe<Scalars['String']['input']>
  contactName?: InputMaybe<Scalars['String']['input']>
  contactPhone?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  /** Searches based on name, contact name, contact email, contact phone */
  search?: InputMaybe<Scalars['String']['input']>
}

export enum ServiceProvidersSortField {
  CREATED = 'CREATED',
  NAME = 'NAME',
}

export type ServiceProvidersSortInput = {
  direction: SortDirection
  field: ServiceProvidersSortField
}

export enum ServiceScopeCode {
  REQUIRED = 'REQUIRED',
}

export type ServiceSetServiceProviderContactInput = {
  createServiceProviderContact?: InputMaybe<ServiceProviderContactCreateInput>
  updateServiceProviderContact?: InputMaybe<ServiceSetServiceProviderUpdateContactInput>
}

export type ServiceSetServiceProviderInput = {
  serviceProviderId: Scalars['ID']['input']
}

export type ServiceSetServiceProviderUpdateContactInput = {
  serviceProviderContact?: InputMaybe<ServiceProviderContactUpdateInput>
  serviceProviderContactId: Scalars['ID']['input']
}

export enum ServiceStatus {
  ACTIVE = 'ACTIVE',
  PROPOSED = 'PROPOSED',
  REQUESTED = 'REQUESTED',
  TERMINATED = 'TERMINATED',
}

export type ServiceTypeCreateInput = {
  isActive: Scalars['Boolean']['input']
  localizedTitle?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
  value?: InputMaybe<Scalars['String']['input']>
}

export type ServiceTypeUpdateInput = {
  isActive: Scalars['Boolean']['input']
  localizedTitle?: InputMaybe<Scalars['String']['input']>
  title: Scalars['String']['input']
  value?: InputMaybe<Scalars['String']['input']>
}

export type ServiceTypesFilterInput = {
  deactivated?: InputMaybe<Scalars['Boolean']['input']>
}

export enum ServiceTypesSortField {
  ACTIVE = 'ACTIVE',
  TITLE = 'TITLE',
}

export type ServiceTypesSortInput = {
  direction: SortDirection
  field: ServiceTypesSortField
}

export type ServiceUpdateInput = {
  dpd?: InputMaybe<Scalars['Float']['input']>
  outstandingDebt?: InputMaybe<MoneyInput>
  principalAmount?: InputMaybe<MoneyInput>
  status: ServiceStatus
  title: Scalars['String']['input']
  typeId: Scalars['ID']['input']
}

export type ServicesFilterInput = {
  status?: InputMaybe<Array<ServiceStatus>>
}

export enum ServicesSortField {
  CREATED = 'CREATED',
  STATUS = 'STATUS',
}

export type ServicesSortInput = {
  direction: SortDirection
  field: ServicesSortField
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type UserCreateInput = {
  email: Scalars['String']['input']
  firstName?: InputMaybe<Scalars['String']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  permissions?: InputMaybe<Array<Permission>>
  role?: InputMaybe<UserRole>
  roleDescription?: InputMaybe<Scalars['String']['input']>
}

export enum UserRole {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  NEGOTIATOR = 'NEGOTIATOR',
  OPERATOR = 'OPERATOR',
  OPERATOR_INACTIVE = 'OPERATOR_INACTIVE',
  TEAMLEADER = 'TEAMLEADER',
}

export type UserUpdateInput = {
  firstName?: InputMaybe<Scalars['String']['input']>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  permissions?: InputMaybe<Array<Permission>>
  role?: InputMaybe<UserRole>
  roleDescription?: InputMaybe<Scalars['String']['input']>
}

export type UsersFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  role?: InputMaybe<Array<UserRole>>
  search?: InputMaybe<Scalars['String']['input']>
}

export enum UsersSortField {
  ASSIGNED_CASES_COUNT = 'ASSIGNED_CASES_COUNT',
  EMAIL = 'EMAIL',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
}

export type UsersSortInput = {
  direction: SortDirection
  field: UsersSortField
}

export type AppCreateMutationVariables = Exact<{
  input: AppCreateInput
}>

export type AppCreateMutation = {
  __typename: 'Mutation'
  appCreate: {
    __typename: 'AppCreate'
    app: {
      __typename: 'App'
      id: string
      name: string
      isActive: boolean
      tokens: Array<{
        __typename: 'AppToken'
        id: string
        name: string
        token: string
      }>
      accountPermissions: Array<{
        __typename: 'AccountPermission'
        code: Permission
        name: string
      }>
    }
  }
}

export type AppDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type AppDeleteMutation = {
  __typename: 'Mutation'
  appDelete: {
    __typename: 'AppDelete'
    app: {
      __typename: 'App'
      id: string
      name: string
      isActive: boolean
      tokens: Array<{
        __typename: 'AppToken'
        id: string
        name: string
        token: string
      }>
      accountPermissions: Array<{
        __typename: 'AccountPermission'
        code: Permission
        name: string
      }>
    }
  }
}

export type AppUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: AppUpdateInput
}>

export type AppUpdateMutation = {
  __typename: 'Mutation'
  appUpdate: {
    __typename: 'AppUpdate'
    app: {
      __typename: 'App'
      id: string
      name: string
      isActive: boolean
      tokens: Array<{
        __typename: 'AppToken'
        id: string
        name: string
        token: string
      }>
      accountPermissions: Array<{
        __typename: 'AccountPermission'
        code: Permission
        name: string
      }>
    }
  }
}

export type AppTokenCreateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: AppTokenCreateInput
}>

export type AppTokenCreateMutation = {
  __typename: 'Mutation'
  appTokenCreate: {
    __typename: 'AppTokenCreate'
    token: string
    appToken: { __typename: 'AppToken'; name: string; id: string }
  }
}

export type AppTokenDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type AppTokenDeleteMutation = {
  __typename: 'Mutation'
  appTokenDelete: {
    __typename: 'AppTokenDelete'
    appToken: { __typename: 'AppToken'; name: string; id: string }
  }
}

export type AppListQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  sort?: InputMaybe<AppsSortInput>
  filter?: InputMaybe<AppsFilterInput>
}>

export type AppListQuery = {
  __typename: 'Query'
  apps: {
    __typename: 'AppConnection'
    totalCount: number
    edges: Array<{
      __typename: 'AppEdge'
      node: { __typename: 'App'; id: string; name: string; isActive: boolean }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
  }
}

export type AppDetailQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type AppDetailQuery = {
  __typename: 'Query'
  app: {
    __typename: 'App'
    id: string
    name: string
    isActive: boolean
    tokens: Array<{
      __typename: 'AppToken'
      id: string
      name: string
      token: string
    }>
    accountPermissions: Array<{
      __typename: 'AccountPermission'
      code: Permission
      name: string
    }>
  } | null
  permissions: Array<{
    __typename: 'AccountPermission'
    code: Permission
    name: string
  }>
}

export type AppCreatePermissionsQueryVariables = Exact<{ [key: string]: never }>

export type AppCreatePermissionsQuery = {
  __typename: 'Query'
  permissions: Array<{
    __typename: 'AccountPermission'
    code: Permission
    name: string
  }>
}

export type TokenAuthMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type TokenAuthMutation = {
  __typename: 'Mutation'
  tokenCreate: {
    __typename: 'TokenCreate'
    accessToken: string
    refreshToken: string
    user: {
      __typename: 'User'
      id: string
      email: string
      firstName: string | null
      lastName: string | null
      accountPermissions: Array<{
        __typename: 'AccountPermission'
        code: Permission
        name: string
      }>
      avatar: { __typename: 'Image'; url: string } | null
    }
  }
}

export type VerifyTokenMutationVariables = Exact<{
  token: Scalars['String']['input']
}>

export type VerifyTokenMutation = {
  __typename: 'Mutation'
  tokenVerifyUser: {
    __typename: 'TokenVerifyUser'
    user: {
      __typename: 'User'
      id: string
      email: string
      firstName: string | null
      lastName: string | null
      accountPermissions: Array<{
        __typename: 'AccountPermission'
        code: Permission
        name: string
      }>
      avatar: { __typename: 'Image'; url: string } | null
    } | null
  }
}

export type RefreshTokenMutationVariables = Exact<{
  token: Scalars['String']['input']
}>

export type RefreshTokenMutation = {
  __typename: 'Mutation'
  tokenRefresh: { __typename: 'TokenRefresh'; token: string }
}

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String']['input']
  redirectUrl: Scalars['String']['input']
}>

export type RequestPasswordResetMutation = {
  __typename: 'Mutation'
  requestPasswordReset: boolean
}

export type RequestedPasswordSetMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
  token: Scalars['String']['input']
}>

export type RequestedPasswordSetMutation = {
  __typename: 'Mutation'
  requestedPasswordSet: {
    __typename: 'RequestedPasswordSet'
    refreshToken: string
    accessToken: string
    user: {
      __typename: 'User'
      id: string
      email: string
      firstName: string | null
      lastName: string | null
      accountPermissions: Array<{
        __typename: 'AccountPermission'
        code: Permission
        name: string
      }>
      avatar: { __typename: 'Image'; url: string } | null
    }
  }
}

export type ConfirmAccountMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
  token: Scalars['String']['input']
}>

export type ConfirmAccountMutation = {
  __typename: 'Mutation'
  confirmAccount: {
    __typename: 'ConfirmAccount'
    refreshToken: string
    accessToken: string
    user: {
      __typename: 'User'
      id: string
      email: string
      firstName: string | null
      lastName: string | null
      accountPermissions: Array<{
        __typename: 'AccountPermission'
        code: Permission
        name: string
      }>
      avatar: { __typename: 'Image'; url: string } | null
    }
  }
}

export type CaseActionResultCreateMutationVariables = Exact<{
  input: CaseActionResultCreateInput
}>

export type CaseActionResultCreateMutation = {
  __typename: 'Mutation'
  create: {
    __typename: 'CaseActionResultCreate'
    newSettingsItem: { __typename: 'CaseActionResult'; id: string }
  }
}

export type CaseActionResultUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseActionResultUpdateInput
}>

export type CaseActionResultUpdateMutation = {
  __typename: 'Mutation'
  update: {
    __typename: 'CaseActionResultUpdate'
    updatedSettingsItem: { __typename: 'CaseActionResult'; id: string }
  }
}

export type CaseActionResultDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CaseActionResultDeleteMutation = {
  __typename: 'Mutation'
  delete: {
    __typename: 'CaseActionResultDelete'
    deletedSettingsItem: { __typename: 'CaseActionResult'; id: string }
  }
}

export type CaseActionTypeCreateMutationVariables = Exact<{
  input: CaseActionTypeCreateInput
}>

export type CaseActionTypeCreateMutation = {
  __typename: 'Mutation'
  create: {
    __typename: 'CaseActionTypeCreate'
    newSettingsItem: { __typename: 'CaseActionType'; id: string }
  }
}

export type CaseActionTypeUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseActionTypeUpdateInput
}>

export type CaseActionTypeUpdateMutation = {
  __typename: 'Mutation'
  update: {
    __typename: 'CaseActionTypeUpdate'
    updatedSettingsItem: { __typename: 'CaseActionType'; id: string }
  }
}

export type CaseActionTypeDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CaseActionTypeDeleteMutation = {
  __typename: 'Mutation'
  delete: {
    __typename: 'CaseActionTypeDelete'
    deletedSettingsItem: { __typename: 'CaseActionType'; id: string }
  }
}

export type CaseCloseReasonCreateMutationVariables = Exact<{
  input: CaseCloseReasonCreateInput
}>

export type CaseCloseReasonCreateMutation = {
  __typename: 'Mutation'
  create: {
    __typename: 'CaseCloseReasonCreate'
    newSettingsItem: { __typename: 'CaseCloseReason'; id: string }
  }
}

export type CaseCloseReasonUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseCloseReasonUpdateInput
}>

export type CaseCloseReasonUpdateMutation = {
  __typename: 'Mutation'
  update: {
    __typename: 'CaseCloseReasonUpdate'
    updatedSettingsItem: { __typename: 'CaseCloseReason'; id: string }
  }
}

export type CaseCloseReasonDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CaseCloseReasonDeleteMutation = {
  __typename: 'Mutation'
  delete: {
    __typename: 'CaseCloseReasonDelete'
    deletedSettingsItem: { __typename: 'CaseCloseReason'; id: string }
  }
}

export type ServiceTypeCreateMutationVariables = Exact<{
  input: ServiceTypeCreateInput
}>

export type ServiceTypeCreateMutation = {
  __typename: 'Mutation'
  create: {
    __typename: 'ServiceTypeCreate'
    newSettingsItem: { __typename: 'ServiceType'; id: string }
  }
}

export type ServiceTypeUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: ServiceTypeUpdateInput
}>

export type ServiceTypeUpdateMutation = {
  __typename: 'Mutation'
  update: {
    __typename: 'ServiceTypeUpdate'
    updatedSettingsItem: { __typename: 'ServiceType'; id: string }
  }
}

export type ServiceTypeDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ServiceTypeDeleteMutation = {
  __typename: 'Mutation'
  delete: {
    __typename: 'ServiceTypeDelete'
    deletedSettingsItem: { __typename: 'ServiceType'; id: string }
  }
}

export type CaseActionResultsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<CaseActionResultsSortInput>
}>

export type CaseActionResultsQuery = {
  __typename: 'Query'
  caseSettings: {
    __typename: 'CaseActionResultConnection'
    totalCount: number
    edges: Array<{
      __typename: 'CaseActionResultEdge'
      node: {
        __typename: 'CaseActionResult'
        id: string
        isActive: boolean
        value: string
        title: string
        createdAt: string
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      startCursor: string | null
      endCursor: string | null
    }
  }
}

export type CaseActionResultDetailQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CaseActionResultDetailQuery = {
  __typename: 'Query'
  caseSettingsItemDetail: {
    __typename: 'CaseActionResult'
    id: string
    isActive: boolean
    value: string
    title: string
    createdAt: string
  } | null
}

export type CaseActionTypesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<CaseActionTypesSortInput>
}>

export type CaseActionTypesQuery = {
  __typename: 'Query'
  caseSettings: {
    __typename: 'CaseActionTypeConnection'
    totalCount: number
    edges: Array<{
      __typename: 'CaseActionTypeEdge'
      node: {
        __typename: 'CaseActionType'
        id: string
        isActive: boolean
        value: string
        title: string
        createdAt: string
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      startCursor: string | null
      endCursor: string | null
    }
  }
}

export type CaseActionTypeDetailQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CaseActionTypeDetailQuery = {
  __typename: 'Query'
  caseSettingsItemDetail: {
    __typename: 'CaseActionType'
    id: string
    isActive: boolean
    value: string
    title: string
    createdAt: string
  } | null
}

export type CaseCloseReasonsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<CaseCloseReasonsSortInput>
}>

export type CaseCloseReasonsQuery = {
  __typename: 'Query'
  caseSettings: {
    __typename: 'CaseCloseReasonConnection'
    totalCount: number
    edges: Array<{
      __typename: 'CaseCloseReasonEdge'
      node: {
        __typename: 'CaseCloseReason'
        id: string
        isActive: boolean
        value: string
        title: string
        createdAt: string
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      startCursor: string | null
      endCursor: string | null
    }
  }
}

export type CaseCloseReasonDetailQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CaseCloseReasonDetailQuery = {
  __typename: 'Query'
  caseSettingsItemDetail: {
    __typename: 'CaseCloseReason'
    id: string
    isActive: boolean
    value: string
    title: string
    createdAt: string
  } | null
}

export type ServiceTypesQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<ServiceTypesSortInput>
}>

export type ServiceTypesQuery = {
  __typename: 'Query'
  caseSettings: {
    __typename: 'ServiceTypeConnection'
    totalCount: number
    edges: Array<{
      __typename: 'ServiceTypeEdge'
      node: {
        __typename: 'ServiceType'
        id: string
        isActive: boolean
        value: string
        title: string
        createdAt: string
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      startCursor: string | null
      endCursor: string | null
    }
  }
}

export type ServiceTypeDetailQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ServiceTypeDetailQuery = {
  __typename: 'Query'
  caseSettingsItemDetail: {
    __typename: 'ServiceType'
    id: string
    isActive: boolean
    value: string
    title: string
    createdAt: string
    localizedTitle: string | null
  } | null
}

export type CaseBulkAssignMutationVariables = Exact<{
  input: CaseBulkAssignInput
}>

export type CaseBulkAssignMutation = {
  __typename: 'Mutation'
  caseBulkAssign: {
    __typename: 'CaseBulkAssign'
    cases: Array<{
      __typename: 'Case'
      id: string
      status: CaseStatus
      assignedTo: { __typename: 'User'; id: string; displayName: string } | null
    }>
  }
}

export type CaseBulkReopenMutationVariables = Exact<{
  input: CaseBulkReopenInput
}>

export type CaseBulkReopenMutation = {
  __typename: 'Mutation'
  caseBulkReopen: {
    __typename: 'CaseBulkReopen'
    cases: Array<{ __typename: 'Case'; id: string; status: CaseStatus }>
  }
}

export type CaseCreateMutationVariables = Exact<{
  input: CaseCreateInput
  skipDuplicatesCheck: Scalars['Boolean']['input']
  assignToSelf: Scalars['Boolean']['input']
}>

export type CaseCreateMutation = {
  __typename: 'Mutation'
  caseCreate: {
    __typename: 'CaseCreate'
    case: { __typename: 'Case'; id: string }
  }
}

export type CaseAddressAssignMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseAddressAssignInput
}>

export type CaseAddressAssignMutation = {
  __typename: 'Mutation'
  caseAddressAssign: {
    __typename: 'CaseAddressAssign'
    case: {
      __typename: 'Case'
      id: string
      createdAt: string
      modifiedAt: string
      type: CaseType
      status: CaseStatus
      customStatus: CaseCustomStatus
      title: string
      defaultFeeRate: number
      customer: {
        __typename: 'Customer'
        id: string
        displayName: string
        birthDate: string | null
        gender: Gender | null
        governmentId: string | null
        educationLevel: CustomerEducationLevel | null
        marriageStatus: CustomerMaritalStatus | null
        note: string | null
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        }>
        defaultPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
        } | null
        defaultEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
        } | null
        contactPhones: Array<{
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        }>
        contactEmails: Array<{
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        }>
        documents: {
          __typename: 'CustomerDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CustomerDocumentEdge'
            node: {
              __typename: 'CustomerDocument'
              id: string
              type: CustomerDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }
      closeReason: {
        __typename: 'CaseCloseReason'
        id: string
        title: string
      } | null
      actions: Array<{
        __typename: 'CaseAction'
        id: string
        comment: string
        date: string | null
        createdAt: string
        type: { __typename: 'CaseActionType'; id: string; title: string }
        result: { __typename: 'CaseActionResult'; id: string; title: string }
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }>
      services: Array<{
        __typename: 'Service'
        id: string
        title: string
        createdAt: string
        dpd: number | null
        status: ServiceStatus
        type: { __typename: 'ServiceType'; id: string; title: string }
        outstandingDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        principalAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        serviceProvider: {
          __typename: 'ServiceProvider'
          id: string
          name: string
        } | null
        serviceProviderContact: {
          __typename: 'ServiceProviderContact'
          id: string
          displayName: string
          firstName: string
          middleName: string | null
          lastName: string
          role: string
          phoneNumber: string | null
          phoneNumberNote: string | null
          emailAddress: string | null
          emailAddressNote: string | null
        } | null
        documents: {
          __typename: 'ServiceDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'ServiceDocumentEdge'
            node: {
              __typename: 'ServiceDocument'
              id: string
              type: ServiceDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              service: { __typename: 'Service'; id: string; title: string }
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }>
      documents: {
        __typename: 'CaseDocumentConnection'
        totalCount: number
        edges: Array<{
          __typename: 'CaseDocumentEdge'
          node: {
            __typename: 'CaseDocument'
            id: string
            type: CaseDocumentType
            otherType: string | null
            fileUrl: string
            fileMimeType: string
            fileName: string
            note: string | null
            createdAt: string
            createdByApp: { __typename: 'App'; id: string; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
          }
        }>
      }
      events: {
        __typename: 'CaseEventConnection'
        edges: Array<{
          __typename: 'CaseEventEdge'
          node: {
            __typename: 'CaseEvent'
            id: string
            type: CaseEventType
            date: string
            parameters: any
            actionResult: {
              __typename: 'CaseActionResult'
              title: string
            } | null
            actionType: { __typename: 'CaseActionType'; title: string } | null
            createdByApp: { __typename: 'App'; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
            user: {
              __typename: 'User'
              id: string
              displayName: string
              email: string
            } | null
            service: { __typename: 'Service'; id: string; title: string } | null
          }
        }>
      }
      address: {
        __typename: 'CustomerAddress'
        id: string
        city: string
        cityArea: string | null
        note: string | null
        postalCode: string
        streetAddress1: string
        streetAddress2: string | null
        isPermanentResidency: boolean
        isDefault: boolean
        country: { __typename: 'Country'; code: string; name: string } | null
        countryArea: {
          __typename: 'CountryArea'
          id: string
          name: string
        } | null
      } | null
      contactEmail: {
        __typename: 'CustomerContactEmail'
        id: string
        emailAddress: string
        note: string | null
        isDefault: boolean
      } | null
      contactPhone: {
        __typename: 'CustomerContactPhone'
        id: string
        phoneNumber: string
        note: string | null
        isDefault: boolean
      } | null
      assignedTo: {
        __typename: 'User'
        id: string
        email: string
        displayName: string
      } | null
      totalDebt: {
        __typename: 'Money'
        amount: number
        currency: string
      } | null
      fee: {
        __typename: 'CaseFee'
        id: string
        paymentStatus: PaymentStatus
        feeRate: number
        createdAt: string
        requestedAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        paidAmount: { __typename: 'Money'; amount: number; currency: string }
        remainingAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        payments: Array<{
          __typename: 'CaseFeePayment'
          id: string
          paidAt: string
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
        }>
      } | null
      availableCaseActionResults: Array<{
        __typename: 'CaseActionResult'
        id: string
        title: string
      }>
      availableCaseActionTypes: Array<{
        __typename: 'CaseActionType'
        id: string
        title: string
      }>
      availableCaseCloseReasons: Array<{
        __typename: 'CaseCloseReason'
        id: string
        title: string
      }>
      availableServiceTypes: Array<{
        __typename: 'ServiceType'
        id: string
        title: string
      }>
      createdByUser: {
        __typename: 'User'
        id: string
        displayName: string
      } | null
      createdByApp: { __typename: 'App'; id: string; name: string } | null
    }
  }
}

export type CaseAddressAssignNewMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseAddressAssignNewInput
}>

export type CaseAddressAssignNewMutation = {
  __typename: 'Mutation'
  caseAddressAssignNew: {
    __typename: 'CaseAddressAssignNew'
    case: {
      __typename: 'Case'
      id: string
      createdAt: string
      modifiedAt: string
      type: CaseType
      status: CaseStatus
      customStatus: CaseCustomStatus
      title: string
      defaultFeeRate: number
      customer: {
        __typename: 'Customer'
        id: string
        displayName: string
        birthDate: string | null
        gender: Gender | null
        governmentId: string | null
        educationLevel: CustomerEducationLevel | null
        marriageStatus: CustomerMaritalStatus | null
        note: string | null
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        }>
        defaultPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
        } | null
        defaultEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
        } | null
        contactPhones: Array<{
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        }>
        contactEmails: Array<{
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        }>
        documents: {
          __typename: 'CustomerDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CustomerDocumentEdge'
            node: {
              __typename: 'CustomerDocument'
              id: string
              type: CustomerDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }
      closeReason: {
        __typename: 'CaseCloseReason'
        id: string
        title: string
      } | null
      actions: Array<{
        __typename: 'CaseAction'
        id: string
        comment: string
        date: string | null
        createdAt: string
        type: { __typename: 'CaseActionType'; id: string; title: string }
        result: { __typename: 'CaseActionResult'; id: string; title: string }
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }>
      services: Array<{
        __typename: 'Service'
        id: string
        title: string
        createdAt: string
        dpd: number | null
        status: ServiceStatus
        type: { __typename: 'ServiceType'; id: string; title: string }
        outstandingDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        principalAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        serviceProvider: {
          __typename: 'ServiceProvider'
          id: string
          name: string
        } | null
        serviceProviderContact: {
          __typename: 'ServiceProviderContact'
          id: string
          displayName: string
          firstName: string
          middleName: string | null
          lastName: string
          role: string
          phoneNumber: string | null
          phoneNumberNote: string | null
          emailAddress: string | null
          emailAddressNote: string | null
        } | null
        documents: {
          __typename: 'ServiceDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'ServiceDocumentEdge'
            node: {
              __typename: 'ServiceDocument'
              id: string
              type: ServiceDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              service: { __typename: 'Service'; id: string; title: string }
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }>
      documents: {
        __typename: 'CaseDocumentConnection'
        totalCount: number
        edges: Array<{
          __typename: 'CaseDocumentEdge'
          node: {
            __typename: 'CaseDocument'
            id: string
            type: CaseDocumentType
            otherType: string | null
            fileUrl: string
            fileMimeType: string
            fileName: string
            note: string | null
            createdAt: string
            createdByApp: { __typename: 'App'; id: string; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
          }
        }>
      }
      events: {
        __typename: 'CaseEventConnection'
        edges: Array<{
          __typename: 'CaseEventEdge'
          node: {
            __typename: 'CaseEvent'
            id: string
            type: CaseEventType
            date: string
            parameters: any
            actionResult: {
              __typename: 'CaseActionResult'
              title: string
            } | null
            actionType: { __typename: 'CaseActionType'; title: string } | null
            createdByApp: { __typename: 'App'; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
            user: {
              __typename: 'User'
              id: string
              displayName: string
              email: string
            } | null
            service: { __typename: 'Service'; id: string; title: string } | null
          }
        }>
      }
      address: {
        __typename: 'CustomerAddress'
        id: string
        city: string
        cityArea: string | null
        note: string | null
        postalCode: string
        streetAddress1: string
        streetAddress2: string | null
        isPermanentResidency: boolean
        isDefault: boolean
        country: { __typename: 'Country'; code: string; name: string } | null
        countryArea: {
          __typename: 'CountryArea'
          id: string
          name: string
        } | null
      } | null
      contactEmail: {
        __typename: 'CustomerContactEmail'
        id: string
        emailAddress: string
        note: string | null
        isDefault: boolean
      } | null
      contactPhone: {
        __typename: 'CustomerContactPhone'
        id: string
        phoneNumber: string
        note: string | null
        isDefault: boolean
      } | null
      assignedTo: {
        __typename: 'User'
        id: string
        email: string
        displayName: string
      } | null
      totalDebt: {
        __typename: 'Money'
        amount: number
        currency: string
      } | null
      fee: {
        __typename: 'CaseFee'
        id: string
        paymentStatus: PaymentStatus
        feeRate: number
        createdAt: string
        requestedAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        paidAmount: { __typename: 'Money'; amount: number; currency: string }
        remainingAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        payments: Array<{
          __typename: 'CaseFeePayment'
          id: string
          paidAt: string
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
        }>
      } | null
      availableCaseActionResults: Array<{
        __typename: 'CaseActionResult'
        id: string
        title: string
      }>
      availableCaseActionTypes: Array<{
        __typename: 'CaseActionType'
        id: string
        title: string
      }>
      availableCaseCloseReasons: Array<{
        __typename: 'CaseCloseReason'
        id: string
        title: string
      }>
      availableServiceTypes: Array<{
        __typename: 'ServiceType'
        id: string
        title: string
      }>
      createdByUser: {
        __typename: 'User'
        id: string
        displayName: string
      } | null
      createdByApp: { __typename: 'App'; id: string; name: string } | null
    }
  }
}

export type CasePhoneAssignMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CasePhoneAssignInput
}>

export type CasePhoneAssignMutation = {
  __typename: 'Mutation'
  casePhoneAssign: {
    __typename: 'CasePhoneAssign'
    case: {
      __typename: 'Case'
      id: string
      createdAt: string
      modifiedAt: string
      type: CaseType
      status: CaseStatus
      customStatus: CaseCustomStatus
      title: string
      defaultFeeRate: number
      customer: {
        __typename: 'Customer'
        id: string
        displayName: string
        birthDate: string | null
        gender: Gender | null
        governmentId: string | null
        educationLevel: CustomerEducationLevel | null
        marriageStatus: CustomerMaritalStatus | null
        note: string | null
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        }>
        defaultPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
        } | null
        defaultEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
        } | null
        contactPhones: Array<{
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        }>
        contactEmails: Array<{
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        }>
        documents: {
          __typename: 'CustomerDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CustomerDocumentEdge'
            node: {
              __typename: 'CustomerDocument'
              id: string
              type: CustomerDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }
      closeReason: {
        __typename: 'CaseCloseReason'
        id: string
        title: string
      } | null
      actions: Array<{
        __typename: 'CaseAction'
        id: string
        comment: string
        date: string | null
        createdAt: string
        type: { __typename: 'CaseActionType'; id: string; title: string }
        result: { __typename: 'CaseActionResult'; id: string; title: string }
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }>
      services: Array<{
        __typename: 'Service'
        id: string
        title: string
        createdAt: string
        dpd: number | null
        status: ServiceStatus
        type: { __typename: 'ServiceType'; id: string; title: string }
        outstandingDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        principalAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        serviceProvider: {
          __typename: 'ServiceProvider'
          id: string
          name: string
        } | null
        serviceProviderContact: {
          __typename: 'ServiceProviderContact'
          id: string
          displayName: string
          firstName: string
          middleName: string | null
          lastName: string
          role: string
          phoneNumber: string | null
          phoneNumberNote: string | null
          emailAddress: string | null
          emailAddressNote: string | null
        } | null
        documents: {
          __typename: 'ServiceDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'ServiceDocumentEdge'
            node: {
              __typename: 'ServiceDocument'
              id: string
              type: ServiceDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              service: { __typename: 'Service'; id: string; title: string }
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }>
      documents: {
        __typename: 'CaseDocumentConnection'
        totalCount: number
        edges: Array<{
          __typename: 'CaseDocumentEdge'
          node: {
            __typename: 'CaseDocument'
            id: string
            type: CaseDocumentType
            otherType: string | null
            fileUrl: string
            fileMimeType: string
            fileName: string
            note: string | null
            createdAt: string
            createdByApp: { __typename: 'App'; id: string; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
          }
        }>
      }
      events: {
        __typename: 'CaseEventConnection'
        edges: Array<{
          __typename: 'CaseEventEdge'
          node: {
            __typename: 'CaseEvent'
            id: string
            type: CaseEventType
            date: string
            parameters: any
            actionResult: {
              __typename: 'CaseActionResult'
              title: string
            } | null
            actionType: { __typename: 'CaseActionType'; title: string } | null
            createdByApp: { __typename: 'App'; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
            user: {
              __typename: 'User'
              id: string
              displayName: string
              email: string
            } | null
            service: { __typename: 'Service'; id: string; title: string } | null
          }
        }>
      }
      address: {
        __typename: 'CustomerAddress'
        id: string
        city: string
        cityArea: string | null
        note: string | null
        postalCode: string
        streetAddress1: string
        streetAddress2: string | null
        isPermanentResidency: boolean
        isDefault: boolean
        country: { __typename: 'Country'; code: string; name: string } | null
        countryArea: {
          __typename: 'CountryArea'
          id: string
          name: string
        } | null
      } | null
      contactEmail: {
        __typename: 'CustomerContactEmail'
        id: string
        emailAddress: string
        note: string | null
        isDefault: boolean
      } | null
      contactPhone: {
        __typename: 'CustomerContactPhone'
        id: string
        phoneNumber: string
        note: string | null
        isDefault: boolean
      } | null
      assignedTo: {
        __typename: 'User'
        id: string
        email: string
        displayName: string
      } | null
      totalDebt: {
        __typename: 'Money'
        amount: number
        currency: string
      } | null
      fee: {
        __typename: 'CaseFee'
        id: string
        paymentStatus: PaymentStatus
        feeRate: number
        createdAt: string
        requestedAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        paidAmount: { __typename: 'Money'; amount: number; currency: string }
        remainingAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        payments: Array<{
          __typename: 'CaseFeePayment'
          id: string
          paidAt: string
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
        }>
      } | null
      availableCaseActionResults: Array<{
        __typename: 'CaseActionResult'
        id: string
        title: string
      }>
      availableCaseActionTypes: Array<{
        __typename: 'CaseActionType'
        id: string
        title: string
      }>
      availableCaseCloseReasons: Array<{
        __typename: 'CaseCloseReason'
        id: string
        title: string
      }>
      availableServiceTypes: Array<{
        __typename: 'ServiceType'
        id: string
        title: string
      }>
      createdByUser: {
        __typename: 'User'
        id: string
        displayName: string
      } | null
      createdByApp: { __typename: 'App'; id: string; name: string } | null
    }
  }
}

export type CasePhoneAssignNewMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CasePhoneAssignNewInput
}>

export type CasePhoneAssignNewMutation = {
  __typename: 'Mutation'
  casePhoneAssignNew: {
    __typename: 'CasePhoneAssignNew'
    case: {
      __typename: 'Case'
      id: string
      createdAt: string
      modifiedAt: string
      type: CaseType
      status: CaseStatus
      customStatus: CaseCustomStatus
      title: string
      defaultFeeRate: number
      customer: {
        __typename: 'Customer'
        id: string
        displayName: string
        birthDate: string | null
        gender: Gender | null
        governmentId: string | null
        educationLevel: CustomerEducationLevel | null
        marriageStatus: CustomerMaritalStatus | null
        note: string | null
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        }>
        defaultPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
        } | null
        defaultEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
        } | null
        contactPhones: Array<{
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        }>
        contactEmails: Array<{
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        }>
        documents: {
          __typename: 'CustomerDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CustomerDocumentEdge'
            node: {
              __typename: 'CustomerDocument'
              id: string
              type: CustomerDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }
      closeReason: {
        __typename: 'CaseCloseReason'
        id: string
        title: string
      } | null
      actions: Array<{
        __typename: 'CaseAction'
        id: string
        comment: string
        date: string | null
        createdAt: string
        type: { __typename: 'CaseActionType'; id: string; title: string }
        result: { __typename: 'CaseActionResult'; id: string; title: string }
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }>
      services: Array<{
        __typename: 'Service'
        id: string
        title: string
        createdAt: string
        dpd: number | null
        status: ServiceStatus
        type: { __typename: 'ServiceType'; id: string; title: string }
        outstandingDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        principalAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        serviceProvider: {
          __typename: 'ServiceProvider'
          id: string
          name: string
        } | null
        serviceProviderContact: {
          __typename: 'ServiceProviderContact'
          id: string
          displayName: string
          firstName: string
          middleName: string | null
          lastName: string
          role: string
          phoneNumber: string | null
          phoneNumberNote: string | null
          emailAddress: string | null
          emailAddressNote: string | null
        } | null
        documents: {
          __typename: 'ServiceDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'ServiceDocumentEdge'
            node: {
              __typename: 'ServiceDocument'
              id: string
              type: ServiceDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              service: { __typename: 'Service'; id: string; title: string }
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }>
      documents: {
        __typename: 'CaseDocumentConnection'
        totalCount: number
        edges: Array<{
          __typename: 'CaseDocumentEdge'
          node: {
            __typename: 'CaseDocument'
            id: string
            type: CaseDocumentType
            otherType: string | null
            fileUrl: string
            fileMimeType: string
            fileName: string
            note: string | null
            createdAt: string
            createdByApp: { __typename: 'App'; id: string; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
          }
        }>
      }
      events: {
        __typename: 'CaseEventConnection'
        edges: Array<{
          __typename: 'CaseEventEdge'
          node: {
            __typename: 'CaseEvent'
            id: string
            type: CaseEventType
            date: string
            parameters: any
            actionResult: {
              __typename: 'CaseActionResult'
              title: string
            } | null
            actionType: { __typename: 'CaseActionType'; title: string } | null
            createdByApp: { __typename: 'App'; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
            user: {
              __typename: 'User'
              id: string
              displayName: string
              email: string
            } | null
            service: { __typename: 'Service'; id: string; title: string } | null
          }
        }>
      }
      address: {
        __typename: 'CustomerAddress'
        id: string
        city: string
        cityArea: string | null
        note: string | null
        postalCode: string
        streetAddress1: string
        streetAddress2: string | null
        isPermanentResidency: boolean
        isDefault: boolean
        country: { __typename: 'Country'; code: string; name: string } | null
        countryArea: {
          __typename: 'CountryArea'
          id: string
          name: string
        } | null
      } | null
      contactEmail: {
        __typename: 'CustomerContactEmail'
        id: string
        emailAddress: string
        note: string | null
        isDefault: boolean
      } | null
      contactPhone: {
        __typename: 'CustomerContactPhone'
        id: string
        phoneNumber: string
        note: string | null
        isDefault: boolean
      } | null
      assignedTo: {
        __typename: 'User'
        id: string
        email: string
        displayName: string
      } | null
      totalDebt: {
        __typename: 'Money'
        amount: number
        currency: string
      } | null
      fee: {
        __typename: 'CaseFee'
        id: string
        paymentStatus: PaymentStatus
        feeRate: number
        createdAt: string
        requestedAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        paidAmount: { __typename: 'Money'; amount: number; currency: string }
        remainingAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        payments: Array<{
          __typename: 'CaseFeePayment'
          id: string
          paidAt: string
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
        }>
      } | null
      availableCaseActionResults: Array<{
        __typename: 'CaseActionResult'
        id: string
        title: string
      }>
      availableCaseActionTypes: Array<{
        __typename: 'CaseActionType'
        id: string
        title: string
      }>
      availableCaseCloseReasons: Array<{
        __typename: 'CaseCloseReason'
        id: string
        title: string
      }>
      availableServiceTypes: Array<{
        __typename: 'ServiceType'
        id: string
        title: string
      }>
      createdByUser: {
        __typename: 'User'
        id: string
        displayName: string
      } | null
      createdByApp: { __typename: 'App'; id: string; name: string } | null
    }
  }
}

export type CaseEmailAssignMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseEmailAssignInput
}>

export type CaseEmailAssignMutation = {
  __typename: 'Mutation'
  caseEmailAssign: {
    __typename: 'CaseEmailAssign'
    case: {
      __typename: 'Case'
      id: string
      createdAt: string
      modifiedAt: string
      type: CaseType
      status: CaseStatus
      customStatus: CaseCustomStatus
      title: string
      defaultFeeRate: number
      customer: {
        __typename: 'Customer'
        id: string
        displayName: string
        birthDate: string | null
        gender: Gender | null
        governmentId: string | null
        educationLevel: CustomerEducationLevel | null
        marriageStatus: CustomerMaritalStatus | null
        note: string | null
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        }>
        defaultPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
        } | null
        defaultEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
        } | null
        contactPhones: Array<{
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        }>
        contactEmails: Array<{
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        }>
        documents: {
          __typename: 'CustomerDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CustomerDocumentEdge'
            node: {
              __typename: 'CustomerDocument'
              id: string
              type: CustomerDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }
      closeReason: {
        __typename: 'CaseCloseReason'
        id: string
        title: string
      } | null
      actions: Array<{
        __typename: 'CaseAction'
        id: string
        comment: string
        date: string | null
        createdAt: string
        type: { __typename: 'CaseActionType'; id: string; title: string }
        result: { __typename: 'CaseActionResult'; id: string; title: string }
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }>
      services: Array<{
        __typename: 'Service'
        id: string
        title: string
        createdAt: string
        dpd: number | null
        status: ServiceStatus
        type: { __typename: 'ServiceType'; id: string; title: string }
        outstandingDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        principalAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        serviceProvider: {
          __typename: 'ServiceProvider'
          id: string
          name: string
        } | null
        serviceProviderContact: {
          __typename: 'ServiceProviderContact'
          id: string
          displayName: string
          firstName: string
          middleName: string | null
          lastName: string
          role: string
          phoneNumber: string | null
          phoneNumberNote: string | null
          emailAddress: string | null
          emailAddressNote: string | null
        } | null
        documents: {
          __typename: 'ServiceDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'ServiceDocumentEdge'
            node: {
              __typename: 'ServiceDocument'
              id: string
              type: ServiceDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              service: { __typename: 'Service'; id: string; title: string }
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }>
      documents: {
        __typename: 'CaseDocumentConnection'
        totalCount: number
        edges: Array<{
          __typename: 'CaseDocumentEdge'
          node: {
            __typename: 'CaseDocument'
            id: string
            type: CaseDocumentType
            otherType: string | null
            fileUrl: string
            fileMimeType: string
            fileName: string
            note: string | null
            createdAt: string
            createdByApp: { __typename: 'App'; id: string; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
          }
        }>
      }
      events: {
        __typename: 'CaseEventConnection'
        edges: Array<{
          __typename: 'CaseEventEdge'
          node: {
            __typename: 'CaseEvent'
            id: string
            type: CaseEventType
            date: string
            parameters: any
            actionResult: {
              __typename: 'CaseActionResult'
              title: string
            } | null
            actionType: { __typename: 'CaseActionType'; title: string } | null
            createdByApp: { __typename: 'App'; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
            user: {
              __typename: 'User'
              id: string
              displayName: string
              email: string
            } | null
            service: { __typename: 'Service'; id: string; title: string } | null
          }
        }>
      }
      address: {
        __typename: 'CustomerAddress'
        id: string
        city: string
        cityArea: string | null
        note: string | null
        postalCode: string
        streetAddress1: string
        streetAddress2: string | null
        isPermanentResidency: boolean
        isDefault: boolean
        country: { __typename: 'Country'; code: string; name: string } | null
        countryArea: {
          __typename: 'CountryArea'
          id: string
          name: string
        } | null
      } | null
      contactEmail: {
        __typename: 'CustomerContactEmail'
        id: string
        emailAddress: string
        note: string | null
        isDefault: boolean
      } | null
      contactPhone: {
        __typename: 'CustomerContactPhone'
        id: string
        phoneNumber: string
        note: string | null
        isDefault: boolean
      } | null
      assignedTo: {
        __typename: 'User'
        id: string
        email: string
        displayName: string
      } | null
      totalDebt: {
        __typename: 'Money'
        amount: number
        currency: string
      } | null
      fee: {
        __typename: 'CaseFee'
        id: string
        paymentStatus: PaymentStatus
        feeRate: number
        createdAt: string
        requestedAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        paidAmount: { __typename: 'Money'; amount: number; currency: string }
        remainingAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        payments: Array<{
          __typename: 'CaseFeePayment'
          id: string
          paidAt: string
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
        }>
      } | null
      availableCaseActionResults: Array<{
        __typename: 'CaseActionResult'
        id: string
        title: string
      }>
      availableCaseActionTypes: Array<{
        __typename: 'CaseActionType'
        id: string
        title: string
      }>
      availableCaseCloseReasons: Array<{
        __typename: 'CaseCloseReason'
        id: string
        title: string
      }>
      availableServiceTypes: Array<{
        __typename: 'ServiceType'
        id: string
        title: string
      }>
      createdByUser: {
        __typename: 'User'
        id: string
        displayName: string
      } | null
      createdByApp: { __typename: 'App'; id: string; name: string } | null
    }
  }
}

export type CaseEmailAssignNewMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseEmailAssignNewInput
}>

export type CaseEmailAssignNewMutation = {
  __typename: 'Mutation'
  caseEmailAssignNew: {
    __typename: 'CaseEmailAssignNew'
    case: {
      __typename: 'Case'
      id: string
      createdAt: string
      modifiedAt: string
      type: CaseType
      status: CaseStatus
      customStatus: CaseCustomStatus
      title: string
      defaultFeeRate: number
      customer: {
        __typename: 'Customer'
        id: string
        displayName: string
        birthDate: string | null
        gender: Gender | null
        governmentId: string | null
        educationLevel: CustomerEducationLevel | null
        marriageStatus: CustomerMaritalStatus | null
        note: string | null
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        }>
        defaultPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
        } | null
        defaultEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
        } | null
        contactPhones: Array<{
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        }>
        contactEmails: Array<{
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        }>
        documents: {
          __typename: 'CustomerDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CustomerDocumentEdge'
            node: {
              __typename: 'CustomerDocument'
              id: string
              type: CustomerDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }
      closeReason: {
        __typename: 'CaseCloseReason'
        id: string
        title: string
      } | null
      actions: Array<{
        __typename: 'CaseAction'
        id: string
        comment: string
        date: string | null
        createdAt: string
        type: { __typename: 'CaseActionType'; id: string; title: string }
        result: { __typename: 'CaseActionResult'; id: string; title: string }
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }>
      services: Array<{
        __typename: 'Service'
        id: string
        title: string
        createdAt: string
        dpd: number | null
        status: ServiceStatus
        type: { __typename: 'ServiceType'; id: string; title: string }
        outstandingDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        principalAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        serviceProvider: {
          __typename: 'ServiceProvider'
          id: string
          name: string
        } | null
        serviceProviderContact: {
          __typename: 'ServiceProviderContact'
          id: string
          displayName: string
          firstName: string
          middleName: string | null
          lastName: string
          role: string
          phoneNumber: string | null
          phoneNumberNote: string | null
          emailAddress: string | null
          emailAddressNote: string | null
        } | null
        documents: {
          __typename: 'ServiceDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'ServiceDocumentEdge'
            node: {
              __typename: 'ServiceDocument'
              id: string
              type: ServiceDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              service: { __typename: 'Service'; id: string; title: string }
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }>
      documents: {
        __typename: 'CaseDocumentConnection'
        totalCount: number
        edges: Array<{
          __typename: 'CaseDocumentEdge'
          node: {
            __typename: 'CaseDocument'
            id: string
            type: CaseDocumentType
            otherType: string | null
            fileUrl: string
            fileMimeType: string
            fileName: string
            note: string | null
            createdAt: string
            createdByApp: { __typename: 'App'; id: string; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
          }
        }>
      }
      events: {
        __typename: 'CaseEventConnection'
        edges: Array<{
          __typename: 'CaseEventEdge'
          node: {
            __typename: 'CaseEvent'
            id: string
            type: CaseEventType
            date: string
            parameters: any
            actionResult: {
              __typename: 'CaseActionResult'
              title: string
            } | null
            actionType: { __typename: 'CaseActionType'; title: string } | null
            createdByApp: { __typename: 'App'; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
            user: {
              __typename: 'User'
              id: string
              displayName: string
              email: string
            } | null
            service: { __typename: 'Service'; id: string; title: string } | null
          }
        }>
      }
      address: {
        __typename: 'CustomerAddress'
        id: string
        city: string
        cityArea: string | null
        note: string | null
        postalCode: string
        streetAddress1: string
        streetAddress2: string | null
        isPermanentResidency: boolean
        isDefault: boolean
        country: { __typename: 'Country'; code: string; name: string } | null
        countryArea: {
          __typename: 'CountryArea'
          id: string
          name: string
        } | null
      } | null
      contactEmail: {
        __typename: 'CustomerContactEmail'
        id: string
        emailAddress: string
        note: string | null
        isDefault: boolean
      } | null
      contactPhone: {
        __typename: 'CustomerContactPhone'
        id: string
        phoneNumber: string
        note: string | null
        isDefault: boolean
      } | null
      assignedTo: {
        __typename: 'User'
        id: string
        email: string
        displayName: string
      } | null
      totalDebt: {
        __typename: 'Money'
        amount: number
        currency: string
      } | null
      fee: {
        __typename: 'CaseFee'
        id: string
        paymentStatus: PaymentStatus
        feeRate: number
        createdAt: string
        requestedAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        paidAmount: { __typename: 'Money'; amount: number; currency: string }
        remainingAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        payments: Array<{
          __typename: 'CaseFeePayment'
          id: string
          paidAt: string
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
        }>
      } | null
      availableCaseActionResults: Array<{
        __typename: 'CaseActionResult'
        id: string
        title: string
      }>
      availableCaseActionTypes: Array<{
        __typename: 'CaseActionType'
        id: string
        title: string
      }>
      availableCaseCloseReasons: Array<{
        __typename: 'CaseCloseReason'
        id: string
        title: string
      }>
      availableServiceTypes: Array<{
        __typename: 'ServiceType'
        id: string
        title: string
      }>
      createdByUser: {
        __typename: 'User'
        id: string
        displayName: string
      } | null
      createdByApp: { __typename: 'App'; id: string; name: string } | null
    }
  }
}

export type CaseNoteAddMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseNoteAddInput
}>

export type CaseNoteAddMutation = {
  __typename: 'Mutation'
  caseNoteAdd: {
    __typename: 'CaseNoteAdd'
    case: {
      __typename: 'Case'
      id: string
      createdAt: string
      modifiedAt: string
      type: CaseType
      status: CaseStatus
      customStatus: CaseCustomStatus
      title: string
      defaultFeeRate: number
      closeReason: {
        __typename: 'CaseCloseReason'
        id: string
        title: string
      } | null
      actions: Array<{
        __typename: 'CaseAction'
        id: string
        comment: string
        date: string | null
        createdAt: string
        type: { __typename: 'CaseActionType'; id: string; title: string }
        result: { __typename: 'CaseActionResult'; id: string; title: string }
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }>
      services: Array<{
        __typename: 'Service'
        id: string
        title: string
        createdAt: string
        dpd: number | null
        status: ServiceStatus
        type: { __typename: 'ServiceType'; id: string; title: string }
        outstandingDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        principalAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        serviceProvider: {
          __typename: 'ServiceProvider'
          id: string
          name: string
        } | null
        serviceProviderContact: {
          __typename: 'ServiceProviderContact'
          id: string
          displayName: string
          firstName: string
          middleName: string | null
          lastName: string
          role: string
          phoneNumber: string | null
          phoneNumberNote: string | null
          emailAddress: string | null
          emailAddressNote: string | null
        } | null
        documents: {
          __typename: 'ServiceDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'ServiceDocumentEdge'
            node: {
              __typename: 'ServiceDocument'
              id: string
              type: ServiceDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              service: { __typename: 'Service'; id: string; title: string }
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }>
      documents: {
        __typename: 'CaseDocumentConnection'
        totalCount: number
        edges: Array<{
          __typename: 'CaseDocumentEdge'
          node: {
            __typename: 'CaseDocument'
            id: string
            type: CaseDocumentType
            otherType: string | null
            fileUrl: string
            fileMimeType: string
            fileName: string
            note: string | null
            createdAt: string
            createdByApp: { __typename: 'App'; id: string; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
          }
        }>
      }
      events: {
        __typename: 'CaseEventConnection'
        edges: Array<{
          __typename: 'CaseEventEdge'
          node: {
            __typename: 'CaseEvent'
            id: string
            type: CaseEventType
            date: string
            parameters: any
            actionResult: {
              __typename: 'CaseActionResult'
              title: string
            } | null
            actionType: { __typename: 'CaseActionType'; title: string } | null
            createdByApp: { __typename: 'App'; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
            user: {
              __typename: 'User'
              id: string
              displayName: string
              email: string
            } | null
            service: { __typename: 'Service'; id: string; title: string } | null
          }
        }>
      }
      customer: {
        __typename: 'Customer'
        id: string
        displayName: string
        birthDate: string | null
        gender: Gender | null
        governmentId: string | null
        educationLevel: CustomerEducationLevel | null
        marriageStatus: CustomerMaritalStatus | null
        note: string | null
        defaultPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
        } | null
        defaultEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
        } | null
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        }>
        contactPhones: Array<{
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        }>
        contactEmails: Array<{
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        }>
        documents: {
          __typename: 'CustomerDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CustomerDocumentEdge'
            node: {
              __typename: 'CustomerDocument'
              id: string
              type: CustomerDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }
      address: {
        __typename: 'CustomerAddress'
        id: string
        city: string
        cityArea: string | null
        note: string | null
        postalCode: string
        streetAddress1: string
        streetAddress2: string | null
        isPermanentResidency: boolean
        isDefault: boolean
        country: { __typename: 'Country'; code: string; name: string } | null
        countryArea: {
          __typename: 'CountryArea'
          id: string
          name: string
        } | null
      } | null
      contactEmail: {
        __typename: 'CustomerContactEmail'
        id: string
        emailAddress: string
        note: string | null
        isDefault: boolean
      } | null
      contactPhone: {
        __typename: 'CustomerContactPhone'
        id: string
        phoneNumber: string
        note: string | null
        isDefault: boolean
      } | null
      assignedTo: {
        __typename: 'User'
        id: string
        email: string
        displayName: string
      } | null
      totalDebt: {
        __typename: 'Money'
        amount: number
        currency: string
      } | null
      fee: {
        __typename: 'CaseFee'
        id: string
        paymentStatus: PaymentStatus
        feeRate: number
        createdAt: string
        requestedAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        paidAmount: { __typename: 'Money'; amount: number; currency: string }
        remainingAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        payments: Array<{
          __typename: 'CaseFeePayment'
          id: string
          paidAt: string
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
        }>
      } | null
      availableCaseActionResults: Array<{
        __typename: 'CaseActionResult'
        id: string
        title: string
      }>
      availableCaseActionTypes: Array<{
        __typename: 'CaseActionType'
        id: string
        title: string
      }>
      availableCaseCloseReasons: Array<{
        __typename: 'CaseCloseReason'
        id: string
        title: string
      }>
      availableServiceTypes: Array<{
        __typename: 'ServiceType'
        id: string
        title: string
      }>
      createdByUser: {
        __typename: 'User'
        id: string
        displayName: string
      } | null
      createdByApp: { __typename: 'App'; id: string; name: string } | null
    }
  }
}

export type CaseAssignMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseAssignInput
}>

export type CaseAssignMutation = {
  __typename: 'Mutation'
  caseAssign: {
    __typename: 'CaseAssign'
    case: {
      __typename: 'Case'
      id: string
      createdAt: string
      modifiedAt: string
      type: CaseType
      status: CaseStatus
      customStatus: CaseCustomStatus
      title: string
      defaultFeeRate: number
      closeReason: {
        __typename: 'CaseCloseReason'
        id: string
        title: string
      } | null
      actions: Array<{
        __typename: 'CaseAction'
        id: string
        comment: string
        date: string | null
        createdAt: string
        type: { __typename: 'CaseActionType'; id: string; title: string }
        result: { __typename: 'CaseActionResult'; id: string; title: string }
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }>
      services: Array<{
        __typename: 'Service'
        id: string
        title: string
        createdAt: string
        dpd: number | null
        status: ServiceStatus
        type: { __typename: 'ServiceType'; id: string; title: string }
        outstandingDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        principalAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        serviceProvider: {
          __typename: 'ServiceProvider'
          id: string
          name: string
        } | null
        serviceProviderContact: {
          __typename: 'ServiceProviderContact'
          id: string
          displayName: string
          firstName: string
          middleName: string | null
          lastName: string
          role: string
          phoneNumber: string | null
          phoneNumberNote: string | null
          emailAddress: string | null
          emailAddressNote: string | null
        } | null
        documents: {
          __typename: 'ServiceDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'ServiceDocumentEdge'
            node: {
              __typename: 'ServiceDocument'
              id: string
              type: ServiceDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              service: { __typename: 'Service'; id: string; title: string }
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }>
      documents: {
        __typename: 'CaseDocumentConnection'
        totalCount: number
        edges: Array<{
          __typename: 'CaseDocumentEdge'
          node: {
            __typename: 'CaseDocument'
            id: string
            type: CaseDocumentType
            otherType: string | null
            fileUrl: string
            fileMimeType: string
            fileName: string
            note: string | null
            createdAt: string
            createdByApp: { __typename: 'App'; id: string; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
          }
        }>
      }
      events: {
        __typename: 'CaseEventConnection'
        edges: Array<{
          __typename: 'CaseEventEdge'
          node: {
            __typename: 'CaseEvent'
            id: string
            type: CaseEventType
            date: string
            parameters: any
            actionResult: {
              __typename: 'CaseActionResult'
              title: string
            } | null
            actionType: { __typename: 'CaseActionType'; title: string } | null
            createdByApp: { __typename: 'App'; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
            user: {
              __typename: 'User'
              id: string
              displayName: string
              email: string
            } | null
            service: { __typename: 'Service'; id: string; title: string } | null
          }
        }>
      }
      customer: {
        __typename: 'Customer'
        id: string
        displayName: string
        birthDate: string | null
        gender: Gender | null
        governmentId: string | null
        educationLevel: CustomerEducationLevel | null
        marriageStatus: CustomerMaritalStatus | null
        note: string | null
        defaultPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
        } | null
        defaultEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
        } | null
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        }>
        contactPhones: Array<{
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        }>
        contactEmails: Array<{
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        }>
        documents: {
          __typename: 'CustomerDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CustomerDocumentEdge'
            node: {
              __typename: 'CustomerDocument'
              id: string
              type: CustomerDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }
      address: {
        __typename: 'CustomerAddress'
        id: string
        city: string
        cityArea: string | null
        note: string | null
        postalCode: string
        streetAddress1: string
        streetAddress2: string | null
        isPermanentResidency: boolean
        isDefault: boolean
        country: { __typename: 'Country'; code: string; name: string } | null
        countryArea: {
          __typename: 'CountryArea'
          id: string
          name: string
        } | null
      } | null
      contactEmail: {
        __typename: 'CustomerContactEmail'
        id: string
        emailAddress: string
        note: string | null
        isDefault: boolean
      } | null
      contactPhone: {
        __typename: 'CustomerContactPhone'
        id: string
        phoneNumber: string
        note: string | null
        isDefault: boolean
      } | null
      assignedTo: {
        __typename: 'User'
        id: string
        email: string
        displayName: string
      } | null
      totalDebt: {
        __typename: 'Money'
        amount: number
        currency: string
      } | null
      fee: {
        __typename: 'CaseFee'
        id: string
        paymentStatus: PaymentStatus
        feeRate: number
        createdAt: string
        requestedAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        paidAmount: { __typename: 'Money'; amount: number; currency: string }
        remainingAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        payments: Array<{
          __typename: 'CaseFeePayment'
          id: string
          paidAt: string
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
        }>
      } | null
      availableCaseActionResults: Array<{
        __typename: 'CaseActionResult'
        id: string
        title: string
      }>
      availableCaseActionTypes: Array<{
        __typename: 'CaseActionType'
        id: string
        title: string
      }>
      availableCaseCloseReasons: Array<{
        __typename: 'CaseCloseReason'
        id: string
        title: string
      }>
      availableServiceTypes: Array<{
        __typename: 'ServiceType'
        id: string
        title: string
      }>
      createdByUser: {
        __typename: 'User'
        id: string
        displayName: string
      } | null
      createdByApp: { __typename: 'App'; id: string; name: string } | null
    }
  }
}

export type CaseDocumentGeneratePowerOfAttorneyMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseDocumentGeneratePowerOfAttorneyInput
}>

export type CaseDocumentGeneratePowerOfAttorneyMutation = {
  __typename: 'Mutation'
  caseDocumentGeneratePowerOfAttorney: {
    __typename: 'CaseDocumentGeneratePowerOfAttorney'
    caseDocument: {
      __typename: 'CaseDocument'
      fileUrl: string
      case: {
        __typename: 'Case'
        id: string
        documents: {
          __typename: 'CaseDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CaseDocumentEdge'
            node: {
              __typename: 'CaseDocument'
              id: string
              type: CaseDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }
    }
  }
}

export type CaseReopenMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CaseReopenMutation = {
  __typename: 'Mutation'
  caseReopen: {
    __typename: 'CaseReopen'
    case: {
      __typename: 'Case'
      id: string
      createdAt: string
      modifiedAt: string
      type: CaseType
      status: CaseStatus
      customStatus: CaseCustomStatus
      title: string
      defaultFeeRate: number
      closeReason: {
        __typename: 'CaseCloseReason'
        id: string
        title: string
      } | null
      actions: Array<{
        __typename: 'CaseAction'
        id: string
        comment: string
        date: string | null
        createdAt: string
        type: { __typename: 'CaseActionType'; id: string; title: string }
        result: { __typename: 'CaseActionResult'; id: string; title: string }
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }>
      services: Array<{
        __typename: 'Service'
        id: string
        title: string
        createdAt: string
        dpd: number | null
        status: ServiceStatus
        type: { __typename: 'ServiceType'; id: string; title: string }
        outstandingDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        principalAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        serviceProvider: {
          __typename: 'ServiceProvider'
          id: string
          name: string
        } | null
        serviceProviderContact: {
          __typename: 'ServiceProviderContact'
          id: string
          displayName: string
          firstName: string
          middleName: string | null
          lastName: string
          role: string
          phoneNumber: string | null
          phoneNumberNote: string | null
          emailAddress: string | null
          emailAddressNote: string | null
        } | null
        documents: {
          __typename: 'ServiceDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'ServiceDocumentEdge'
            node: {
              __typename: 'ServiceDocument'
              id: string
              type: ServiceDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              service: { __typename: 'Service'; id: string; title: string }
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }>
      documents: {
        __typename: 'CaseDocumentConnection'
        totalCount: number
        edges: Array<{
          __typename: 'CaseDocumentEdge'
          node: {
            __typename: 'CaseDocument'
            id: string
            type: CaseDocumentType
            otherType: string | null
            fileUrl: string
            fileMimeType: string
            fileName: string
            note: string | null
            createdAt: string
            createdByApp: { __typename: 'App'; id: string; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
          }
        }>
      }
      events: {
        __typename: 'CaseEventConnection'
        edges: Array<{
          __typename: 'CaseEventEdge'
          node: {
            __typename: 'CaseEvent'
            id: string
            type: CaseEventType
            date: string
            parameters: any
            actionResult: {
              __typename: 'CaseActionResult'
              title: string
            } | null
            actionType: { __typename: 'CaseActionType'; title: string } | null
            createdByApp: { __typename: 'App'; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
            user: {
              __typename: 'User'
              id: string
              displayName: string
              email: string
            } | null
            service: { __typename: 'Service'; id: string; title: string } | null
          }
        }>
      }
      customer: {
        __typename: 'Customer'
        id: string
        displayName: string
        birthDate: string | null
        gender: Gender | null
        governmentId: string | null
        educationLevel: CustomerEducationLevel | null
        marriageStatus: CustomerMaritalStatus | null
        note: string | null
        defaultPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
        } | null
        defaultEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
        } | null
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        }>
        contactPhones: Array<{
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        }>
        contactEmails: Array<{
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        }>
        documents: {
          __typename: 'CustomerDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CustomerDocumentEdge'
            node: {
              __typename: 'CustomerDocument'
              id: string
              type: CustomerDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }
      address: {
        __typename: 'CustomerAddress'
        id: string
        city: string
        cityArea: string | null
        note: string | null
        postalCode: string
        streetAddress1: string
        streetAddress2: string | null
        isPermanentResidency: boolean
        isDefault: boolean
        country: { __typename: 'Country'; code: string; name: string } | null
        countryArea: {
          __typename: 'CountryArea'
          id: string
          name: string
        } | null
      } | null
      contactEmail: {
        __typename: 'CustomerContactEmail'
        id: string
        emailAddress: string
        note: string | null
        isDefault: boolean
      } | null
      contactPhone: {
        __typename: 'CustomerContactPhone'
        id: string
        phoneNumber: string
        note: string | null
        isDefault: boolean
      } | null
      assignedTo: {
        __typename: 'User'
        id: string
        email: string
        displayName: string
      } | null
      totalDebt: {
        __typename: 'Money'
        amount: number
        currency: string
      } | null
      fee: {
        __typename: 'CaseFee'
        id: string
        paymentStatus: PaymentStatus
        feeRate: number
        createdAt: string
        requestedAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        paidAmount: { __typename: 'Money'; amount: number; currency: string }
        remainingAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        payments: Array<{
          __typename: 'CaseFeePayment'
          id: string
          paidAt: string
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
        }>
      } | null
      availableCaseActionResults: Array<{
        __typename: 'CaseActionResult'
        id: string
        title: string
      }>
      availableCaseActionTypes: Array<{
        __typename: 'CaseActionType'
        id: string
        title: string
      }>
      availableCaseCloseReasons: Array<{
        __typename: 'CaseCloseReason'
        id: string
        title: string
      }>
      availableServiceTypes: Array<{
        __typename: 'ServiceType'
        id: string
        title: string
      }>
      createdByUser: {
        __typename: 'User'
        id: string
        displayName: string
      } | null
      createdByApp: { __typename: 'App'; id: string; name: string } | null
    }
  }
}

export type CaseAssignSelfMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CaseAssignSelfMutation = {
  __typename: 'Mutation'
  caseAssignSelf: {
    __typename: 'CaseAssignSelf'
    case: {
      __typename: 'Case'
      id: string
      createdAt: string
      modifiedAt: string
      type: CaseType
      status: CaseStatus
      customStatus: CaseCustomStatus
      title: string
      defaultFeeRate: number
      closeReason: {
        __typename: 'CaseCloseReason'
        id: string
        title: string
      } | null
      actions: Array<{
        __typename: 'CaseAction'
        id: string
        comment: string
        date: string | null
        createdAt: string
        type: { __typename: 'CaseActionType'; id: string; title: string }
        result: { __typename: 'CaseActionResult'; id: string; title: string }
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }>
      services: Array<{
        __typename: 'Service'
        id: string
        title: string
        createdAt: string
        dpd: number | null
        status: ServiceStatus
        type: { __typename: 'ServiceType'; id: string; title: string }
        outstandingDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        principalAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        serviceProvider: {
          __typename: 'ServiceProvider'
          id: string
          name: string
        } | null
        serviceProviderContact: {
          __typename: 'ServiceProviderContact'
          id: string
          displayName: string
          firstName: string
          middleName: string | null
          lastName: string
          role: string
          phoneNumber: string | null
          phoneNumberNote: string | null
          emailAddress: string | null
          emailAddressNote: string | null
        } | null
        documents: {
          __typename: 'ServiceDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'ServiceDocumentEdge'
            node: {
              __typename: 'ServiceDocument'
              id: string
              type: ServiceDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              service: { __typename: 'Service'; id: string; title: string }
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }>
      documents: {
        __typename: 'CaseDocumentConnection'
        totalCount: number
        edges: Array<{
          __typename: 'CaseDocumentEdge'
          node: {
            __typename: 'CaseDocument'
            id: string
            type: CaseDocumentType
            otherType: string | null
            fileUrl: string
            fileMimeType: string
            fileName: string
            note: string | null
            createdAt: string
            createdByApp: { __typename: 'App'; id: string; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
          }
        }>
      }
      events: {
        __typename: 'CaseEventConnection'
        edges: Array<{
          __typename: 'CaseEventEdge'
          node: {
            __typename: 'CaseEvent'
            id: string
            type: CaseEventType
            date: string
            parameters: any
            actionResult: {
              __typename: 'CaseActionResult'
              title: string
            } | null
            actionType: { __typename: 'CaseActionType'; title: string } | null
            createdByApp: { __typename: 'App'; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
            user: {
              __typename: 'User'
              id: string
              displayName: string
              email: string
            } | null
            service: { __typename: 'Service'; id: string; title: string } | null
          }
        }>
      }
      customer: {
        __typename: 'Customer'
        id: string
        displayName: string
        birthDate: string | null
        gender: Gender | null
        governmentId: string | null
        educationLevel: CustomerEducationLevel | null
        marriageStatus: CustomerMaritalStatus | null
        note: string | null
        defaultPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
        } | null
        defaultEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
        } | null
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        }>
        contactPhones: Array<{
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        }>
        contactEmails: Array<{
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        }>
        documents: {
          __typename: 'CustomerDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CustomerDocumentEdge'
            node: {
              __typename: 'CustomerDocument'
              id: string
              type: CustomerDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }
      address: {
        __typename: 'CustomerAddress'
        id: string
        city: string
        cityArea: string | null
        note: string | null
        postalCode: string
        streetAddress1: string
        streetAddress2: string | null
        isPermanentResidency: boolean
        isDefault: boolean
        country: { __typename: 'Country'; code: string; name: string } | null
        countryArea: {
          __typename: 'CountryArea'
          id: string
          name: string
        } | null
      } | null
      contactEmail: {
        __typename: 'CustomerContactEmail'
        id: string
        emailAddress: string
        note: string | null
        isDefault: boolean
      } | null
      contactPhone: {
        __typename: 'CustomerContactPhone'
        id: string
        phoneNumber: string
        note: string | null
        isDefault: boolean
      } | null
      assignedTo: {
        __typename: 'User'
        id: string
        email: string
        displayName: string
      } | null
      totalDebt: {
        __typename: 'Money'
        amount: number
        currency: string
      } | null
      fee: {
        __typename: 'CaseFee'
        id: string
        paymentStatus: PaymentStatus
        feeRate: number
        createdAt: string
        requestedAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        paidAmount: { __typename: 'Money'; amount: number; currency: string }
        remainingAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        payments: Array<{
          __typename: 'CaseFeePayment'
          id: string
          paidAt: string
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
        }>
      } | null
      availableCaseActionResults: Array<{
        __typename: 'CaseActionResult'
        id: string
        title: string
      }>
      availableCaseActionTypes: Array<{
        __typename: 'CaseActionType'
        id: string
        title: string
      }>
      availableCaseCloseReasons: Array<{
        __typename: 'CaseCloseReason'
        id: string
        title: string
      }>
      availableServiceTypes: Array<{
        __typename: 'ServiceType'
        id: string
        title: string
      }>
      createdByUser: {
        __typename: 'User'
        id: string
        displayName: string
      } | null
      createdByApp: { __typename: 'App'; id: string; name: string } | null
    }
  }
}

export type ServiceCreateMutationVariables = Exact<{
  caseId: Scalars['ID']['input']
  input: ServiceCreateInput
}>

export type ServiceCreateMutation = {
  __typename: 'Mutation'
  serviceCreate: {
    __typename: 'ServiceCreate'
    service: {
      __typename: 'Service'
      title: string
      case: {
        __typename: 'Case'
        id: string
        createdAt: string
        modifiedAt: string
        type: CaseType
        status: CaseStatus
        customStatus: CaseCustomStatus
        title: string
        defaultFeeRate: number
        closeReason: {
          __typename: 'CaseCloseReason'
          id: string
          title: string
        } | null
        actions: Array<{
          __typename: 'CaseAction'
          id: string
          comment: string
          date: string | null
          createdAt: string
          type: { __typename: 'CaseActionType'; id: string; title: string }
          result: { __typename: 'CaseActionResult'; id: string; title: string }
          createdByUser: {
            __typename: 'User'
            id: string
            displayName: string
          } | null
          createdByApp: { __typename: 'App'; id: string; name: string } | null
        }>
        services: Array<{
          __typename: 'Service'
          id: string
          title: string
          createdAt: string
          dpd: number | null
          status: ServiceStatus
          type: { __typename: 'ServiceType'; id: string; title: string }
          outstandingDebt: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          principalAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          } | null
          serviceProvider: {
            __typename: 'ServiceProvider'
            id: string
            name: string
          } | null
          serviceProviderContact: {
            __typename: 'ServiceProviderContact'
            id: string
            displayName: string
            firstName: string
            middleName: string | null
            lastName: string
            role: string
            phoneNumber: string | null
            phoneNumberNote: string | null
            emailAddress: string | null
            emailAddressNote: string | null
          } | null
          documents: {
            __typename: 'ServiceDocumentConnection'
            totalCount: number
            edges: Array<{
              __typename: 'ServiceDocumentEdge'
              node: {
                __typename: 'ServiceDocument'
                id: string
                type: ServiceDocumentType
                otherType: string | null
                fileUrl: string
                fileMimeType: string
                fileName: string
                note: string | null
                createdAt: string
                service: { __typename: 'Service'; id: string; title: string }
                createdByApp: {
                  __typename: 'App'
                  id: string
                  name: string
                } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
              }
            }>
          }
        }>
        documents: {
          __typename: 'CaseDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CaseDocumentEdge'
            node: {
              __typename: 'CaseDocument'
              id: string
              type: CaseDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
        events: {
          __typename: 'CaseEventConnection'
          edges: Array<{
            __typename: 'CaseEventEdge'
            node: {
              __typename: 'CaseEvent'
              id: string
              type: CaseEventType
              date: string
              parameters: any
              actionResult: {
                __typename: 'CaseActionResult'
                title: string
              } | null
              actionType: { __typename: 'CaseActionType'; title: string } | null
              createdByApp: { __typename: 'App'; name: string } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
              user: {
                __typename: 'User'
                id: string
                displayName: string
                email: string
              } | null
              service: {
                __typename: 'Service'
                id: string
                title: string
              } | null
            }
          }>
        }
        customer: {
          __typename: 'Customer'
          id: string
          displayName: string
          birthDate: string | null
          gender: Gender | null
          governmentId: string | null
          educationLevel: CustomerEducationLevel | null
          marriageStatus: CustomerMaritalStatus | null
          note: string | null
          defaultPhone: {
            __typename: 'CustomerContactPhone'
            id: string
            phoneNumber: string
          } | null
          defaultEmail: {
            __typename: 'CustomerContactEmail'
            id: string
            emailAddress: string
          } | null
          addresses: Array<{
            __typename: 'CustomerAddress'
            id: string
            city: string
            cityArea: string | null
            note: string | null
            postalCode: string
            streetAddress1: string
            streetAddress2: string | null
            isPermanentResidency: boolean
            isDefault: boolean
            country: {
              __typename: 'Country'
              code: string
              name: string
            } | null
            countryArea: {
              __typename: 'CountryArea'
              id: string
              name: string
            } | null
          }>
          contactPhones: Array<{
            __typename: 'CustomerContactPhone'
            id: string
            phoneNumber: string
            note: string | null
            isDefault: boolean
          }>
          contactEmails: Array<{
            __typename: 'CustomerContactEmail'
            id: string
            emailAddress: string
            note: string | null
            isDefault: boolean
          }>
          documents: {
            __typename: 'CustomerDocumentConnection'
            totalCount: number
            edges: Array<{
              __typename: 'CustomerDocumentEdge'
              node: {
                __typename: 'CustomerDocument'
                id: string
                type: CustomerDocumentType
                otherType: string | null
                fileUrl: string
                fileMimeType: string
                fileName: string
                note: string | null
                createdAt: string
                createdByApp: {
                  __typename: 'App'
                  id: string
                  name: string
                } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
              }
            }>
          }
        }
        address: {
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        } | null
        contactEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        } | null
        contactPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        } | null
        assignedTo: {
          __typename: 'User'
          id: string
          email: string
          displayName: string
        } | null
        totalDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        fee: {
          __typename: 'CaseFee'
          id: string
          paymentStatus: PaymentStatus
          feeRate: number
          createdAt: string
          requestedAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
          remainingAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          payments: Array<{
            __typename: 'CaseFeePayment'
            id: string
            paidAt: string
            paidAmount: {
              __typename: 'Money'
              amount: number
              currency: string
            }
          }>
        } | null
        availableCaseActionResults: Array<{
          __typename: 'CaseActionResult'
          id: string
          title: string
        }>
        availableCaseActionTypes: Array<{
          __typename: 'CaseActionType'
          id: string
          title: string
        }>
        availableCaseCloseReasons: Array<{
          __typename: 'CaseCloseReason'
          id: string
          title: string
        }>
        availableServiceTypes: Array<{
          __typename: 'ServiceType'
          id: string
          title: string
        }>
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }
    }
  }
}

export type ServiceUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: ServiceUpdateInput
}>

export type ServiceUpdateMutation = {
  __typename: 'Mutation'
  serviceUpdate: {
    __typename: 'ServiceUpdate'
    service: {
      __typename: 'Service'
      title: string
      case: {
        __typename: 'Case'
        id: string
        createdAt: string
        modifiedAt: string
        type: CaseType
        status: CaseStatus
        customStatus: CaseCustomStatus
        title: string
        defaultFeeRate: number
        closeReason: {
          __typename: 'CaseCloseReason'
          id: string
          title: string
        } | null
        actions: Array<{
          __typename: 'CaseAction'
          id: string
          comment: string
          date: string | null
          createdAt: string
          type: { __typename: 'CaseActionType'; id: string; title: string }
          result: { __typename: 'CaseActionResult'; id: string; title: string }
          createdByUser: {
            __typename: 'User'
            id: string
            displayName: string
          } | null
          createdByApp: { __typename: 'App'; id: string; name: string } | null
        }>
        services: Array<{
          __typename: 'Service'
          id: string
          title: string
          createdAt: string
          dpd: number | null
          status: ServiceStatus
          type: { __typename: 'ServiceType'; id: string; title: string }
          outstandingDebt: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          principalAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          } | null
          serviceProvider: {
            __typename: 'ServiceProvider'
            id: string
            name: string
          } | null
          serviceProviderContact: {
            __typename: 'ServiceProviderContact'
            id: string
            displayName: string
            firstName: string
            middleName: string | null
            lastName: string
            role: string
            phoneNumber: string | null
            phoneNumberNote: string | null
            emailAddress: string | null
            emailAddressNote: string | null
          } | null
          documents: {
            __typename: 'ServiceDocumentConnection'
            totalCount: number
            edges: Array<{
              __typename: 'ServiceDocumentEdge'
              node: {
                __typename: 'ServiceDocument'
                id: string
                type: ServiceDocumentType
                otherType: string | null
                fileUrl: string
                fileMimeType: string
                fileName: string
                note: string | null
                createdAt: string
                service: { __typename: 'Service'; id: string; title: string }
                createdByApp: {
                  __typename: 'App'
                  id: string
                  name: string
                } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
              }
            }>
          }
        }>
        documents: {
          __typename: 'CaseDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CaseDocumentEdge'
            node: {
              __typename: 'CaseDocument'
              id: string
              type: CaseDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
        events: {
          __typename: 'CaseEventConnection'
          edges: Array<{
            __typename: 'CaseEventEdge'
            node: {
              __typename: 'CaseEvent'
              id: string
              type: CaseEventType
              date: string
              parameters: any
              actionResult: {
                __typename: 'CaseActionResult'
                title: string
              } | null
              actionType: { __typename: 'CaseActionType'; title: string } | null
              createdByApp: { __typename: 'App'; name: string } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
              user: {
                __typename: 'User'
                id: string
                displayName: string
                email: string
              } | null
              service: {
                __typename: 'Service'
                id: string
                title: string
              } | null
            }
          }>
        }
        customer: {
          __typename: 'Customer'
          id: string
          displayName: string
          birthDate: string | null
          gender: Gender | null
          governmentId: string | null
          educationLevel: CustomerEducationLevel | null
          marriageStatus: CustomerMaritalStatus | null
          note: string | null
          defaultPhone: {
            __typename: 'CustomerContactPhone'
            id: string
            phoneNumber: string
          } | null
          defaultEmail: {
            __typename: 'CustomerContactEmail'
            id: string
            emailAddress: string
          } | null
          addresses: Array<{
            __typename: 'CustomerAddress'
            id: string
            city: string
            cityArea: string | null
            note: string | null
            postalCode: string
            streetAddress1: string
            streetAddress2: string | null
            isPermanentResidency: boolean
            isDefault: boolean
            country: {
              __typename: 'Country'
              code: string
              name: string
            } | null
            countryArea: {
              __typename: 'CountryArea'
              id: string
              name: string
            } | null
          }>
          contactPhones: Array<{
            __typename: 'CustomerContactPhone'
            id: string
            phoneNumber: string
            note: string | null
            isDefault: boolean
          }>
          contactEmails: Array<{
            __typename: 'CustomerContactEmail'
            id: string
            emailAddress: string
            note: string | null
            isDefault: boolean
          }>
          documents: {
            __typename: 'CustomerDocumentConnection'
            totalCount: number
            edges: Array<{
              __typename: 'CustomerDocumentEdge'
              node: {
                __typename: 'CustomerDocument'
                id: string
                type: CustomerDocumentType
                otherType: string | null
                fileUrl: string
                fileMimeType: string
                fileName: string
                note: string | null
                createdAt: string
                createdByApp: {
                  __typename: 'App'
                  id: string
                  name: string
                } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
              }
            }>
          }
        }
        address: {
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        } | null
        contactEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        } | null
        contactPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        } | null
        assignedTo: {
          __typename: 'User'
          id: string
          email: string
          displayName: string
        } | null
        totalDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        fee: {
          __typename: 'CaseFee'
          id: string
          paymentStatus: PaymentStatus
          feeRate: number
          createdAt: string
          requestedAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
          remainingAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          payments: Array<{
            __typename: 'CaseFeePayment'
            id: string
            paidAt: string
            paidAmount: {
              __typename: 'Money'
              amount: number
              currency: string
            }
          }>
        } | null
        availableCaseActionResults: Array<{
          __typename: 'CaseActionResult'
          id: string
          title: string
        }>
        availableCaseActionTypes: Array<{
          __typename: 'CaseActionType'
          id: string
          title: string
        }>
        availableCaseCloseReasons: Array<{
          __typename: 'CaseCloseReason'
          id: string
          title: string
        }>
        availableServiceTypes: Array<{
          __typename: 'ServiceType'
          id: string
          title: string
        }>
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }
    }
  }
}

export type ServiceSetProviderMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: ServiceSetServiceProviderInput
}>

export type ServiceSetProviderMutation = {
  __typename: 'Mutation'
  serviceSetServiceProvider: {
    __typename: 'ServiceSetServiceProvider'
    service: {
      __typename: 'Service'
      title: string
      case: {
        __typename: 'Case'
        id: string
        createdAt: string
        modifiedAt: string
        type: CaseType
        status: CaseStatus
        customStatus: CaseCustomStatus
        title: string
        defaultFeeRate: number
        closeReason: {
          __typename: 'CaseCloseReason'
          id: string
          title: string
        } | null
        actions: Array<{
          __typename: 'CaseAction'
          id: string
          comment: string
          date: string | null
          createdAt: string
          type: { __typename: 'CaseActionType'; id: string; title: string }
          result: { __typename: 'CaseActionResult'; id: string; title: string }
          createdByUser: {
            __typename: 'User'
            id: string
            displayName: string
          } | null
          createdByApp: { __typename: 'App'; id: string; name: string } | null
        }>
        services: Array<{
          __typename: 'Service'
          id: string
          title: string
          createdAt: string
          dpd: number | null
          status: ServiceStatus
          type: { __typename: 'ServiceType'; id: string; title: string }
          outstandingDebt: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          principalAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          } | null
          serviceProvider: {
            __typename: 'ServiceProvider'
            id: string
            name: string
          } | null
          serviceProviderContact: {
            __typename: 'ServiceProviderContact'
            id: string
            displayName: string
            firstName: string
            middleName: string | null
            lastName: string
            role: string
            phoneNumber: string | null
            phoneNumberNote: string | null
            emailAddress: string | null
            emailAddressNote: string | null
          } | null
          documents: {
            __typename: 'ServiceDocumentConnection'
            totalCount: number
            edges: Array<{
              __typename: 'ServiceDocumentEdge'
              node: {
                __typename: 'ServiceDocument'
                id: string
                type: ServiceDocumentType
                otherType: string | null
                fileUrl: string
                fileMimeType: string
                fileName: string
                note: string | null
                createdAt: string
                service: { __typename: 'Service'; id: string; title: string }
                createdByApp: {
                  __typename: 'App'
                  id: string
                  name: string
                } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
              }
            }>
          }
        }>
        documents: {
          __typename: 'CaseDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CaseDocumentEdge'
            node: {
              __typename: 'CaseDocument'
              id: string
              type: CaseDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
        events: {
          __typename: 'CaseEventConnection'
          edges: Array<{
            __typename: 'CaseEventEdge'
            node: {
              __typename: 'CaseEvent'
              id: string
              type: CaseEventType
              date: string
              parameters: any
              actionResult: {
                __typename: 'CaseActionResult'
                title: string
              } | null
              actionType: { __typename: 'CaseActionType'; title: string } | null
              createdByApp: { __typename: 'App'; name: string } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
              user: {
                __typename: 'User'
                id: string
                displayName: string
                email: string
              } | null
              service: {
                __typename: 'Service'
                id: string
                title: string
              } | null
            }
          }>
        }
        customer: {
          __typename: 'Customer'
          id: string
          displayName: string
          birthDate: string | null
          gender: Gender | null
          governmentId: string | null
          educationLevel: CustomerEducationLevel | null
          marriageStatus: CustomerMaritalStatus | null
          note: string | null
          defaultPhone: {
            __typename: 'CustomerContactPhone'
            id: string
            phoneNumber: string
          } | null
          defaultEmail: {
            __typename: 'CustomerContactEmail'
            id: string
            emailAddress: string
          } | null
          addresses: Array<{
            __typename: 'CustomerAddress'
            id: string
            city: string
            cityArea: string | null
            note: string | null
            postalCode: string
            streetAddress1: string
            streetAddress2: string | null
            isPermanentResidency: boolean
            isDefault: boolean
            country: {
              __typename: 'Country'
              code: string
              name: string
            } | null
            countryArea: {
              __typename: 'CountryArea'
              id: string
              name: string
            } | null
          }>
          contactPhones: Array<{
            __typename: 'CustomerContactPhone'
            id: string
            phoneNumber: string
            note: string | null
            isDefault: boolean
          }>
          contactEmails: Array<{
            __typename: 'CustomerContactEmail'
            id: string
            emailAddress: string
            note: string | null
            isDefault: boolean
          }>
          documents: {
            __typename: 'CustomerDocumentConnection'
            totalCount: number
            edges: Array<{
              __typename: 'CustomerDocumentEdge'
              node: {
                __typename: 'CustomerDocument'
                id: string
                type: CustomerDocumentType
                otherType: string | null
                fileUrl: string
                fileMimeType: string
                fileName: string
                note: string | null
                createdAt: string
                createdByApp: {
                  __typename: 'App'
                  id: string
                  name: string
                } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
              }
            }>
          }
        }
        address: {
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        } | null
        contactEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        } | null
        contactPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        } | null
        assignedTo: {
          __typename: 'User'
          id: string
          email: string
          displayName: string
        } | null
        totalDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        fee: {
          __typename: 'CaseFee'
          id: string
          paymentStatus: PaymentStatus
          feeRate: number
          createdAt: string
          requestedAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
          remainingAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          payments: Array<{
            __typename: 'CaseFeePayment'
            id: string
            paidAt: string
            paidAmount: {
              __typename: 'Money'
              amount: number
              currency: string
            }
          }>
        } | null
        availableCaseActionResults: Array<{
          __typename: 'CaseActionResult'
          id: string
          title: string
        }>
        availableCaseActionTypes: Array<{
          __typename: 'CaseActionType'
          id: string
          title: string
        }>
        availableCaseCloseReasons: Array<{
          __typename: 'CaseCloseReason'
          id: string
          title: string
        }>
        availableServiceTypes: Array<{
          __typename: 'ServiceType'
          id: string
          title: string
        }>
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }
    }
  }
}

export type ServiceSetProviderContactMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: ServiceSetServiceProviderContactInput
}>

export type ServiceSetProviderContactMutation = {
  __typename: 'Mutation'
  serviceSetServiceProviderContact: {
    __typename: 'ServiceSetServiceProviderContact'
    service: {
      __typename: 'Service'
      title: string
      case: {
        __typename: 'Case'
        id: string
        createdAt: string
        modifiedAt: string
        type: CaseType
        status: CaseStatus
        customStatus: CaseCustomStatus
        title: string
        defaultFeeRate: number
        closeReason: {
          __typename: 'CaseCloseReason'
          id: string
          title: string
        } | null
        actions: Array<{
          __typename: 'CaseAction'
          id: string
          comment: string
          date: string | null
          createdAt: string
          type: { __typename: 'CaseActionType'; id: string; title: string }
          result: { __typename: 'CaseActionResult'; id: string; title: string }
          createdByUser: {
            __typename: 'User'
            id: string
            displayName: string
          } | null
          createdByApp: { __typename: 'App'; id: string; name: string } | null
        }>
        services: Array<{
          __typename: 'Service'
          id: string
          title: string
          createdAt: string
          dpd: number | null
          status: ServiceStatus
          type: { __typename: 'ServiceType'; id: string; title: string }
          outstandingDebt: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          principalAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          } | null
          serviceProvider: {
            __typename: 'ServiceProvider'
            id: string
            name: string
          } | null
          serviceProviderContact: {
            __typename: 'ServiceProviderContact'
            id: string
            displayName: string
            firstName: string
            middleName: string | null
            lastName: string
            role: string
            phoneNumber: string | null
            phoneNumberNote: string | null
            emailAddress: string | null
            emailAddressNote: string | null
          } | null
          documents: {
            __typename: 'ServiceDocumentConnection'
            totalCount: number
            edges: Array<{
              __typename: 'ServiceDocumentEdge'
              node: {
                __typename: 'ServiceDocument'
                id: string
                type: ServiceDocumentType
                otherType: string | null
                fileUrl: string
                fileMimeType: string
                fileName: string
                note: string | null
                createdAt: string
                service: { __typename: 'Service'; id: string; title: string }
                createdByApp: {
                  __typename: 'App'
                  id: string
                  name: string
                } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
              }
            }>
          }
        }>
        documents: {
          __typename: 'CaseDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CaseDocumentEdge'
            node: {
              __typename: 'CaseDocument'
              id: string
              type: CaseDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
        events: {
          __typename: 'CaseEventConnection'
          edges: Array<{
            __typename: 'CaseEventEdge'
            node: {
              __typename: 'CaseEvent'
              id: string
              type: CaseEventType
              date: string
              parameters: any
              actionResult: {
                __typename: 'CaseActionResult'
                title: string
              } | null
              actionType: { __typename: 'CaseActionType'; title: string } | null
              createdByApp: { __typename: 'App'; name: string } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
              user: {
                __typename: 'User'
                id: string
                displayName: string
                email: string
              } | null
              service: {
                __typename: 'Service'
                id: string
                title: string
              } | null
            }
          }>
        }
        customer: {
          __typename: 'Customer'
          id: string
          displayName: string
          birthDate: string | null
          gender: Gender | null
          governmentId: string | null
          educationLevel: CustomerEducationLevel | null
          marriageStatus: CustomerMaritalStatus | null
          note: string | null
          defaultPhone: {
            __typename: 'CustomerContactPhone'
            id: string
            phoneNumber: string
          } | null
          defaultEmail: {
            __typename: 'CustomerContactEmail'
            id: string
            emailAddress: string
          } | null
          addresses: Array<{
            __typename: 'CustomerAddress'
            id: string
            city: string
            cityArea: string | null
            note: string | null
            postalCode: string
            streetAddress1: string
            streetAddress2: string | null
            isPermanentResidency: boolean
            isDefault: boolean
            country: {
              __typename: 'Country'
              code: string
              name: string
            } | null
            countryArea: {
              __typename: 'CountryArea'
              id: string
              name: string
            } | null
          }>
          contactPhones: Array<{
            __typename: 'CustomerContactPhone'
            id: string
            phoneNumber: string
            note: string | null
            isDefault: boolean
          }>
          contactEmails: Array<{
            __typename: 'CustomerContactEmail'
            id: string
            emailAddress: string
            note: string | null
            isDefault: boolean
          }>
          documents: {
            __typename: 'CustomerDocumentConnection'
            totalCount: number
            edges: Array<{
              __typename: 'CustomerDocumentEdge'
              node: {
                __typename: 'CustomerDocument'
                id: string
                type: CustomerDocumentType
                otherType: string | null
                fileUrl: string
                fileMimeType: string
                fileName: string
                note: string | null
                createdAt: string
                createdByApp: {
                  __typename: 'App'
                  id: string
                  name: string
                } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
              }
            }>
          }
        }
        address: {
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        } | null
        contactEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        } | null
        contactPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        } | null
        assignedTo: {
          __typename: 'User'
          id: string
          email: string
          displayName: string
        } | null
        totalDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        fee: {
          __typename: 'CaseFee'
          id: string
          paymentStatus: PaymentStatus
          feeRate: number
          createdAt: string
          requestedAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
          remainingAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          payments: Array<{
            __typename: 'CaseFeePayment'
            id: string
            paidAt: string
            paidAmount: {
              __typename: 'Money'
              amount: number
              currency: string
            }
          }>
        } | null
        availableCaseActionResults: Array<{
          __typename: 'CaseActionResult'
          id: string
          title: string
        }>
        availableCaseActionTypes: Array<{
          __typename: 'CaseActionType'
          id: string
          title: string
        }>
        availableCaseCloseReasons: Array<{
          __typename: 'CaseCloseReason'
          id: string
          title: string
        }>
        availableServiceTypes: Array<{
          __typename: 'ServiceType'
          id: string
          title: string
        }>
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }
    }
  }
}

export type ServiceDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ServiceDeleteMutation = {
  __typename: 'Mutation'
  serviceDelete: {
    __typename: 'ServiceDelete'
    service: {
      __typename: 'Service'
      title: string
      case: {
        __typename: 'Case'
        id: string
        createdAt: string
        modifiedAt: string
        type: CaseType
        status: CaseStatus
        customStatus: CaseCustomStatus
        title: string
        defaultFeeRate: number
        closeReason: {
          __typename: 'CaseCloseReason'
          id: string
          title: string
        } | null
        actions: Array<{
          __typename: 'CaseAction'
          id: string
          comment: string
          date: string | null
          createdAt: string
          type: { __typename: 'CaseActionType'; id: string; title: string }
          result: { __typename: 'CaseActionResult'; id: string; title: string }
          createdByUser: {
            __typename: 'User'
            id: string
            displayName: string
          } | null
          createdByApp: { __typename: 'App'; id: string; name: string } | null
        }>
        services: Array<{
          __typename: 'Service'
          id: string
          title: string
          createdAt: string
          dpd: number | null
          status: ServiceStatus
          type: { __typename: 'ServiceType'; id: string; title: string }
          outstandingDebt: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          principalAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          } | null
          serviceProvider: {
            __typename: 'ServiceProvider'
            id: string
            name: string
          } | null
          serviceProviderContact: {
            __typename: 'ServiceProviderContact'
            id: string
            displayName: string
            firstName: string
            middleName: string | null
            lastName: string
            role: string
            phoneNumber: string | null
            phoneNumberNote: string | null
            emailAddress: string | null
            emailAddressNote: string | null
          } | null
          documents: {
            __typename: 'ServiceDocumentConnection'
            totalCount: number
            edges: Array<{
              __typename: 'ServiceDocumentEdge'
              node: {
                __typename: 'ServiceDocument'
                id: string
                type: ServiceDocumentType
                otherType: string | null
                fileUrl: string
                fileMimeType: string
                fileName: string
                note: string | null
                createdAt: string
                service: { __typename: 'Service'; id: string; title: string }
                createdByApp: {
                  __typename: 'App'
                  id: string
                  name: string
                } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
              }
            }>
          }
        }>
        documents: {
          __typename: 'CaseDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CaseDocumentEdge'
            node: {
              __typename: 'CaseDocument'
              id: string
              type: CaseDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
        events: {
          __typename: 'CaseEventConnection'
          edges: Array<{
            __typename: 'CaseEventEdge'
            node: {
              __typename: 'CaseEvent'
              id: string
              type: CaseEventType
              date: string
              parameters: any
              actionResult: {
                __typename: 'CaseActionResult'
                title: string
              } | null
              actionType: { __typename: 'CaseActionType'; title: string } | null
              createdByApp: { __typename: 'App'; name: string } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
              user: {
                __typename: 'User'
                id: string
                displayName: string
                email: string
              } | null
              service: {
                __typename: 'Service'
                id: string
                title: string
              } | null
            }
          }>
        }
        customer: {
          __typename: 'Customer'
          id: string
          displayName: string
          birthDate: string | null
          gender: Gender | null
          governmentId: string | null
          educationLevel: CustomerEducationLevel | null
          marriageStatus: CustomerMaritalStatus | null
          note: string | null
          defaultPhone: {
            __typename: 'CustomerContactPhone'
            id: string
            phoneNumber: string
          } | null
          defaultEmail: {
            __typename: 'CustomerContactEmail'
            id: string
            emailAddress: string
          } | null
          addresses: Array<{
            __typename: 'CustomerAddress'
            id: string
            city: string
            cityArea: string | null
            note: string | null
            postalCode: string
            streetAddress1: string
            streetAddress2: string | null
            isPermanentResidency: boolean
            isDefault: boolean
            country: {
              __typename: 'Country'
              code: string
              name: string
            } | null
            countryArea: {
              __typename: 'CountryArea'
              id: string
              name: string
            } | null
          }>
          contactPhones: Array<{
            __typename: 'CustomerContactPhone'
            id: string
            phoneNumber: string
            note: string | null
            isDefault: boolean
          }>
          contactEmails: Array<{
            __typename: 'CustomerContactEmail'
            id: string
            emailAddress: string
            note: string | null
            isDefault: boolean
          }>
          documents: {
            __typename: 'CustomerDocumentConnection'
            totalCount: number
            edges: Array<{
              __typename: 'CustomerDocumentEdge'
              node: {
                __typename: 'CustomerDocument'
                id: string
                type: CustomerDocumentType
                otherType: string | null
                fileUrl: string
                fileMimeType: string
                fileName: string
                note: string | null
                createdAt: string
                createdByApp: {
                  __typename: 'App'
                  id: string
                  name: string
                } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
              }
            }>
          }
        }
        address: {
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        } | null
        contactEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        } | null
        contactPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        } | null
        assignedTo: {
          __typename: 'User'
          id: string
          email: string
          displayName: string
        } | null
        totalDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        fee: {
          __typename: 'CaseFee'
          id: string
          paymentStatus: PaymentStatus
          feeRate: number
          createdAt: string
          requestedAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
          remainingAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          payments: Array<{
            __typename: 'CaseFeePayment'
            id: string
            paidAt: string
            paidAmount: {
              __typename: 'Money'
              amount: number
              currency: string
            }
          }>
        } | null
        availableCaseActionResults: Array<{
          __typename: 'CaseActionResult'
          id: string
          title: string
        }>
        availableCaseActionTypes: Array<{
          __typename: 'CaseActionType'
          id: string
          title: string
        }>
        availableCaseCloseReasons: Array<{
          __typename: 'CaseCloseReason'
          id: string
          title: string
        }>
        availableServiceTypes: Array<{
          __typename: 'ServiceType'
          id: string
          title: string
        }>
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }
    }
  }
}

export type CaseActionCreateMutationVariables = Exact<{
  caseId: Scalars['ID']['input']
  input: CaseActionCreateInput
}>

export type CaseActionCreateMutation = {
  __typename: 'Mutation'
  caseActionCreate: {
    __typename: 'CaseActionCreate'
    caseAction: {
      __typename: 'CaseAction'
      result: { __typename: 'CaseActionResult'; id: string; title: string }
      type: { __typename: 'CaseActionType'; id: string; title: string }
      case: {
        __typename: 'Case'
        id: string
        createdAt: string
        modifiedAt: string
        type: CaseType
        status: CaseStatus
        customStatus: CaseCustomStatus
        title: string
        defaultFeeRate: number
        closeReason: {
          __typename: 'CaseCloseReason'
          id: string
          title: string
        } | null
        actions: Array<{
          __typename: 'CaseAction'
          id: string
          comment: string
          date: string | null
          createdAt: string
          type: { __typename: 'CaseActionType'; id: string; title: string }
          result: { __typename: 'CaseActionResult'; id: string; title: string }
          createdByUser: {
            __typename: 'User'
            id: string
            displayName: string
          } | null
          createdByApp: { __typename: 'App'; id: string; name: string } | null
        }>
        services: Array<{
          __typename: 'Service'
          id: string
          title: string
          createdAt: string
          dpd: number | null
          status: ServiceStatus
          type: { __typename: 'ServiceType'; id: string; title: string }
          outstandingDebt: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          principalAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          } | null
          serviceProvider: {
            __typename: 'ServiceProvider'
            id: string
            name: string
          } | null
          serviceProviderContact: {
            __typename: 'ServiceProviderContact'
            id: string
            displayName: string
            firstName: string
            middleName: string | null
            lastName: string
            role: string
            phoneNumber: string | null
            phoneNumberNote: string | null
            emailAddress: string | null
            emailAddressNote: string | null
          } | null
          documents: {
            __typename: 'ServiceDocumentConnection'
            totalCount: number
            edges: Array<{
              __typename: 'ServiceDocumentEdge'
              node: {
                __typename: 'ServiceDocument'
                id: string
                type: ServiceDocumentType
                otherType: string | null
                fileUrl: string
                fileMimeType: string
                fileName: string
                note: string | null
                createdAt: string
                service: { __typename: 'Service'; id: string; title: string }
                createdByApp: {
                  __typename: 'App'
                  id: string
                  name: string
                } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
              }
            }>
          }
        }>
        documents: {
          __typename: 'CaseDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CaseDocumentEdge'
            node: {
              __typename: 'CaseDocument'
              id: string
              type: CaseDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
        events: {
          __typename: 'CaseEventConnection'
          edges: Array<{
            __typename: 'CaseEventEdge'
            node: {
              __typename: 'CaseEvent'
              id: string
              type: CaseEventType
              date: string
              parameters: any
              actionResult: {
                __typename: 'CaseActionResult'
                title: string
              } | null
              actionType: { __typename: 'CaseActionType'; title: string } | null
              createdByApp: { __typename: 'App'; name: string } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
              user: {
                __typename: 'User'
                id: string
                displayName: string
                email: string
              } | null
              service: {
                __typename: 'Service'
                id: string
                title: string
              } | null
            }
          }>
        }
        customer: {
          __typename: 'Customer'
          id: string
          displayName: string
          birthDate: string | null
          gender: Gender | null
          governmentId: string | null
          educationLevel: CustomerEducationLevel | null
          marriageStatus: CustomerMaritalStatus | null
          note: string | null
          defaultPhone: {
            __typename: 'CustomerContactPhone'
            id: string
            phoneNumber: string
          } | null
          defaultEmail: {
            __typename: 'CustomerContactEmail'
            id: string
            emailAddress: string
          } | null
          addresses: Array<{
            __typename: 'CustomerAddress'
            id: string
            city: string
            cityArea: string | null
            note: string | null
            postalCode: string
            streetAddress1: string
            streetAddress2: string | null
            isPermanentResidency: boolean
            isDefault: boolean
            country: {
              __typename: 'Country'
              code: string
              name: string
            } | null
            countryArea: {
              __typename: 'CountryArea'
              id: string
              name: string
            } | null
          }>
          contactPhones: Array<{
            __typename: 'CustomerContactPhone'
            id: string
            phoneNumber: string
            note: string | null
            isDefault: boolean
          }>
          contactEmails: Array<{
            __typename: 'CustomerContactEmail'
            id: string
            emailAddress: string
            note: string | null
            isDefault: boolean
          }>
          documents: {
            __typename: 'CustomerDocumentConnection'
            totalCount: number
            edges: Array<{
              __typename: 'CustomerDocumentEdge'
              node: {
                __typename: 'CustomerDocument'
                id: string
                type: CustomerDocumentType
                otherType: string | null
                fileUrl: string
                fileMimeType: string
                fileName: string
                note: string | null
                createdAt: string
                createdByApp: {
                  __typename: 'App'
                  id: string
                  name: string
                } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
              }
            }>
          }
        }
        address: {
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        } | null
        contactEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        } | null
        contactPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        } | null
        assignedTo: {
          __typename: 'User'
          id: string
          email: string
          displayName: string
        } | null
        totalDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        fee: {
          __typename: 'CaseFee'
          id: string
          paymentStatus: PaymentStatus
          feeRate: number
          createdAt: string
          requestedAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
          remainingAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          payments: Array<{
            __typename: 'CaseFeePayment'
            id: string
            paidAt: string
            paidAmount: {
              __typename: 'Money'
              amount: number
              currency: string
            }
          }>
        } | null
        availableCaseActionResults: Array<{
          __typename: 'CaseActionResult'
          id: string
          title: string
        }>
        availableCaseActionTypes: Array<{
          __typename: 'CaseActionType'
          id: string
          title: string
        }>
        availableCaseCloseReasons: Array<{
          __typename: 'CaseCloseReason'
          id: string
          title: string
        }>
        availableServiceTypes: Array<{
          __typename: 'ServiceType'
          id: string
          title: string
        }>
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }
    }
  }
}

export type CaseActionUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseActionUpdateInput
}>

export type CaseActionUpdateMutation = {
  __typename: 'Mutation'
  caseActionUpdate: {
    __typename: 'CaseActionUpdate'
    caseAction: {
      __typename: 'CaseAction'
      result: { __typename: 'CaseActionResult'; id: string; title: string }
      type: { __typename: 'CaseActionType'; id: string; title: string }
      case: {
        __typename: 'Case'
        id: string
        createdAt: string
        modifiedAt: string
        type: CaseType
        status: CaseStatus
        customStatus: CaseCustomStatus
        title: string
        defaultFeeRate: number
        closeReason: {
          __typename: 'CaseCloseReason'
          id: string
          title: string
        } | null
        actions: Array<{
          __typename: 'CaseAction'
          id: string
          comment: string
          date: string | null
          createdAt: string
          type: { __typename: 'CaseActionType'; id: string; title: string }
          result: { __typename: 'CaseActionResult'; id: string; title: string }
          createdByUser: {
            __typename: 'User'
            id: string
            displayName: string
          } | null
          createdByApp: { __typename: 'App'; id: string; name: string } | null
        }>
        services: Array<{
          __typename: 'Service'
          id: string
          title: string
          createdAt: string
          dpd: number | null
          status: ServiceStatus
          type: { __typename: 'ServiceType'; id: string; title: string }
          outstandingDebt: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          principalAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          } | null
          serviceProvider: {
            __typename: 'ServiceProvider'
            id: string
            name: string
          } | null
          serviceProviderContact: {
            __typename: 'ServiceProviderContact'
            id: string
            displayName: string
            firstName: string
            middleName: string | null
            lastName: string
            role: string
            phoneNumber: string | null
            phoneNumberNote: string | null
            emailAddress: string | null
            emailAddressNote: string | null
          } | null
          documents: {
            __typename: 'ServiceDocumentConnection'
            totalCount: number
            edges: Array<{
              __typename: 'ServiceDocumentEdge'
              node: {
                __typename: 'ServiceDocument'
                id: string
                type: ServiceDocumentType
                otherType: string | null
                fileUrl: string
                fileMimeType: string
                fileName: string
                note: string | null
                createdAt: string
                service: { __typename: 'Service'; id: string; title: string }
                createdByApp: {
                  __typename: 'App'
                  id: string
                  name: string
                } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
              }
            }>
          }
        }>
        documents: {
          __typename: 'CaseDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CaseDocumentEdge'
            node: {
              __typename: 'CaseDocument'
              id: string
              type: CaseDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
        events: {
          __typename: 'CaseEventConnection'
          edges: Array<{
            __typename: 'CaseEventEdge'
            node: {
              __typename: 'CaseEvent'
              id: string
              type: CaseEventType
              date: string
              parameters: any
              actionResult: {
                __typename: 'CaseActionResult'
                title: string
              } | null
              actionType: { __typename: 'CaseActionType'; title: string } | null
              createdByApp: { __typename: 'App'; name: string } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
              user: {
                __typename: 'User'
                id: string
                displayName: string
                email: string
              } | null
              service: {
                __typename: 'Service'
                id: string
                title: string
              } | null
            }
          }>
        }
        customer: {
          __typename: 'Customer'
          id: string
          displayName: string
          birthDate: string | null
          gender: Gender | null
          governmentId: string | null
          educationLevel: CustomerEducationLevel | null
          marriageStatus: CustomerMaritalStatus | null
          note: string | null
          defaultPhone: {
            __typename: 'CustomerContactPhone'
            id: string
            phoneNumber: string
          } | null
          defaultEmail: {
            __typename: 'CustomerContactEmail'
            id: string
            emailAddress: string
          } | null
          addresses: Array<{
            __typename: 'CustomerAddress'
            id: string
            city: string
            cityArea: string | null
            note: string | null
            postalCode: string
            streetAddress1: string
            streetAddress2: string | null
            isPermanentResidency: boolean
            isDefault: boolean
            country: {
              __typename: 'Country'
              code: string
              name: string
            } | null
            countryArea: {
              __typename: 'CountryArea'
              id: string
              name: string
            } | null
          }>
          contactPhones: Array<{
            __typename: 'CustomerContactPhone'
            id: string
            phoneNumber: string
            note: string | null
            isDefault: boolean
          }>
          contactEmails: Array<{
            __typename: 'CustomerContactEmail'
            id: string
            emailAddress: string
            note: string | null
            isDefault: boolean
          }>
          documents: {
            __typename: 'CustomerDocumentConnection'
            totalCount: number
            edges: Array<{
              __typename: 'CustomerDocumentEdge'
              node: {
                __typename: 'CustomerDocument'
                id: string
                type: CustomerDocumentType
                otherType: string | null
                fileUrl: string
                fileMimeType: string
                fileName: string
                note: string | null
                createdAt: string
                createdByApp: {
                  __typename: 'App'
                  id: string
                  name: string
                } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
              }
            }>
          }
        }
        address: {
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        } | null
        contactEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        } | null
        contactPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        } | null
        assignedTo: {
          __typename: 'User'
          id: string
          email: string
          displayName: string
        } | null
        totalDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        fee: {
          __typename: 'CaseFee'
          id: string
          paymentStatus: PaymentStatus
          feeRate: number
          createdAt: string
          requestedAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
          remainingAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          payments: Array<{
            __typename: 'CaseFeePayment'
            id: string
            paidAt: string
            paidAmount: {
              __typename: 'Money'
              amount: number
              currency: string
            }
          }>
        } | null
        availableCaseActionResults: Array<{
          __typename: 'CaseActionResult'
          id: string
          title: string
        }>
        availableCaseActionTypes: Array<{
          __typename: 'CaseActionType'
          id: string
          title: string
        }>
        availableCaseCloseReasons: Array<{
          __typename: 'CaseCloseReason'
          id: string
          title: string
        }>
        availableServiceTypes: Array<{
          __typename: 'ServiceType'
          id: string
          title: string
        }>
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }
    }
  }
}

export type CaseActionDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CaseActionDeleteMutation = {
  __typename: 'Mutation'
  caseActionDelete: {
    __typename: 'CaseActionDelete'
    caseAction: {
      __typename: 'CaseAction'
      result: { __typename: 'CaseActionResult'; id: string; title: string }
      type: { __typename: 'CaseActionType'; id: string; title: string }
      case: {
        __typename: 'Case'
        id: string
        createdAt: string
        modifiedAt: string
        type: CaseType
        status: CaseStatus
        customStatus: CaseCustomStatus
        title: string
        defaultFeeRate: number
        closeReason: {
          __typename: 'CaseCloseReason'
          id: string
          title: string
        } | null
        actions: Array<{
          __typename: 'CaseAction'
          id: string
          comment: string
          date: string | null
          createdAt: string
          type: { __typename: 'CaseActionType'; id: string; title: string }
          result: { __typename: 'CaseActionResult'; id: string; title: string }
          createdByUser: {
            __typename: 'User'
            id: string
            displayName: string
          } | null
          createdByApp: { __typename: 'App'; id: string; name: string } | null
        }>
        services: Array<{
          __typename: 'Service'
          id: string
          title: string
          createdAt: string
          dpd: number | null
          status: ServiceStatus
          type: { __typename: 'ServiceType'; id: string; title: string }
          outstandingDebt: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          principalAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          } | null
          serviceProvider: {
            __typename: 'ServiceProvider'
            id: string
            name: string
          } | null
          serviceProviderContact: {
            __typename: 'ServiceProviderContact'
            id: string
            displayName: string
            firstName: string
            middleName: string | null
            lastName: string
            role: string
            phoneNumber: string | null
            phoneNumberNote: string | null
            emailAddress: string | null
            emailAddressNote: string | null
          } | null
          documents: {
            __typename: 'ServiceDocumentConnection'
            totalCount: number
            edges: Array<{
              __typename: 'ServiceDocumentEdge'
              node: {
                __typename: 'ServiceDocument'
                id: string
                type: ServiceDocumentType
                otherType: string | null
                fileUrl: string
                fileMimeType: string
                fileName: string
                note: string | null
                createdAt: string
                service: { __typename: 'Service'; id: string; title: string }
                createdByApp: {
                  __typename: 'App'
                  id: string
                  name: string
                } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
              }
            }>
          }
        }>
        documents: {
          __typename: 'CaseDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CaseDocumentEdge'
            node: {
              __typename: 'CaseDocument'
              id: string
              type: CaseDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
        events: {
          __typename: 'CaseEventConnection'
          edges: Array<{
            __typename: 'CaseEventEdge'
            node: {
              __typename: 'CaseEvent'
              id: string
              type: CaseEventType
              date: string
              parameters: any
              actionResult: {
                __typename: 'CaseActionResult'
                title: string
              } | null
              actionType: { __typename: 'CaseActionType'; title: string } | null
              createdByApp: { __typename: 'App'; name: string } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
              user: {
                __typename: 'User'
                id: string
                displayName: string
                email: string
              } | null
              service: {
                __typename: 'Service'
                id: string
                title: string
              } | null
            }
          }>
        }
        customer: {
          __typename: 'Customer'
          id: string
          displayName: string
          birthDate: string | null
          gender: Gender | null
          governmentId: string | null
          educationLevel: CustomerEducationLevel | null
          marriageStatus: CustomerMaritalStatus | null
          note: string | null
          defaultPhone: {
            __typename: 'CustomerContactPhone'
            id: string
            phoneNumber: string
          } | null
          defaultEmail: {
            __typename: 'CustomerContactEmail'
            id: string
            emailAddress: string
          } | null
          addresses: Array<{
            __typename: 'CustomerAddress'
            id: string
            city: string
            cityArea: string | null
            note: string | null
            postalCode: string
            streetAddress1: string
            streetAddress2: string | null
            isPermanentResidency: boolean
            isDefault: boolean
            country: {
              __typename: 'Country'
              code: string
              name: string
            } | null
            countryArea: {
              __typename: 'CountryArea'
              id: string
              name: string
            } | null
          }>
          contactPhones: Array<{
            __typename: 'CustomerContactPhone'
            id: string
            phoneNumber: string
            note: string | null
            isDefault: boolean
          }>
          contactEmails: Array<{
            __typename: 'CustomerContactEmail'
            id: string
            emailAddress: string
            note: string | null
            isDefault: boolean
          }>
          documents: {
            __typename: 'CustomerDocumentConnection'
            totalCount: number
            edges: Array<{
              __typename: 'CustomerDocumentEdge'
              node: {
                __typename: 'CustomerDocument'
                id: string
                type: CustomerDocumentType
                otherType: string | null
                fileUrl: string
                fileMimeType: string
                fileName: string
                note: string | null
                createdAt: string
                createdByApp: {
                  __typename: 'App'
                  id: string
                  name: string
                } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
              }
            }>
          }
        }
        address: {
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        } | null
        contactEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        } | null
        contactPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        } | null
        assignedTo: {
          __typename: 'User'
          id: string
          email: string
          displayName: string
        } | null
        totalDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        fee: {
          __typename: 'CaseFee'
          id: string
          paymentStatus: PaymentStatus
          feeRate: number
          createdAt: string
          requestedAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
          remainingAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          payments: Array<{
            __typename: 'CaseFeePayment'
            id: string
            paidAt: string
            paidAmount: {
              __typename: 'Money'
              amount: number
              currency: string
            }
          }>
        } | null
        availableCaseActionResults: Array<{
          __typename: 'CaseActionResult'
          id: string
          title: string
        }>
        availableCaseActionTypes: Array<{
          __typename: 'CaseActionType'
          id: string
          title: string
        }>
        availableCaseCloseReasons: Array<{
          __typename: 'CaseCloseReason'
          id: string
          title: string
        }>
        availableServiceTypes: Array<{
          __typename: 'ServiceType'
          id: string
          title: string
        }>
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }
    }
  }
}

export type CaseCloseMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseCloseInput
}>

export type CaseCloseMutation = {
  __typename: 'Mutation'
  caseClose: {
    __typename: 'CaseClose'
    case: {
      __typename: 'Case'
      id: string
      createdAt: string
      modifiedAt: string
      type: CaseType
      status: CaseStatus
      customStatus: CaseCustomStatus
      title: string
      defaultFeeRate: number
      closeReason: {
        __typename: 'CaseCloseReason'
        id: string
        title: string
      } | null
      actions: Array<{
        __typename: 'CaseAction'
        id: string
        comment: string
        date: string | null
        createdAt: string
        type: { __typename: 'CaseActionType'; id: string; title: string }
        result: { __typename: 'CaseActionResult'; id: string; title: string }
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        createdByApp: { __typename: 'App'; id: string; name: string } | null
      }>
      services: Array<{
        __typename: 'Service'
        id: string
        title: string
        createdAt: string
        dpd: number | null
        status: ServiceStatus
        type: { __typename: 'ServiceType'; id: string; title: string }
        outstandingDebt: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        principalAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        } | null
        serviceProvider: {
          __typename: 'ServiceProvider'
          id: string
          name: string
        } | null
        serviceProviderContact: {
          __typename: 'ServiceProviderContact'
          id: string
          displayName: string
          firstName: string
          middleName: string | null
          lastName: string
          role: string
          phoneNumber: string | null
          phoneNumberNote: string | null
          emailAddress: string | null
          emailAddressNote: string | null
        } | null
        documents: {
          __typename: 'ServiceDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'ServiceDocumentEdge'
            node: {
              __typename: 'ServiceDocument'
              id: string
              type: ServiceDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              service: { __typename: 'Service'; id: string; title: string }
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }>
      documents: {
        __typename: 'CaseDocumentConnection'
        totalCount: number
        edges: Array<{
          __typename: 'CaseDocumentEdge'
          node: {
            __typename: 'CaseDocument'
            id: string
            type: CaseDocumentType
            otherType: string | null
            fileUrl: string
            fileMimeType: string
            fileName: string
            note: string | null
            createdAt: string
            createdByApp: { __typename: 'App'; id: string; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
          }
        }>
      }
      events: {
        __typename: 'CaseEventConnection'
        edges: Array<{
          __typename: 'CaseEventEdge'
          node: {
            __typename: 'CaseEvent'
            id: string
            type: CaseEventType
            date: string
            parameters: any
            actionResult: {
              __typename: 'CaseActionResult'
              title: string
            } | null
            actionType: { __typename: 'CaseActionType'; title: string } | null
            createdByApp: { __typename: 'App'; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
            user: {
              __typename: 'User'
              id: string
              displayName: string
              email: string
            } | null
            service: { __typename: 'Service'; id: string; title: string } | null
          }
        }>
      }
      customer: {
        __typename: 'Customer'
        id: string
        displayName: string
        birthDate: string | null
        gender: Gender | null
        governmentId: string | null
        educationLevel: CustomerEducationLevel | null
        marriageStatus: CustomerMaritalStatus | null
        note: string | null
        defaultPhone: {
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
        } | null
        defaultEmail: {
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
        } | null
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        }>
        contactPhones: Array<{
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
        }>
        contactEmails: Array<{
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
        }>
        documents: {
          __typename: 'CustomerDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CustomerDocumentEdge'
            node: {
              __typename: 'CustomerDocument'
              id: string
              type: CustomerDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }
      address: {
        __typename: 'CustomerAddress'
        id: string
        city: string
        cityArea: string | null
        note: string | null
        postalCode: string
        streetAddress1: string
        streetAddress2: string | null
        isPermanentResidency: boolean
        isDefault: boolean
        country: { __typename: 'Country'; code: string; name: string } | null
        countryArea: {
          __typename: 'CountryArea'
          id: string
          name: string
        } | null
      } | null
      contactEmail: {
        __typename: 'CustomerContactEmail'
        id: string
        emailAddress: string
        note: string | null
        isDefault: boolean
      } | null
      contactPhone: {
        __typename: 'CustomerContactPhone'
        id: string
        phoneNumber: string
        note: string | null
        isDefault: boolean
      } | null
      assignedTo: {
        __typename: 'User'
        id: string
        email: string
        displayName: string
      } | null
      totalDebt: {
        __typename: 'Money'
        amount: number
        currency: string
      } | null
      fee: {
        __typename: 'CaseFee'
        id: string
        paymentStatus: PaymentStatus
        feeRate: number
        createdAt: string
        requestedAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        paidAmount: { __typename: 'Money'; amount: number; currency: string }
        remainingAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        payments: Array<{
          __typename: 'CaseFeePayment'
          id: string
          paidAt: string
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
        }>
      } | null
      availableCaseActionResults: Array<{
        __typename: 'CaseActionResult'
        id: string
        title: string
      }>
      availableCaseActionTypes: Array<{
        __typename: 'CaseActionType'
        id: string
        title: string
      }>
      availableCaseCloseReasons: Array<{
        __typename: 'CaseCloseReason'
        id: string
        title: string
      }>
      availableServiceTypes: Array<{
        __typename: 'ServiceType'
        id: string
        title: string
      }>
      createdByUser: {
        __typename: 'User'
        id: string
        displayName: string
      } | null
      createdByApp: { __typename: 'App'; id: string; name: string } | null
    }
  }
}

export type CaseCustomStatusUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseCustomStatusUpdateInput
}>

export type CaseCustomStatusUpdateMutation = {
  __typename: 'Mutation'
  caseCustomStatusUpdate: {
    __typename: 'CaseCustomStatusUpdate'
    case: {
      __typename: 'Case'
      id: string
      customStatus: CaseCustomStatus
      events: {
        __typename: 'CaseEventConnection'
        edges: Array<{
          __typename: 'CaseEventEdge'
          node: {
            __typename: 'CaseEvent'
            id: string
            type: CaseEventType
            date: string
            parameters: any
            actionResult: {
              __typename: 'CaseActionResult'
              title: string
            } | null
            actionType: { __typename: 'CaseActionType'; title: string } | null
            createdByApp: { __typename: 'App'; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
            user: {
              __typename: 'User'
              id: string
              displayName: string
              email: string
            } | null
            service: { __typename: 'Service'; id: string; title: string } | null
          }
        }>
      }
    }
  }
}

export type CaseFeeCreateMutationVariables = Exact<{
  caseId: Scalars['ID']['input']
  input: CaseFeeCreateInput
}>

export type CaseFeeCreateMutation = {
  __typename: 'Mutation'
  caseFeeCreate: {
    __typename: 'CaseFeeCreate'
    caseFee: {
      __typename: 'CaseFee'
      case: {
        __typename: 'Case'
        id: string
        fee: {
          __typename: 'CaseFee'
          id: string
          paymentStatus: PaymentStatus
          feeRate: number
          createdAt: string
          requestedAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
          remainingAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          payments: Array<{
            __typename: 'CaseFeePayment'
            id: string
            paidAt: string
            paidAmount: {
              __typename: 'Money'
              amount: number
              currency: string
            }
          }>
        } | null
      }
    }
  }
}

export type CaseFeeUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseFeeUpdateInput
}>

export type CaseFeeUpdateMutation = {
  __typename: 'Mutation'
  caseFeeUpdate: {
    __typename: 'CaseFeeUpdate'
    caseFee: {
      __typename: 'CaseFee'
      case: {
        __typename: 'Case'
        id: string
        fee: {
          __typename: 'CaseFee'
          id: string
          paymentStatus: PaymentStatus
          feeRate: number
          createdAt: string
          requestedAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
          remainingAmount: {
            __typename: 'Money'
            amount: number
            currency: string
          }
          payments: Array<{
            __typename: 'CaseFeePayment'
            id: string
            paidAt: string
            paidAmount: {
              __typename: 'Money'
              amount: number
              currency: string
            }
          }>
        } | null
      }
    }
  }
}

export type CaseFeePaymentAddMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseFeePaymentCreateInput
}>

export type CaseFeePaymentAddMutation = {
  __typename: 'Mutation'
  caseFeePaymentCreate: {
    __typename: 'CaseFeePaymentCreate'
    caseFeePayment: {
      __typename: 'CaseFeePayment'
      fee: {
        __typename: 'CaseFee'
        id: string
        paymentStatus: PaymentStatus
        feeRate: number
        createdAt: string
        requestedAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        paidAmount: { __typename: 'Money'; amount: number; currency: string }
        remainingAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        payments: Array<{
          __typename: 'CaseFeePayment'
          id: string
          paidAt: string
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
        }>
      }
    }
  }
}

export type CaseFeePaymentUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseFeePaymentUpdateInput
}>

export type CaseFeePaymentUpdateMutation = {
  __typename: 'Mutation'
  caseFeePaymentUpdate: {
    __typename: 'CaseFeePaymentUpdate'
    caseFeePayment: {
      __typename: 'CaseFeePayment'
      fee: {
        __typename: 'CaseFee'
        id: string
        paymentStatus: PaymentStatus
        feeRate: number
        createdAt: string
        requestedAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        paidAmount: { __typename: 'Money'; amount: number; currency: string }
        remainingAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        payments: Array<{
          __typename: 'CaseFeePayment'
          id: string
          paidAt: string
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
        }>
      }
    }
  }
}

export type CaseFeePaymentDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CaseFeePaymentDeleteMutation = {
  __typename: 'Mutation'
  caseFeePaymentDelete: {
    __typename: 'CaseFeePaymentDelete'
    caseFeePayment: {
      __typename: 'CaseFeePayment'
      fee: {
        __typename: 'CaseFee'
        id: string
        paymentStatus: PaymentStatus
        feeRate: number
        createdAt: string
        requestedAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        paidAmount: { __typename: 'Money'; amount: number; currency: string }
        remainingAmount: {
          __typename: 'Money'
          amount: number
          currency: string
        }
        payments: Array<{
          __typename: 'CaseFeePayment'
          id: string
          paidAt: string
          paidAmount: { __typename: 'Money'; amount: number; currency: string }
        }>
      }
    }
  }
}

export type CaseDocumentCreateMutationVariables = Exact<{
  caseId: Scalars['ID']['input']
  input: CaseDocumentCreateInput
}>

export type CaseDocumentCreateMutation = {
  __typename: 'Mutation'
  caseDocumentCreate: {
    __typename: 'CaseDocumentCreate'
    caseDocument: {
      __typename: 'CaseDocument'
      id: string
      fileName: string
      case: {
        __typename: 'Case'
        id: string
        documents: {
          __typename: 'CaseDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CaseDocumentEdge'
            node: {
              __typename: 'CaseDocument'
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
        events: {
          __typename: 'CaseEventConnection'
          edges: Array<{
            __typename: 'CaseEventEdge'
            node: {
              __typename: 'CaseEvent'
              id: string
              type: CaseEventType
              date: string
              parameters: any
              actionResult: {
                __typename: 'CaseActionResult'
                title: string
              } | null
              actionType: { __typename: 'CaseActionType'; title: string } | null
              createdByApp: { __typename: 'App'; name: string } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
              user: {
                __typename: 'User'
                id: string
                displayName: string
                email: string
              } | null
              service: {
                __typename: 'Service'
                id: string
                title: string
              } | null
            }
          }>
        }
      }
    }
  }
}

export type CaseDocumentUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CaseDocumentUpdateInput
}>

export type CaseDocumentUpdateMutation = {
  __typename: 'Mutation'
  caseDocumentUpdate: {
    __typename: 'CaseDocumentUpdate'
    caseDocument: {
      __typename: 'CaseDocument'
      id: string
      fileName: string
      case: {
        __typename: 'Case'
        id: string
        documents: {
          __typename: 'CaseDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CaseDocumentEdge'
            node: {
              __typename: 'CaseDocument'
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
        events: {
          __typename: 'CaseEventConnection'
          edges: Array<{
            __typename: 'CaseEventEdge'
            node: {
              __typename: 'CaseEvent'
              id: string
              type: CaseEventType
              date: string
              parameters: any
              actionResult: {
                __typename: 'CaseActionResult'
                title: string
              } | null
              actionType: { __typename: 'CaseActionType'; title: string } | null
              createdByApp: { __typename: 'App'; name: string } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
              user: {
                __typename: 'User'
                id: string
                displayName: string
                email: string
              } | null
              service: {
                __typename: 'Service'
                id: string
                title: string
              } | null
            }
          }>
        }
      }
    }
  }
}

export type CaseDocumentDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CaseDocumentDeleteMutation = {
  __typename: 'Mutation'
  caseDocumentDelete: {
    __typename: 'CaseDocumentDelete'
    caseDocument: {
      __typename: 'CaseDocument'
      id: string
      fileName: string
      case: {
        __typename: 'Case'
        id: string
        documents: {
          __typename: 'CaseDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CaseDocumentEdge'
            node: {
              __typename: 'CaseDocument'
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
        events: {
          __typename: 'CaseEventConnection'
          edges: Array<{
            __typename: 'CaseEventEdge'
            node: {
              __typename: 'CaseEvent'
              id: string
              type: CaseEventType
              date: string
              parameters: any
              actionResult: {
                __typename: 'CaseActionResult'
                title: string
              } | null
              actionType: { __typename: 'CaseActionType'; title: string } | null
              createdByApp: { __typename: 'App'; name: string } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
              user: {
                __typename: 'User'
                id: string
                displayName: string
                email: string
              } | null
              service: {
                __typename: 'Service'
                id: string
                title: string
              } | null
            }
          }>
        }
      }
    }
  }
}

export type ServiceDocumentCreateMutationVariables = Exact<{
  serviceId: Scalars['ID']['input']
  input: ServiceDocumentCreateInput
}>

export type ServiceDocumentCreateMutation = {
  __typename: 'Mutation'
  serviceDocumentCreate: {
    __typename: 'ServiceDocumentCreate'
    serviceDocument: {
      __typename: 'ServiceDocument'
      id: string
      fileName: string
      service: {
        __typename: 'Service'
        id: string
        documents: {
          __typename: 'ServiceDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'ServiceDocumentEdge'
            node: {
              __typename: 'ServiceDocument'
              id: string
              type: ServiceDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              service: { __typename: 'Service'; id: string; title: string }
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
        case: {
          __typename: 'Case'
          id: string
          events: {
            __typename: 'CaseEventConnection'
            edges: Array<{
              __typename: 'CaseEventEdge'
              node: {
                __typename: 'CaseEvent'
                id: string
                type: CaseEventType
                date: string
                parameters: any
                actionResult: {
                  __typename: 'CaseActionResult'
                  title: string
                } | null
                actionType: {
                  __typename: 'CaseActionType'
                  title: string
                } | null
                createdByApp: { __typename: 'App'; name: string } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
                user: {
                  __typename: 'User'
                  id: string
                  displayName: string
                  email: string
                } | null
                service: {
                  __typename: 'Service'
                  id: string
                  title: string
                } | null
              }
            }>
          }
        }
      }
    }
  }
}

export type ServiceDocumentUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: ServiceDocumentUpdateInput
}>

export type ServiceDocumentUpdateMutation = {
  __typename: 'Mutation'
  serviceDocumentUpdate: {
    __typename: 'ServiceDocumentUpdate'
    serviceDocument: {
      __typename: 'ServiceDocument'
      id: string
      fileName: string
      service: {
        __typename: 'Service'
        id: string
        documents: {
          __typename: 'ServiceDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'ServiceDocumentEdge'
            node: {
              __typename: 'ServiceDocument'
              id: string
              type: ServiceDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              service: { __typename: 'Service'; id: string; title: string }
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
        case: {
          __typename: 'Case'
          id: string
          events: {
            __typename: 'CaseEventConnection'
            edges: Array<{
              __typename: 'CaseEventEdge'
              node: {
                __typename: 'CaseEvent'
                id: string
                type: CaseEventType
                date: string
                parameters: any
                actionResult: {
                  __typename: 'CaseActionResult'
                  title: string
                } | null
                actionType: {
                  __typename: 'CaseActionType'
                  title: string
                } | null
                createdByApp: { __typename: 'App'; name: string } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
                user: {
                  __typename: 'User'
                  id: string
                  displayName: string
                  email: string
                } | null
                service: {
                  __typename: 'Service'
                  id: string
                  title: string
                } | null
              }
            }>
          }
        }
      }
    }
  }
}

export type ServiceDocumentDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ServiceDocumentDeleteMutation = {
  __typename: 'Mutation'
  serviceDocumentDelete: {
    __typename: 'ServiceDocumentDelete'
    serviceDocument: {
      __typename: 'ServiceDocument'
      id: string
      fileName: string
      service: {
        __typename: 'Service'
        id: string
        documents: {
          __typename: 'ServiceDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'ServiceDocumentEdge'
            node: {
              __typename: 'ServiceDocument'
              id: string
              type: ServiceDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              service: { __typename: 'Service'; id: string; title: string }
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
        case: {
          __typename: 'Case'
          id: string
          events: {
            __typename: 'CaseEventConnection'
            edges: Array<{
              __typename: 'CaseEventEdge'
              node: {
                __typename: 'CaseEvent'
                id: string
                type: CaseEventType
                date: string
                parameters: any
                actionResult: {
                  __typename: 'CaseActionResult'
                  title: string
                } | null
                actionType: {
                  __typename: 'CaseActionType'
                  title: string
                } | null
                createdByApp: { __typename: 'App'; name: string } | null
                createdByUser: {
                  __typename: 'User'
                  id: string
                  displayName: string
                } | null
                user: {
                  __typename: 'User'
                  id: string
                  displayName: string
                  email: string
                } | null
                service: {
                  __typename: 'Service'
                  id: string
                  title: string
                } | null
              }
            }>
          }
        }
      }
    }
  }
}

export type CaseListFragment = {
  __typename: 'Case'
  id: string
  createdAt: string
  title: string
  customStatus: CaseCustomStatus
  status: CaseStatus
  closeReason: {
    __typename: 'CaseCloseReason'
    id: string
    title: string
  } | null
  fee: { __typename: 'CaseFee'; paymentStatus: PaymentStatus } | null
  customer: { __typename: 'Customer'; displayName: string }
  assignedTo: { __typename: 'User'; displayName: string } | null
}

export type CasesAllQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<CasesAllFilterInput>
  sort?: InputMaybe<CasesAllSortInput>
}>

export type CasesAllQuery = {
  __typename: 'Query'
  cases: {
    __typename: 'CaseConnection'
    totalCount: number
    edges: Array<{
      __typename: 'CaseEdge'
      node: {
        __typename: 'Case'
        id: string
        createdAt: string
        title: string
        customStatus: CaseCustomStatus
        status: CaseStatus
        closeReason: {
          __typename: 'CaseCloseReason'
          id: string
          title: string
        } | null
        fee: { __typename: 'CaseFee'; paymentStatus: PaymentStatus } | null
        customer: { __typename: 'Customer'; displayName: string }
        assignedTo: { __typename: 'User'; displayName: string } | null
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      startCursor: string | null
      endCursor: string | null
    }
  }
}

export type CasesAssignedQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<CasesAssignedFilterInput>
  sort?: InputMaybe<CasesAssignedSortInput>
}>

export type CasesAssignedQuery = {
  __typename: 'Query'
  cases: {
    __typename: 'CaseConnection'
    totalCount: number
    edges: Array<{
      __typename: 'CaseEdge'
      node: {
        __typename: 'Case'
        id: string
        createdAt: string
        title: string
        customStatus: CaseCustomStatus
        status: CaseStatus
        closeReason: {
          __typename: 'CaseCloseReason'
          id: string
          title: string
        } | null
        fee: { __typename: 'CaseFee'; paymentStatus: PaymentStatus } | null
        customer: { __typename: 'Customer'; displayName: string }
        assignedTo: { __typename: 'User'; displayName: string } | null
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      startCursor: string | null
      endCursor: string | null
    }
  }
}

export type CaseDetailQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CaseDetailQuery = {
  __typename: 'Query'
  case: {
    __typename: 'Case'
    id: string
    createdAt: string
    modifiedAt: string
    type: CaseType
    status: CaseStatus
    customStatus: CaseCustomStatus
    title: string
    defaultFeeRate: number
    closeReason: {
      __typename: 'CaseCloseReason'
      id: string
      title: string
    } | null
    actions: Array<{
      __typename: 'CaseAction'
      id: string
      comment: string
      date: string | null
      createdAt: string
      type: { __typename: 'CaseActionType'; id: string; title: string }
      result: { __typename: 'CaseActionResult'; id: string; title: string }
      createdByUser: {
        __typename: 'User'
        id: string
        displayName: string
      } | null
      createdByApp: { __typename: 'App'; id: string; name: string } | null
    }>
    services: Array<{
      __typename: 'Service'
      id: string
      title: string
      createdAt: string
      dpd: number | null
      status: ServiceStatus
      type: { __typename: 'ServiceType'; id: string; title: string }
      outstandingDebt: { __typename: 'Money'; amount: number; currency: string }
      principalAmount: {
        __typename: 'Money'
        amount: number
        currency: string
      } | null
      serviceProvider: {
        __typename: 'ServiceProvider'
        id: string
        name: string
      } | null
      serviceProviderContact: {
        __typename: 'ServiceProviderContact'
        id: string
        displayName: string
        firstName: string
        middleName: string | null
        lastName: string
        role: string
        phoneNumber: string | null
        phoneNumberNote: string | null
        emailAddress: string | null
        emailAddressNote: string | null
      } | null
      documents: {
        __typename: 'ServiceDocumentConnection'
        totalCount: number
        edges: Array<{
          __typename: 'ServiceDocumentEdge'
          node: {
            __typename: 'ServiceDocument'
            id: string
            type: ServiceDocumentType
            otherType: string | null
            fileUrl: string
            fileMimeType: string
            fileName: string
            note: string | null
            createdAt: string
            service: { __typename: 'Service'; id: string; title: string }
            createdByApp: { __typename: 'App'; id: string; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
          }
        }>
      }
    }>
    documents: {
      __typename: 'CaseDocumentConnection'
      totalCount: number
      edges: Array<{
        __typename: 'CaseDocumentEdge'
        node: {
          __typename: 'CaseDocument'
          id: string
          type: CaseDocumentType
          otherType: string | null
          fileUrl: string
          fileMimeType: string
          fileName: string
          note: string | null
          createdAt: string
          createdByApp: { __typename: 'App'; id: string; name: string } | null
          createdByUser: {
            __typename: 'User'
            id: string
            displayName: string
          } | null
        }
      }>
    }
    events: {
      __typename: 'CaseEventConnection'
      edges: Array<{
        __typename: 'CaseEventEdge'
        node: {
          __typename: 'CaseEvent'
          id: string
          type: CaseEventType
          date: string
          parameters: any
          actionResult: { __typename: 'CaseActionResult'; title: string } | null
          actionType: { __typename: 'CaseActionType'; title: string } | null
          createdByApp: { __typename: 'App'; name: string } | null
          createdByUser: {
            __typename: 'User'
            id: string
            displayName: string
          } | null
          user: {
            __typename: 'User'
            id: string
            displayName: string
            email: string
          } | null
          service: { __typename: 'Service'; id: string; title: string } | null
        }
      }>
    }
    customer: {
      __typename: 'Customer'
      id: string
      displayName: string
      birthDate: string | null
      gender: Gender | null
      governmentId: string | null
      educationLevel: CustomerEducationLevel | null
      marriageStatus: CustomerMaritalStatus | null
      note: string | null
      defaultPhone: {
        __typename: 'CustomerContactPhone'
        id: string
        phoneNumber: string
      } | null
      defaultEmail: {
        __typename: 'CustomerContactEmail'
        id: string
        emailAddress: string
      } | null
      addresses: Array<{
        __typename: 'CustomerAddress'
        id: string
        city: string
        cityArea: string | null
        note: string | null
        postalCode: string
        streetAddress1: string
        streetAddress2: string | null
        isPermanentResidency: boolean
        isDefault: boolean
        country: { __typename: 'Country'; code: string; name: string } | null
        countryArea: {
          __typename: 'CountryArea'
          id: string
          name: string
        } | null
      }>
      contactPhones: Array<{
        __typename: 'CustomerContactPhone'
        id: string
        phoneNumber: string
        note: string | null
        isDefault: boolean
      }>
      contactEmails: Array<{
        __typename: 'CustomerContactEmail'
        id: string
        emailAddress: string
        note: string | null
        isDefault: boolean
      }>
      documents: {
        __typename: 'CustomerDocumentConnection'
        totalCount: number
        edges: Array<{
          __typename: 'CustomerDocumentEdge'
          node: {
            __typename: 'CustomerDocument'
            id: string
            type: CustomerDocumentType
            otherType: string | null
            fileUrl: string
            fileMimeType: string
            fileName: string
            note: string | null
            createdAt: string
            createdByApp: { __typename: 'App'; id: string; name: string } | null
            createdByUser: {
              __typename: 'User'
              id: string
              displayName: string
            } | null
          }
        }>
      }
    }
    address: {
      __typename: 'CustomerAddress'
      id: string
      city: string
      cityArea: string | null
      note: string | null
      postalCode: string
      streetAddress1: string
      streetAddress2: string | null
      isPermanentResidency: boolean
      isDefault: boolean
      country: { __typename: 'Country'; code: string; name: string } | null
      countryArea: {
        __typename: 'CountryArea'
        id: string
        name: string
      } | null
    } | null
    contactEmail: {
      __typename: 'CustomerContactEmail'
      id: string
      emailAddress: string
      note: string | null
      isDefault: boolean
    } | null
    contactPhone: {
      __typename: 'CustomerContactPhone'
      id: string
      phoneNumber: string
      note: string | null
      isDefault: boolean
    } | null
    assignedTo: {
      __typename: 'User'
      id: string
      email: string
      displayName: string
    } | null
    totalDebt: { __typename: 'Money'; amount: number; currency: string } | null
    fee: {
      __typename: 'CaseFee'
      id: string
      paymentStatus: PaymentStatus
      feeRate: number
      createdAt: string
      requestedAmount: { __typename: 'Money'; amount: number; currency: string }
      paidAmount: { __typename: 'Money'; amount: number; currency: string }
      remainingAmount: { __typename: 'Money'; amount: number; currency: string }
      payments: Array<{
        __typename: 'CaseFeePayment'
        id: string
        paidAt: string
        paidAmount: { __typename: 'Money'; amount: number; currency: string }
      }>
    } | null
    availableCaseActionResults: Array<{
      __typename: 'CaseActionResult'
      id: string
      title: string
    }>
    availableCaseActionTypes: Array<{
      __typename: 'CaseActionType'
      id: string
      title: string
    }>
    availableCaseCloseReasons: Array<{
      __typename: 'CaseCloseReason'
      id: string
      title: string
    }>
    availableServiceTypes: Array<{
      __typename: 'ServiceType'
      id: string
      title: string
    }>
    createdByUser: {
      __typename: 'User'
      id: string
      displayName: string
    } | null
    createdByApp: { __typename: 'App'; id: string; name: string } | null
  } | null
  countries: Array<{
    __typename: 'Country'
    code: string
    name: string
    isDefault: boolean
    areas: Array<{ __typename: 'CountryArea'; id: string; name: string }>
  }>
}

export type CaseFilterCloseReasonsQueryVariables = Exact<{
  [key: string]: never
}>

export type CaseFilterCloseReasonsQuery = {
  __typename: 'Query'
  caseCloseReasons: {
    __typename: 'CaseCloseReasonConnection'
    edges: Array<{
      __typename: 'CaseCloseReasonEdge'
      node: { __typename: 'CaseCloseReason'; id: string; title: string }
    }>
  }
}

export type InitialCaseFilterUsersQueryVariables = Exact<{
  userIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
}>

export type InitialCaseFilterUsersQuery = {
  __typename: 'Query'
  users: {
    __typename: 'UserConnection'
    edges: Array<{
      __typename: 'UserEdge'
      node: { __typename: 'User'; id: string; displayName: string }
    }>
  }
}

export type InitialCaseFilterCustomersQueryVariables = Exact<{
  customerIds?: InputMaybe<
    Array<Scalars['ID']['input']> | Scalars['ID']['input']
  >
}>

export type InitialCaseFilterCustomersQuery = {
  __typename: 'Query'
  customers: {
    __typename: 'CustomerConnection'
    edges: Array<{
      __typename: 'CustomerEdge'
      node: { __typename: 'Customer'; id: string; displayName: string }
    }>
  }
}

export type ErrorsQueryVariables = Exact<{ [key: string]: never }>

export type ErrorsQuery = {
  __typename: 'Query'
  errorCodesAccount: Array<AccountScopeCode>
  errorCodesAuth: Array<AuthScopeCode>
  errorCodesCommon: Array<CommonScopeCode>
  errorCodesCase: Array<CaseScopeCode>
  errorCodesService: Array<ServiceScopeCode>
  errorCodesServiceProvider: Array<ServiceProviderScopeCode>
  scopes: Array<Scope>
}

export type CustomerCreateMutationVariables = Exact<{
  input: CustomerCreateInput
}>

export type CustomerCreateMutation = {
  __typename: 'Mutation'
  customerCreate: {
    __typename: 'CustomerCreate'
    customer: {
      __typename: 'Customer'
      id: string
      firstName: string | null
      middleName: string | null
      lastName: string
      displayName: string
      gender: Gender | null
      marriageStatus: CustomerMaritalStatus | null
      educationLevel: CustomerEducationLevel | null
      governmentId: string | null
      birthDate: string | null
      addresses: Array<{
        __typename: 'CustomerAddress'
        id: string
        city: string
        cityArea: string | null
        note: string | null
        postalCode: string
        streetAddress1: string
        streetAddress2: string | null
        isPermanentResidency: boolean
        isDefault: boolean
        cases: { __typename: 'CaseConnection'; totalCount: number }
        country: { __typename: 'Country'; code: string; name: string } | null
        countryArea: {
          __typename: 'CountryArea'
          id: string
          name: string
        } | null
      }>
      contactPhones: Array<{
        __typename: 'CustomerContactPhone'
        id: string
        phoneNumber: string
        note: string | null
        isDefault: boolean
        cases: { __typename: 'CaseConnection'; totalCount: number }
      }>
      contactEmails: Array<{
        __typename: 'CustomerContactEmail'
        id: string
        emailAddress: string
        note: string | null
        isDefault: boolean
        cases: { __typename: 'CaseConnection'; totalCount: number }
      }>
    }
  }
}

export type CustomerUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CustomerUpdateInput
}>

export type CustomerUpdateMutation = {
  __typename: 'Mutation'
  customerUpdate: {
    __typename: 'CustomerUpdate'
    customer: {
      __typename: 'Customer'
      id: string
      firstName: string | null
      middleName: string | null
      lastName: string
      displayName: string
      gender: Gender | null
      marriageStatus: CustomerMaritalStatus | null
      educationLevel: CustomerEducationLevel | null
      governmentId: string | null
      birthDate: string | null
      addresses: Array<{
        __typename: 'CustomerAddress'
        id: string
        city: string
        cityArea: string | null
        note: string | null
        postalCode: string
        streetAddress1: string
        streetAddress2: string | null
        isPermanentResidency: boolean
        isDefault: boolean
        cases: { __typename: 'CaseConnection'; totalCount: number }
        country: { __typename: 'Country'; code: string; name: string } | null
        countryArea: {
          __typename: 'CountryArea'
          id: string
          name: string
        } | null
      }>
      contactPhones: Array<{
        __typename: 'CustomerContactPhone'
        id: string
        phoneNumber: string
        note: string | null
        isDefault: boolean
        cases: { __typename: 'CaseConnection'; totalCount: number }
      }>
      contactEmails: Array<{
        __typename: 'CustomerContactEmail'
        id: string
        emailAddress: string
        note: string | null
        isDefault: boolean
        cases: { __typename: 'CaseConnection'; totalCount: number }
      }>
    }
  }
}

export type CustomerDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CustomerDeleteMutation = {
  __typename: 'Mutation'
  customerDelete: {
    __typename: 'CustomerDelete'
    customer: {
      __typename: 'Customer'
      id: string
      firstName: string | null
      middleName: string | null
      lastName: string
      displayName: string
      gender: Gender | null
      marriageStatus: CustomerMaritalStatus | null
      educationLevel: CustomerEducationLevel | null
      governmentId: string | null
      birthDate: string | null
      addresses: Array<{
        __typename: 'CustomerAddress'
        id: string
        city: string
        cityArea: string | null
        note: string | null
        postalCode: string
        streetAddress1: string
        streetAddress2: string | null
        isPermanentResidency: boolean
        isDefault: boolean
        cases: { __typename: 'CaseConnection'; totalCount: number }
        country: { __typename: 'Country'; code: string; name: string } | null
        countryArea: {
          __typename: 'CountryArea'
          id: string
          name: string
        } | null
      }>
      contactPhones: Array<{
        __typename: 'CustomerContactPhone'
        id: string
        phoneNumber: string
        note: string | null
        isDefault: boolean
        cases: { __typename: 'CaseConnection'; totalCount: number }
      }>
      contactEmails: Array<{
        __typename: 'CustomerContactEmail'
        id: string
        emailAddress: string
        note: string | null
        isDefault: boolean
        cases: { __typename: 'CaseConnection'; totalCount: number }
      }>
    }
  }
}

export type CustomerAddressCreateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CustomerAddressCreateInput
}>

export type CustomerAddressCreateMutation = {
  __typename: 'Mutation'
  customerAddressCreate: {
    __typename: 'CustomerAddressCreate'
    customerAddress: {
      __typename: 'CustomerAddress'
      id: string
      city: string
      cityArea: string | null
      note: string | null
      postalCode: string
      streetAddress1: string
      streetAddress2: string | null
      isPermanentResidency: boolean
      isDefault: boolean
      customer: {
        __typename: 'Customer'
        id: string
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          cases: { __typename: 'CaseConnection'; totalCount: number }
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        }>
      }
      country: { __typename: 'Country'; code: string; name: string } | null
      countryArea: {
        __typename: 'CountryArea'
        id: string
        name: string
      } | null
    }
  }
}

export type CustomerAddressDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CustomerAddressDeleteMutation = {
  __typename: 'Mutation'
  customerAddressDelete: {
    __typename: 'CustomerAddressDelete'
    customerAddress: {
      __typename: 'CustomerAddress'
      id: string
      city: string
      cityArea: string | null
      note: string | null
      postalCode: string
      streetAddress1: string
      streetAddress2: string | null
      isPermanentResidency: boolean
      isDefault: boolean
      customer: {
        __typename: 'Customer'
        id: string
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          cases: { __typename: 'CaseConnection'; totalCount: number }
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        }>
      }
      country: { __typename: 'Country'; code: string; name: string } | null
      countryArea: {
        __typename: 'CountryArea'
        id: string
        name: string
      } | null
    }
  }
}

export type CustomerPhoneCreateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CustomerContactPhoneCreateInput
}>

export type CustomerPhoneCreateMutation = {
  __typename: 'Mutation'
  customerContactPhoneCreate: {
    __typename: 'CustomerContactPhoneCreate'
    customerContactPhone: {
      __typename: 'CustomerContactPhone'
      id: string
      phoneNumber: string
      note: string | null
      isDefault: boolean
      customer: {
        __typename: 'Customer'
        id: string
        contactPhones: Array<{
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
          cases: { __typename: 'CaseConnection'; totalCount: number }
        }>
      }
    }
  }
}

export type CustomerPhoneDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CustomerPhoneDeleteMutation = {
  __typename: 'Mutation'
  customerContactPhoneDelete: {
    __typename: 'CustomerContactPhoneDelete'
    customerContactPhone: {
      __typename: 'CustomerContactPhone'
      id: string
      phoneNumber: string
      note: string | null
      isDefault: boolean
      customer: {
        __typename: 'Customer'
        id: string
        contactPhones: Array<{
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
          cases: { __typename: 'CaseConnection'; totalCount: number }
        }>
      }
    }
  }
}

export type CustomerEmailCreateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CustomerContactEmailCreateInput
}>

export type CustomerEmailCreateMutation = {
  __typename: 'Mutation'
  customerContactEmailCreate: {
    __typename: 'CustomerContactEmailCreate'
    customerContactEmail: {
      __typename: 'CustomerContactEmail'
      id: string
      emailAddress: string
      note: string | null
      isDefault: boolean
      customer: {
        __typename: 'Customer'
        id: string
        contactEmails: Array<{
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
          cases: { __typename: 'CaseConnection'; totalCount: number }
        }>
      }
    }
  }
}

export type CustomerEmailDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CustomerEmailDeleteMutation = {
  __typename: 'Mutation'
  customerContactEmailDelete: {
    __typename: 'CustomerContactEmailDelete'
    customerContactEmail: {
      __typename: 'CustomerContactEmail'
      id: string
      emailAddress: string
      note: string | null
      isDefault: boolean
      customer: {
        __typename: 'Customer'
        id: string
        contactEmails: Array<{
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
          cases: { __typename: 'CaseConnection'; totalCount: number }
        }>
      }
    }
  }
}

export type CustomerSetDefaultEmailMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CustomerSetDefaultEmailMutation = {
  __typename: 'Mutation'
  customerEmailSetDefault: {
    __typename: 'CustomerContactEmailSetDefault'
    customerContactEmail: {
      __typename: 'CustomerContactEmail'
      id: string
      emailAddress: string
      note: string | null
      isDefault: boolean
      customer: {
        __typename: 'Customer'
        id: string
        firstName: string | null
        middleName: string | null
        lastName: string
        displayName: string
        gender: Gender | null
        marriageStatus: CustomerMaritalStatus | null
        educationLevel: CustomerEducationLevel | null
        governmentId: string | null
        birthDate: string | null
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          cases: { __typename: 'CaseConnection'; totalCount: number }
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        }>
        contactPhones: Array<{
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
          cases: { __typename: 'CaseConnection'; totalCount: number }
        }>
        contactEmails: Array<{
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
          cases: { __typename: 'CaseConnection'; totalCount: number }
        }>
      }
    }
  }
}

export type CustomerSetDefaultPhoneMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CustomerSetDefaultPhoneMutation = {
  __typename: 'Mutation'
  customerPhoneSetDefault: {
    __typename: 'CustomerContactPhoneSetDefault'
    customerContactPhone: {
      __typename: 'CustomerContactPhone'
      id: string
      phoneNumber: string
      note: string | null
      isDefault: boolean
      customer: {
        __typename: 'Customer'
        id: string
        firstName: string | null
        middleName: string | null
        lastName: string
        displayName: string
        gender: Gender | null
        marriageStatus: CustomerMaritalStatus | null
        educationLevel: CustomerEducationLevel | null
        governmentId: string | null
        birthDate: string | null
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          cases: { __typename: 'CaseConnection'; totalCount: number }
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        }>
        contactPhones: Array<{
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
          cases: { __typename: 'CaseConnection'; totalCount: number }
        }>
        contactEmails: Array<{
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
          cases: { __typename: 'CaseConnection'; totalCount: number }
        }>
      }
    }
  }
}

export type CustomerSetDefaultAddressMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CustomerSetDefaultAddressMutation = {
  __typename: 'Mutation'
  customerAddressSetDefault: {
    __typename: 'CustomerAddressSetDefault'
    customerAddress: {
      __typename: 'CustomerAddress'
      id: string
      city: string
      cityArea: string | null
      note: string | null
      postalCode: string
      streetAddress1: string
      streetAddress2: string | null
      isPermanentResidency: boolean
      isDefault: boolean
      customer: {
        __typename: 'Customer'
        id: string
        firstName: string | null
        middleName: string | null
        lastName: string
        displayName: string
        gender: Gender | null
        marriageStatus: CustomerMaritalStatus | null
        educationLevel: CustomerEducationLevel | null
        governmentId: string | null
        birthDate: string | null
        addresses: Array<{
          __typename: 'CustomerAddress'
          id: string
          city: string
          cityArea: string | null
          note: string | null
          postalCode: string
          streetAddress1: string
          streetAddress2: string | null
          isPermanentResidency: boolean
          isDefault: boolean
          cases: { __typename: 'CaseConnection'; totalCount: number }
          country: { __typename: 'Country'; code: string; name: string } | null
          countryArea: {
            __typename: 'CountryArea'
            id: string
            name: string
          } | null
        }>
        contactPhones: Array<{
          __typename: 'CustomerContactPhone'
          id: string
          phoneNumber: string
          note: string | null
          isDefault: boolean
          cases: { __typename: 'CaseConnection'; totalCount: number }
        }>
        contactEmails: Array<{
          __typename: 'CustomerContactEmail'
          id: string
          emailAddress: string
          note: string | null
          isDefault: boolean
          cases: { __typename: 'CaseConnection'; totalCount: number }
        }>
      }
      country: { __typename: 'Country'; code: string; name: string } | null
      countryArea: {
        __typename: 'CountryArea'
        id: string
        name: string
      } | null
    }
  }
}

export type CustomerDocumentCreateMutationVariables = Exact<{
  customerId: Scalars['ID']['input']
  input: CustomerDocumentCreateInput
}>

export type CustomerDocumentCreateMutation = {
  __typename: 'Mutation'
  customerDocumentCreate: {
    __typename: 'CustomerDocumentCreate'
    customerDocument: {
      __typename: 'CustomerDocument'
      id: string
      fileName: string
      customer: {
        __typename: 'Customer'
        id: string
        documents: {
          __typename: 'CustomerDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CustomerDocumentEdge'
            node: {
              __typename: 'CustomerDocument'
              id: string
              type: CustomerDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }
    }
  }
}

export type CustomerDocumentUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CustomerDocumentUpdateInput
}>

export type CustomerDocumentUpdateMutation = {
  __typename: 'Mutation'
  customerDocumentUpdate: {
    __typename: 'CustomerDocumentUpdate'
    customerDocument: {
      __typename: 'CustomerDocument'
      id: string
      fileName: string
      customer: {
        __typename: 'Customer'
        id: string
        documents: {
          __typename: 'CustomerDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CustomerDocumentEdge'
            node: {
              __typename: 'CustomerDocument'
              id: string
              type: CustomerDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }
    }
  }
}

export type CustomerDocumentDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type CustomerDocumentDeleteMutation = {
  __typename: 'Mutation'
  customerDocumentDelete: {
    __typename: 'CustomerDocumentDelete'
    customerDocument: {
      __typename: 'CustomerDocument'
      id: string
      fileName: string
      customer: {
        __typename: 'Customer'
        id: string
        documents: {
          __typename: 'CustomerDocumentConnection'
          totalCount: number
          edges: Array<{
            __typename: 'CustomerDocumentEdge'
            node: {
              __typename: 'CustomerDocument'
              id: string
              type: CustomerDocumentType
              otherType: string | null
              fileUrl: string
              fileMimeType: string
              fileName: string
              note: string | null
              createdAt: string
              createdByApp: {
                __typename: 'App'
                id: string
                name: string
              } | null
              createdByUser: {
                __typename: 'User'
                id: string
                displayName: string
              } | null
            }
          }>
        }
      }
    }
  }
}

export type CustomerListFragment = {
  __typename: 'Customer'
  id: string
  firstName: string | null
  middleName: string | null
  lastName: string
  displayName: string
  createdAt: string
  cases: { __typename: 'CaseConnection'; totalCount: number }
}

export type CustomersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<CustomersFilterInput>
  sort?: InputMaybe<CustomersSortInput>
}>

export type CustomersQuery = {
  __typename: 'Query'
  customers: {
    __typename: 'CustomerConnection'
    totalCount: number
    edges: Array<{
      __typename: 'CustomerEdge'
      node: {
        __typename: 'Customer'
        id: string
        firstName: string | null
        middleName: string | null
        lastName: string
        displayName: string
        createdAt: string
        cases: { __typename: 'CaseConnection'; totalCount: number }
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
  }
}

export type CustomerDetailServiceFragment = {
  __typename: 'Service'
  id: string
  title: string
  status: ServiceStatus
  case: {
    __typename: 'Case'
    id: string
    status: CaseStatus
    assignedTo: { __typename: 'User'; displayName: string } | null
    closeReason: { __typename: 'CaseCloseReason'; title: string } | null
  }
}

export type CustomerDetailCaseFragment = {
  __typename: 'Case'
  id: string
  title: string
  status: CaseStatus
  assignedTo: { __typename: 'User'; displayName: string } | null
  closeReason: { __typename: 'CaseCloseReason'; title: string } | null
}

export type CustomerDetailQueryVariables = Exact<{
  id: Scalars['ID']['input']
  casesFirst?: InputMaybe<Scalars['Int']['input']>
  casesAfter?: InputMaybe<Scalars['String']['input']>
  casesLast?: InputMaybe<Scalars['Int']['input']>
  casesBefore?: InputMaybe<Scalars['String']['input']>
  servicesFirst?: InputMaybe<Scalars['Int']['input']>
  servicesAfter?: InputMaybe<Scalars['String']['input']>
  servicesLast?: InputMaybe<Scalars['Int']['input']>
  servicesBefore?: InputMaybe<Scalars['String']['input']>
}>

export type CustomerDetailQuery = {
  __typename: 'Query'
  customer: {
    __typename: 'Customer'
    id: string
    firstName: string | null
    middleName: string | null
    lastName: string
    displayName: string
    gender: Gender | null
    marriageStatus: CustomerMaritalStatus | null
    educationLevel: CustomerEducationLevel | null
    governmentId: string | null
    birthDate: string | null
    cases: {
      __typename: 'CaseConnection'
      totalCount: number
      edges: Array<{
        __typename: 'CaseEdge'
        node: {
          __typename: 'Case'
          id: string
          title: string
          status: CaseStatus
          assignedTo: { __typename: 'User'; displayName: string } | null
          closeReason: { __typename: 'CaseCloseReason'; title: string } | null
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        endCursor: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor: string | null
      }
    }
    services: {
      __typename: 'ServiceConnection'
      totalCount: number
      edges: Array<{
        __typename: 'ServiceEdge'
        node: {
          __typename: 'Service'
          id: string
          title: string
          status: ServiceStatus
          case: {
            __typename: 'Case'
            id: string
            status: CaseStatus
            assignedTo: { __typename: 'User'; displayName: string } | null
            closeReason: { __typename: 'CaseCloseReason'; title: string } | null
          }
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        endCursor: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor: string | null
      }
    }
    addresses: Array<{
      __typename: 'CustomerAddress'
      id: string
      city: string
      cityArea: string | null
      note: string | null
      postalCode: string
      streetAddress1: string
      streetAddress2: string | null
      isPermanentResidency: boolean
      isDefault: boolean
      cases: { __typename: 'CaseConnection'; totalCount: number }
      country: { __typename: 'Country'; code: string; name: string } | null
      countryArea: {
        __typename: 'CountryArea'
        id: string
        name: string
      } | null
    }>
    contactPhones: Array<{
      __typename: 'CustomerContactPhone'
      id: string
      phoneNumber: string
      note: string | null
      isDefault: boolean
      cases: { __typename: 'CaseConnection'; totalCount: number }
    }>
    contactEmails: Array<{
      __typename: 'CustomerContactEmail'
      id: string
      emailAddress: string
      note: string | null
      isDefault: boolean
      cases: { __typename: 'CaseConnection'; totalCount: number }
    }>
  } | null
  countries: Array<{
    __typename: 'Country'
    code: string
    name: string
    isDefault: boolean
    areas: Array<{ __typename: 'CountryArea'; id: string; name: string }>
  }>
}

export type AppFragment = {
  __typename: 'App'
  id: string
  name: string
  isActive: boolean
  tokens: Array<{
    __typename: 'AppToken'
    id: string
    name: string
    token: string
  }>
  accountPermissions: Array<{
    __typename: 'AccountPermission'
    code: Permission
    name: string
  }>
}

export type AccountPermissionFragment = {
  __typename: 'AccountPermission'
  code: Permission
  name: string
}

export type UserFragment = {
  __typename: 'User'
  id: string
  email: string
  firstName: string | null
  lastName: string | null
  accountPermissions: Array<{
    __typename: 'AccountPermission'
    code: Permission
    name: string
  }>
  avatar: { __typename: 'Image'; url: string } | null
}

export type CaseActionFragment = {
  __typename: 'CaseAction'
  id: string
  comment: string
  date: string | null
  createdAt: string
  type: { __typename: 'CaseActionType'; id: string; title: string }
  result: { __typename: 'CaseActionResult'; id: string; title: string }
  createdByUser: { __typename: 'User'; id: string; displayName: string } | null
  createdByApp: { __typename: 'App'; id: string; name: string } | null
}

export type CaseServiceProviderFragment = {
  __typename: 'ServiceProvider'
  id: string
  name: string
}

export type CaseServiceFragment = {
  __typename: 'Service'
  id: string
  title: string
  createdAt: string
  dpd: number | null
  status: ServiceStatus
  type: { __typename: 'ServiceType'; id: string; title: string }
  outstandingDebt: { __typename: 'Money'; amount: number; currency: string }
  principalAmount: {
    __typename: 'Money'
    amount: number
    currency: string
  } | null
  serviceProvider: {
    __typename: 'ServiceProvider'
    id: string
    name: string
  } | null
  serviceProviderContact: {
    __typename: 'ServiceProviderContact'
    id: string
    displayName: string
    firstName: string
    middleName: string | null
    lastName: string
    role: string
    phoneNumber: string | null
    phoneNumberNote: string | null
    emailAddress: string | null
    emailAddressNote: string | null
  } | null
  documents: {
    __typename: 'ServiceDocumentConnection'
    totalCount: number
    edges: Array<{
      __typename: 'ServiceDocumentEdge'
      node: {
        __typename: 'ServiceDocument'
        id: string
        type: ServiceDocumentType
        otherType: string | null
        fileUrl: string
        fileMimeType: string
        fileName: string
        note: string | null
        createdAt: string
        service: { __typename: 'Service'; id: string; title: string }
        createdByApp: { __typename: 'App'; id: string; name: string } | null
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
      }
    }>
  }
}

export type CaseEventFragment = {
  __typename: 'CaseEvent'
  id: string
  type: CaseEventType
  date: string
  parameters: any
  actionResult: { __typename: 'CaseActionResult'; title: string } | null
  actionType: { __typename: 'CaseActionType'; title: string } | null
  createdByApp: { __typename: 'App'; name: string } | null
  createdByUser: { __typename: 'User'; id: string; displayName: string } | null
  user: {
    __typename: 'User'
    id: string
    displayName: string
    email: string
  } | null
  service: { __typename: 'Service'; id: string; title: string } | null
}

export type CaseActionResultOptionFragment = {
  __typename: 'CaseActionResult'
  id: string
  title: string
}

export type CaseActionTypeOptionFragment = {
  __typename: 'CaseActionType'
  id: string
  title: string
}

export type CaseCloseReasonOptionFragment = {
  __typename: 'CaseCloseReason'
  id: string
  title: string
}

export type ServiceTypeOptionFragment = {
  __typename: 'ServiceType'
  id: string
  title: string
}

export type CaseDetailFeeFragment = {
  __typename: 'CaseFee'
  id: string
  paymentStatus: PaymentStatus
  feeRate: number
  createdAt: string
  requestedAmount: { __typename: 'Money'; amount: number; currency: string }
  paidAmount: { __typename: 'Money'; amount: number; currency: string }
  remainingAmount: { __typename: 'Money'; amount: number; currency: string }
  payments: Array<{
    __typename: 'CaseFeePayment'
    id: string
    paidAt: string
    paidAmount: { __typename: 'Money'; amount: number; currency: string }
  }>
}

export type CaseDetailCaseDocumentFragment = {
  __typename: 'CaseDocument'
  id: string
  type: CaseDocumentType
  otherType: string | null
  fileUrl: string
  fileMimeType: string
  fileName: string
  note: string | null
  createdAt: string
  createdByApp: { __typename: 'App'; id: string; name: string } | null
  createdByUser: { __typename: 'User'; id: string; displayName: string } | null
}

export type CaseDetailCustomerDocumentFragment = {
  __typename: 'CustomerDocument'
  id: string
  type: CustomerDocumentType
  otherType: string | null
  fileUrl: string
  fileMimeType: string
  fileName: string
  note: string | null
  createdAt: string
  createdByApp: { __typename: 'App'; id: string; name: string } | null
  createdByUser: { __typename: 'User'; id: string; displayName: string } | null
}

type CaseDetailDocument_CaseDocument_Fragment = {
  __typename: 'CaseDocument'
  fileUrl: string
  fileMimeType: string
  fileName: string
  note: string | null
  createdAt: string
  createdByApp: { __typename: 'App'; id: string; name: string } | null
  createdByUser: { __typename: 'User'; id: string; displayName: string } | null
}

type CaseDetailDocument_CustomerDocument_Fragment = {
  __typename: 'CustomerDocument'
  fileUrl: string
  fileMimeType: string
  fileName: string
  note: string | null
  createdAt: string
  createdByApp: { __typename: 'App'; id: string; name: string } | null
  createdByUser: { __typename: 'User'; id: string; displayName: string } | null
}

type CaseDetailDocument_ServiceDocument_Fragment = {
  __typename: 'ServiceDocument'
  fileUrl: string
  fileMimeType: string
  fileName: string
  note: string | null
  createdAt: string
  createdByApp: { __typename: 'App'; id: string; name: string } | null
  createdByUser: { __typename: 'User'; id: string; displayName: string } | null
}

export type CaseDetailDocumentFragment =
  | CaseDetailDocument_CaseDocument_Fragment
  | CaseDetailDocument_CustomerDocument_Fragment
  | CaseDetailDocument_ServiceDocument_Fragment

export type CaseDetailServiceDocumentFragment = {
  __typename: 'ServiceDocument'
  id: string
  type: ServiceDocumentType
  otherType: string | null
  fileUrl: string
  fileMimeType: string
  fileName: string
  note: string | null
  createdAt: string
  service: { __typename: 'Service'; id: string; title: string }
  createdByApp: { __typename: 'App'; id: string; name: string } | null
  createdByUser: { __typename: 'User'; id: string; displayName: string } | null
}

export type CaseDetailFragment = {
  __typename: 'Case'
  id: string
  createdAt: string
  modifiedAt: string
  type: CaseType
  status: CaseStatus
  customStatus: CaseCustomStatus
  title: string
  defaultFeeRate: number
  closeReason: {
    __typename: 'CaseCloseReason'
    id: string
    title: string
  } | null
  actions: Array<{
    __typename: 'CaseAction'
    id: string
    comment: string
    date: string | null
    createdAt: string
    type: { __typename: 'CaseActionType'; id: string; title: string }
    result: { __typename: 'CaseActionResult'; id: string; title: string }
    createdByUser: {
      __typename: 'User'
      id: string
      displayName: string
    } | null
    createdByApp: { __typename: 'App'; id: string; name: string } | null
  }>
  services: Array<{
    __typename: 'Service'
    id: string
    title: string
    createdAt: string
    dpd: number | null
    status: ServiceStatus
    type: { __typename: 'ServiceType'; id: string; title: string }
    outstandingDebt: { __typename: 'Money'; amount: number; currency: string }
    principalAmount: {
      __typename: 'Money'
      amount: number
      currency: string
    } | null
    serviceProvider: {
      __typename: 'ServiceProvider'
      id: string
      name: string
    } | null
    serviceProviderContact: {
      __typename: 'ServiceProviderContact'
      id: string
      displayName: string
      firstName: string
      middleName: string | null
      lastName: string
      role: string
      phoneNumber: string | null
      phoneNumberNote: string | null
      emailAddress: string | null
      emailAddressNote: string | null
    } | null
    documents: {
      __typename: 'ServiceDocumentConnection'
      totalCount: number
      edges: Array<{
        __typename: 'ServiceDocumentEdge'
        node: {
          __typename: 'ServiceDocument'
          id: string
          type: ServiceDocumentType
          otherType: string | null
          fileUrl: string
          fileMimeType: string
          fileName: string
          note: string | null
          createdAt: string
          service: { __typename: 'Service'; id: string; title: string }
          createdByApp: { __typename: 'App'; id: string; name: string } | null
          createdByUser: {
            __typename: 'User'
            id: string
            displayName: string
          } | null
        }
      }>
    }
  }>
  documents: {
    __typename: 'CaseDocumentConnection'
    totalCount: number
    edges: Array<{
      __typename: 'CaseDocumentEdge'
      node: {
        __typename: 'CaseDocument'
        id: string
        type: CaseDocumentType
        otherType: string | null
        fileUrl: string
        fileMimeType: string
        fileName: string
        note: string | null
        createdAt: string
        createdByApp: { __typename: 'App'; id: string; name: string } | null
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
      }
    }>
  }
  events: {
    __typename: 'CaseEventConnection'
    edges: Array<{
      __typename: 'CaseEventEdge'
      node: {
        __typename: 'CaseEvent'
        id: string
        type: CaseEventType
        date: string
        parameters: any
        actionResult: { __typename: 'CaseActionResult'; title: string } | null
        actionType: { __typename: 'CaseActionType'; title: string } | null
        createdByApp: { __typename: 'App'; name: string } | null
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        user: {
          __typename: 'User'
          id: string
          displayName: string
          email: string
        } | null
        service: { __typename: 'Service'; id: string; title: string } | null
      }
    }>
  }
  customer: {
    __typename: 'Customer'
    id: string
    displayName: string
    birthDate: string | null
    gender: Gender | null
    governmentId: string | null
    educationLevel: CustomerEducationLevel | null
    marriageStatus: CustomerMaritalStatus | null
    note: string | null
    defaultPhone: {
      __typename: 'CustomerContactPhone'
      id: string
      phoneNumber: string
    } | null
    defaultEmail: {
      __typename: 'CustomerContactEmail'
      id: string
      emailAddress: string
    } | null
    addresses: Array<{
      __typename: 'CustomerAddress'
      id: string
      city: string
      cityArea: string | null
      note: string | null
      postalCode: string
      streetAddress1: string
      streetAddress2: string | null
      isPermanentResidency: boolean
      isDefault: boolean
      country: { __typename: 'Country'; code: string; name: string } | null
      countryArea: {
        __typename: 'CountryArea'
        id: string
        name: string
      } | null
    }>
    contactPhones: Array<{
      __typename: 'CustomerContactPhone'
      id: string
      phoneNumber: string
      note: string | null
      isDefault: boolean
    }>
    contactEmails: Array<{
      __typename: 'CustomerContactEmail'
      id: string
      emailAddress: string
      note: string | null
      isDefault: boolean
    }>
    documents: {
      __typename: 'CustomerDocumentConnection'
      totalCount: number
      edges: Array<{
        __typename: 'CustomerDocumentEdge'
        node: {
          __typename: 'CustomerDocument'
          id: string
          type: CustomerDocumentType
          otherType: string | null
          fileUrl: string
          fileMimeType: string
          fileName: string
          note: string | null
          createdAt: string
          createdByApp: { __typename: 'App'; id: string; name: string } | null
          createdByUser: {
            __typename: 'User'
            id: string
            displayName: string
          } | null
        }
      }>
    }
  }
  address: {
    __typename: 'CustomerAddress'
    id: string
    city: string
    cityArea: string | null
    note: string | null
    postalCode: string
    streetAddress1: string
    streetAddress2: string | null
    isPermanentResidency: boolean
    isDefault: boolean
    country: { __typename: 'Country'; code: string; name: string } | null
    countryArea: { __typename: 'CountryArea'; id: string; name: string } | null
  } | null
  contactEmail: {
    __typename: 'CustomerContactEmail'
    id: string
    emailAddress: string
    note: string | null
    isDefault: boolean
  } | null
  contactPhone: {
    __typename: 'CustomerContactPhone'
    id: string
    phoneNumber: string
    note: string | null
    isDefault: boolean
  } | null
  assignedTo: {
    __typename: 'User'
    id: string
    email: string
    displayName: string
  } | null
  totalDebt: { __typename: 'Money'; amount: number; currency: string } | null
  fee: {
    __typename: 'CaseFee'
    id: string
    paymentStatus: PaymentStatus
    feeRate: number
    createdAt: string
    requestedAmount: { __typename: 'Money'; amount: number; currency: string }
    paidAmount: { __typename: 'Money'; amount: number; currency: string }
    remainingAmount: { __typename: 'Money'; amount: number; currency: string }
    payments: Array<{
      __typename: 'CaseFeePayment'
      id: string
      paidAt: string
      paidAmount: { __typename: 'Money'; amount: number; currency: string }
    }>
  } | null
  availableCaseActionResults: Array<{
    __typename: 'CaseActionResult'
    id: string
    title: string
  }>
  availableCaseActionTypes: Array<{
    __typename: 'CaseActionType'
    id: string
    title: string
  }>
  availableCaseCloseReasons: Array<{
    __typename: 'CaseCloseReason'
    id: string
    title: string
  }>
  availableServiceTypes: Array<{
    __typename: 'ServiceType'
    id: string
    title: string
  }>
  createdByUser: { __typename: 'User'; id: string; displayName: string } | null
  createdByApp: { __typename: 'App'; id: string; name: string } | null
}

export type CustomerAddressFragment = {
  __typename: 'CustomerAddress'
  id: string
  city: string
  cityArea: string | null
  note: string | null
  postalCode: string
  streetAddress1: string
  streetAddress2: string | null
  isPermanentResidency: boolean
  isDefault: boolean
  country: { __typename: 'Country'; code: string; name: string } | null
  countryArea: { __typename: 'CountryArea'; id: string; name: string } | null
}

export type CustomerPhoneFragment = {
  __typename: 'CustomerContactPhone'
  id: string
  phoneNumber: string
  note: string | null
  isDefault: boolean
}

export type CustomerEmailFragment = {
  __typename: 'CustomerContactEmail'
  id: string
  emailAddress: string
  note: string | null
  isDefault: boolean
}

export type CustomerDetailFragment = {
  __typename: 'Customer'
  id: string
  firstName: string | null
  middleName: string | null
  lastName: string
  displayName: string
  gender: Gender | null
  marriageStatus: CustomerMaritalStatus | null
  educationLevel: CustomerEducationLevel | null
  governmentId: string | null
  birthDate: string | null
  addresses: Array<{
    __typename: 'CustomerAddress'
    id: string
    city: string
    cityArea: string | null
    note: string | null
    postalCode: string
    streetAddress1: string
    streetAddress2: string | null
    isPermanentResidency: boolean
    isDefault: boolean
    cases: { __typename: 'CaseConnection'; totalCount: number }
    country: { __typename: 'Country'; code: string; name: string } | null
    countryArea: { __typename: 'CountryArea'; id: string; name: string } | null
  }>
  contactPhones: Array<{
    __typename: 'CustomerContactPhone'
    id: string
    phoneNumber: string
    note: string | null
    isDefault: boolean
    cases: { __typename: 'CaseConnection'; totalCount: number }
  }>
  contactEmails: Array<{
    __typename: 'CustomerContactEmail'
    id: string
    emailAddress: string
    note: string | null
    isDefault: boolean
    cases: { __typename: 'CaseConnection'; totalCount: number }
  }>
}

export type PageInfoFragment = {
  __typename: 'PageInfo'
  endCursor: string | null
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor: string | null
}

export type ServiceProviderFragment = {
  __typename: 'ServiceProvider'
  id: string
  name: string
  createdAt: string
}

export type ServiceProviderDetailContactFragment = {
  __typename: 'ServiceProviderContact'
  id: string
  displayName: string
  role: string
  phoneNumber: string | null
  emailAddress: string | null
  createdAt: string
}

export type ServiceProviderDetailFragment = {
  __typename: 'ServiceProvider'
  id: string
  name: string
  createdAt: string
  serviceTypes: Array<{ __typename: 'ServiceType'; id: string; title: string }>
}

export type ServiceProviderAvailableServiceTypeFragment = {
  __typename: 'ServiceType'
  id: string
  value: string
  title: string
}

export type UserListFragment = {
  __typename: 'User'
  id: string
  email: string
  firstName: string | null
  isActive: boolean
  lastName: string | null
  role: UserRole | null
  roleDescription: string | null
}

export type UserDetailFragment = {
  __typename: 'User'
  displayName: string
  isSuperUser: boolean
  id: string
  email: string
  firstName: string | null
  isActive: boolean
  lastName: string | null
  role: UserRole | null
  roleDescription: string | null
  accountPermissions: Array<{
    __typename: 'AccountPermission'
    code: Permission
    name: string
  }>
  avatar: { __typename: 'Image'; url: string } | null
}

export type UserInfoFragment = {
  __typename: 'User'
  id: string
  email: string
  displayName: string
}

export type HomeQueryVariables = Exact<{
  PERMISSION_MANAGE_CASES: Scalars['Boolean']['input']
  PERMISSION_MANAGE_USERS: Scalars['Boolean']['input']
}>

export type HomeQuery = {
  __typename: 'Query'
  pendingCases?: { __typename: 'CaseConnection'; totalCount: number }
  openCases?: { __typename: 'CaseConnection'; totalCount: number }
  assignedCases: { __typename: 'CaseConnection'; totalCount: number }
  caseEvents: {
    __typename: 'CaseEventConnection'
    edges: Array<{
      __typename: 'CaseEventEdge'
      node: {
        __typename: 'CaseEvent'
        id: string
        type: CaseEventType
        date: string
        parameters: any
        case: { __typename: 'Case'; id: string; title: string }
        actionResult: { __typename: 'CaseActionResult'; title: string } | null
        actionType: { __typename: 'CaseActionType'; title: string } | null
        createdByApp: { __typename: 'App'; name: string } | null
        createdByUser: {
          __typename: 'User'
          id: string
          displayName: string
        } | null
        user: {
          __typename: 'User'
          id: string
          displayName: string
          email: string
        } | null
        service: { __typename: 'Service'; id: string; title: string } | null
      }
    }>
  }
  mostActiveUsers?: {
    __typename: 'UserConnection'
    edges: Array<{
      __typename: 'UserEdge'
      node: {
        __typename: 'User'
        id: string
        displayName: string
        total: { __typename: 'CaseConnection'; totalCount: number }
        active: { __typename: 'CaseConnection'; totalCount: number }
        avatar: { __typename: 'Image'; url: string } | null
      }
    }>
  }
}

export type ReportAssignedLeadsQueryVariables = Exact<{
  since: Scalars['DateTime']['input']
  until: Scalars['DateTime']['input']
  period: Period
}>

export type ReportAssignedLeadsQuery = {
  __typename: 'Query'
  reportAssignedLeads: {
    __typename: 'ReportAssignedLeads'
    interval: { __typename: 'Interval'; since: string; until: string }
    data: Array<{
      __typename: 'ReportAssignedLeadsByUser'
      user: {
        __typename: 'User'
        displayName: string
        roleDescription: string | null
      }
      intervalCounts: Array<{
        __typename: 'ReportAssignedLeadsByUserInInterval'
        countManuallyAssigned: number
        countAutoAssigned: number
        interval: { __typename: 'Interval'; since: string; until: string }
      }>
    }>
  }
}

export type ReportNewLeadsQueryVariables = Exact<{
  since: Scalars['DateTime']['input']
  until: Scalars['DateTime']['input']
  period: Period
}>

export type ReportNewLeadsQuery = {
  __typename: 'Query'
  reportNewLeads: {
    __typename: 'ReportNewLeads'
    interval: { __typename: 'Interval'; since: string; until: string }
    leadsByAuthor: Array<{
      __typename: 'ReportNewLeadsByAuthor'
      totalCount: number
      createdBy:
        | { __typename: 'App'; displayName: string }
        | { __typename: 'User'; displayName: string }
        | null
      intervalCounts: Array<{
        __typename: 'ReportNewLeadsInInterval'
        count: number
        interval: { __typename: 'Interval'; since: string; until: string }
      }>
    }>
    leadsTotal: Array<{
      __typename: 'ReportNewLeadsInInterval'
      count: number
      interval: { __typename: 'Interval'; since: string; until: string }
    }>
  }
}

export type SearchCustomersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>
  first: Scalars['Int']['input']
  query: Scalars['String']['input']
}>

export type SearchCustomersQuery = {
  __typename: 'Query'
  search: {
    __typename: 'CustomerConnection'
    edges: Array<{
      __typename: 'CustomerEdge'
      node: {
        __typename: 'Customer'
        id: string
        displayName: string
        defaultEmail: {
          __typename: 'CustomerContactEmail'
          emailAddress: string
        } | null
        defaultPhone: {
          __typename: 'CustomerContactPhone'
          phoneNumber: string
        } | null
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
  }
}

export type SearchServiceProviderContactsQueryVariables = Exact<{
  serviceProviderId: Scalars['ID']['input']
  after?: InputMaybe<Scalars['String']['input']>
  first: Scalars['Int']['input']
  query: Scalars['String']['input']
}>

export type SearchServiceProviderContactsQuery = {
  __typename: 'Query'
  search: {
    __typename: 'ServiceProviderContactConnection'
    edges: Array<{
      __typename: 'ServiceProviderContactEdge'
      node: {
        __typename: 'ServiceProviderContact'
        id: string
        displayName: string
        firstName: string
        lastName: string
        middleName: string | null
        role: string
        phoneNumber: string | null
        phoneNumberNote: string | null
        emailAddress: string | null
        emailAddressNote: string | null
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
  }
}

export type SearchServiceTypeServiceProviderQueryVariables = Exact<{
  serviceTypeId: Scalars['ID']['input']
  after?: InputMaybe<Scalars['String']['input']>
  first: Scalars['Int']['input']
  query: Scalars['String']['input']
}>

export type SearchServiceTypeServiceProviderQuery = {
  __typename: 'Query'
  search: {
    __typename: 'ServiceProviderConnection'
    edges: Array<{
      __typename: 'ServiceProviderEdge'
      node: { __typename: 'ServiceProvider'; id: string; name: string }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
  }
}

export type SearchUsersQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>
  first: Scalars['Int']['input']
  query: Scalars['String']['input']
}>

export type SearchUsersQuery = {
  __typename: 'Query'
  search: {
    __typename: 'UserConnection'
    edges: Array<{
      __typename: 'UserEdge'
      node: {
        __typename: 'User'
        id: string
        email: string
        displayName: string
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
  }
}

export type SearchNegotiatorsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>
  first: Scalars['Int']['input']
  query: Scalars['String']['input']
}>

export type SearchNegotiatorsQuery = {
  __typename: 'Query'
  search: {
    __typename: 'UserConnection'
    edges: Array<{
      __typename: 'UserEdge'
      node: {
        __typename: 'User'
        id: string
        email: string
        displayName: string
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
  }
}

export type SearchOperatorsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>
  first: Scalars['Int']['input']
  query: Scalars['String']['input']
}>

export type SearchOperatorsQuery = {
  __typename: 'Query'
  search: {
    __typename: 'UserConnection'
    edges: Array<{
      __typename: 'UserEdge'
      node: {
        __typename: 'User'
        id: string
        email: string
        displayName: string
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      endCursor: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor: string | null
    }
  }
}

export type ServiceProviderCreateMutationVariables = Exact<{
  input: ServiceProviderCreateInput
}>

export type ServiceProviderCreateMutation = {
  __typename: 'Mutation'
  serviceProviderCreate: {
    __typename: 'ServiceProviderCreate'
    serviceProvider: {
      __typename: 'ServiceProvider'
      id: string
      name: string
      createdAt: string
    }
  }
}

export type ServiceProviderUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: ServiceProviderUpdateInput
}>

export type ServiceProviderUpdateMutation = {
  __typename: 'Mutation'
  serviceProviderUpdate: {
    __typename: 'ServiceProviderUpdate'
    serviceProvider: {
      __typename: 'ServiceProvider'
      id: string
      name: string
      createdAt: string
      serviceTypes: Array<{
        __typename: 'ServiceType'
        id: string
        title: string
      }>
    }
  }
}

export type ServiceProviderDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ServiceProviderDeleteMutation = {
  __typename: 'Mutation'
  serviceProviderDelete: {
    __typename: 'ServiceProviderDelete'
    serviceProvider: {
      __typename: 'ServiceProvider'
      id: string
      name: string
      createdAt: string
      serviceTypes: Array<{
        __typename: 'ServiceType'
        id: string
        title: string
      }>
    }
  }
}

export type ServiceProviderContactCreateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: ServiceProviderContactCreateInput
}>

export type ServiceProviderContactCreateMutation = {
  __typename: 'Mutation'
  serviceProviderContactCreate: {
    __typename: 'ServiceProviderContactCreate'
    serviceProviderContact: {
      __typename: 'ServiceProviderContact'
      id: string
      displayName: string
      role: string
      phoneNumber: string | null
      emailAddress: string | null
      createdAt: string
      serviceProvider: {
        __typename: 'ServiceProvider'
        id: string
        name: string
        createdAt: string
        serviceTypes: Array<{
          __typename: 'ServiceType'
          id: string
          title: string
        }>
      }
    }
  }
}

export type ServiceProviderListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<ServiceProvidersFilterInput>
  sort?: InputMaybe<ServiceProvidersSortInput>
}>

export type ServiceProviderListQuery = {
  __typename: 'Query'
  serviceProviders: {
    __typename: 'ServiceProviderConnection'
    totalCount: number
    edges: Array<{
      __typename: 'ServiceProviderEdge'
      cursor: string
      node: {
        __typename: 'ServiceProvider'
        id: string
        name: string
        createdAt: string
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      startCursor: string | null
      endCursor: string | null
    }
  }
}

export type ServiceProviderDetailQueryVariables = Exact<{
  id: Scalars['ID']['input']
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  sort?: InputMaybe<ServiceProviderContactSortInput>
}>

export type ServiceProviderDetailQuery = {
  __typename: 'Query'
  serviceProvider: {
    __typename: 'ServiceProvider'
    id: string
    name: string
    createdAt: string
    contacts: {
      __typename: 'ServiceProviderContactConnection'
      totalCount: number
      edges: Array<{
        __typename: 'ServiceProviderContactEdge'
        node: {
          __typename: 'ServiceProviderContact'
          id: string
          displayName: string
          role: string
          phoneNumber: string | null
          emailAddress: string | null
          createdAt: string
        }
      }>
      pageInfo: {
        __typename: 'PageInfo'
        endCursor: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor: string | null
      }
    }
    availableServiceTypes: Array<{
      __typename: 'ServiceType'
      id: string
      value: string
      title: string
    }>
    serviceTypes: Array<{
      __typename: 'ServiceType'
      id: string
      title: string
    }>
  } | null
}

export type UserAddMutationVariables = Exact<{
  input: UserCreateInput
  redirectUrl?: InputMaybe<Scalars['String']['input']>
}>

export type UserAddMutation = {
  __typename: 'Mutation'
  userCreate: {
    __typename: 'UserCreate'
    user: {
      __typename: 'User'
      displayName: string
      isSuperUser: boolean
      id: string
      email: string
      firstName: string | null
      isActive: boolean
      lastName: string | null
      role: UserRole | null
      roleDescription: string | null
      accountPermissions: Array<{
        __typename: 'AccountPermission'
        code: Permission
        name: string
      }>
      avatar: { __typename: 'Image'; url: string } | null
    }
  }
}

export type UserUpdateMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UserUpdateInput
}>

export type UserUpdateMutation = {
  __typename: 'Mutation'
  userUpdate: {
    __typename: 'UserUpdate'
    user: {
      __typename: 'User'
      displayName: string
      isSuperUser: boolean
      id: string
      email: string
      firstName: string | null
      isActive: boolean
      lastName: string | null
      role: UserRole | null
      roleDescription: string | null
      accountPermissions: Array<{
        __typename: 'AccountPermission'
        code: Permission
        name: string
      }>
      avatar: { __typename: 'Image'; url: string } | null
    }
  }
}

export type UserDeleteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UserDeleteMutation = {
  __typename: 'Mutation'
  userDelete: { __typename: 'UserDelete' }
}

export type ChangeUserPasswordMutationVariables = Exact<{
  newPassword: Scalars['String']['input']
  oldPassword: Scalars['String']['input']
}>

export type ChangeUserPasswordMutation = {
  __typename: 'Mutation'
  passwordChange: {
    __typename: 'PasswordChange'
    accessToken: string
    refreshToken: string
  }
}

export type UserListQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  after?: InputMaybe<Scalars['String']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  filter?: InputMaybe<UsersFilterInput>
  sort?: InputMaybe<UsersSortInput>
}>

export type UserListQuery = {
  __typename: 'Query'
  users: {
    __typename: 'UserConnection'
    totalCount: number
    edges: Array<{
      __typename: 'UserEdge'
      cursor: string
      node: {
        __typename: 'User'
        id: string
        email: string
        firstName: string | null
        isActive: boolean
        lastName: string | null
        role: UserRole | null
        roleDescription: string | null
        avatar: { __typename: 'Image'; url: string } | null
      }
    }>
    pageInfo: {
      __typename: 'PageInfo'
      hasPreviousPage: boolean
      hasNextPage: boolean
      startCursor: string | null
      endCursor: string | null
    }
  }
}

export type UserDetailQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UserDetailQuery = {
  __typename: 'Query'
  user: {
    __typename: 'User'
    displayName: string
    isSuperUser: boolean
    id: string
    email: string
    firstName: string | null
    isActive: boolean
    lastName: string | null
    role: UserRole | null
    roleDescription: string | null
    accountPermissions: Array<{
      __typename: 'AccountPermission'
      code: Permission
      name: string
    }>
    avatar: { __typename: 'Image'; url: string } | null
  } | null
  permissions: Array<{
    __typename: 'AccountPermission'
    code: Permission
    name: string
  }>
}
